import {
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import { LaunchOutlined } from "@material-ui/icons";
import UserLayout from "../../components/userLayout/UserLayout";

const ComingOutSoon = () => {
  return (
    <>
      <UserLayout title={"Coming Soon!"}>
        <MainSection>
          <Card top={"32px"} background={"none"} center>
            <LaunchOutlined
              style={{
                width: "8em",
                height: "8em",
                marginTop: "2em",
                color: "#751DA5",
                opacity: "0.1",
              }}
            />
            <TitleText color={"#751DA5"} center>
              COMING OUT SOON!
            </TitleText>
            <Text bottom={"32px"} size={"1em"} center>
              You will get a notification when this feature is released.
            </Text>
          </Card>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ComingOutSoon;
