import { Grid } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getAdminApproveBid,
  getAdminRevokeBid,
} from "../../../../../../adapters/admin";
import { Box } from "../../../../../../styles/util/icon";

import {
  Button,
  Card,
  Label,
  Text,
  TitleText,
} from "../../../../../../styles/util/UtilStyles";
import { getFormattedAmount, getTimeAgo } from "../../../../../../Utils";

const AdminViewBidCard = ({
  amount,
  timestamp,
  _id,
  user,
  status,
  appContext,
}) => {
  const history = useHistory();
  const { firstName, lastName, picture } = user;
  const userId = user?._id;

  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(status);

  const handleApprove = () => {
    if (newStatus === "Pending") {
      getAdminApproveBid({ _id }, setLoading, appContext, setNewStatus);
    } else {
      getAdminRevokeBid({ _id }, setLoading, appContext, setNewStatus);
    }
  };

  return (
    <>
      <Card>
        <Card left={"16px"} right={"16px"} top={"16px"} bottom={"16px"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box width={"5em"} height={"5em"}>
                <img
                  src={picture}
                  alt={_id}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Label
                background={"rgba(242, 145, 27, 1)"}
                width={"8em"}
                height={"2.5em"}
                top="1em"
                left="auto"
              >
                <Text
                  left={"auto"}
                  right={"auto"}
                  top={"0px"}
                  size={"12px"}
                  color={"white"}
                >
                  N {getFormattedAmount(amount)}
                </Text>
              </Label>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TitleText
                fontFamily={"Inter"}
                size={"16px"}
                lineheight={"20px"}
                color={"#171717"}
                heavy
              >
                {firstName + " " + lastName}
              </TitleText>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Label
                background={"rgba(242, 145, 27, 0.1)"}
                width={"10em"}
                height={"2.5em"}
              >
                <Text
                  left={"auto"}
                  right={"auto"}
                  top={"0px"}
                  size={"12px"}
                  color={"#670150"}
                >
                  {newStatus}
                </Text>
              </Label>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Label
                background={"rgba(242, 145, 27, 0.1)"}
                width={"10em"}
                height={"2.5em"}
                left="auto"
              >
                <Text
                  left={"auto"}
                  right={"auto"}
                  top={"0px"}
                  size={"12px"}
                  color={"#670150"}
                >
                  {getTimeAgo(timestamp)}
                </Text>
              </Label>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card flex spaceBetween>
                <Button
                  color={"#670150"}
                  border={"1px solid #EEEEEE"}
                  background={"white"}
                  width={"10em"}
                  onClick={() => history.push(`/user/profile/${userId}`)}
                >
                  View User
                </Button>
                {loading ? (
                  <Button
                    color={"white"}
                    border={"1px solid #EEEEEE"}
                    background={"#670150"}
                    width={"10em"}
                  >
                    Please wait...
                  </Button>
                ) : (
                  <Button
                    color={"white"}
                    border={"1px solid #EEEEEE"}
                    background={"#670150"}
                    width={"10em"}
                    onClick={() => handleApprove(_id)}
                  >
                    {newStatus === "Pending" ? "Approve Bid" : "Revoke Bid"}
                  </Button>
                )}
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default AdminViewBidCard;
