import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { getUserInvestments } from "../../adapters/investments";

import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { NotFoundError } from "../../pages/error";
import {
  Button,
  Card,
  Option,
  SelectField,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import { InvestmentCard } from "../investments";

const FilterOptions = ({ setFilter, filter }) => {
  return (
    <>
      <Card width={"8em"} left={"auto"} background={"transparent"}>
        <Text bottom={"16px"} heavy>
          Filter
        </Text>
        <SelectField
          defaultValue={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Option>Completed</Option>
          <Option>Pending</Option>
          <Option>In Progress</Option>
          <Option>Cancelled</Option>
          <Option>All</Option>
        </SelectField>
      </Card>
    </>
  );
};

const dummies = [{}, {}, {}, {}, {}, {}];

const UserPortfolio = ({ appContext, userId }) => {
  const [plansList, setPlansList] = useState([]);
  const [filter, setFilter] = useState("All");
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [allPlans, setAllPlans] = useState(null);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    getUserInvestments(
      setLoadingPlans,
      setPlansList,
      appContext,
      pageNo,
      setAllPlans,
      userId
    );
    // eslint-disable-next-line
  }, [pageNo]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  useEffect(() => {
    if (allPlans !== null) {
      setPlansList(allPlans[filter]);
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <FilterOptions setFilter={setFilter} filter={filter} />

      <TitleText bottom={"16px"}>The User's Portfolio</TitleText>

      <Card
        width={"100%"}
        top={"2em"}
        background={plansList.length ? "transparent" : "white"}
      >
        <Grid container spacing={4}>
          {loadingPlans ? (
            dummies.map((item, pos) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={pos}>
                <SkeletonCard loading={loadingPlans} />
              </Grid>
            ))
          ) : (
            <>
              {plansList.length ? (
                <>
                  {plansList.map((item, pos) => (
                    <Grid item lg={4} md={4} sm={6} xs={12} key={pos}>
                      <InvestmentCard
                        appContext={appContext}
                        {...item}
                        userState={filter}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <NotFoundError
                    errorTitle={"No plans found in the user's portfolio"}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Card top={"64px"} bottom={"32px"} background={"transparent"}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                width={"100%"}
                height={"3.5em"}
                size={"12px"}
                background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                border={pageNo > 1 ? "1px solid #670150" : "none"}
                color={"white"}
                radius={"12px"}
                disabled={!pageNo}
                onClick={previous}
              >
                Previous
              </Button>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                width={"100%"}
                height={"3.5em"}
                size={"12px"}
                background={plansList.length ? "white" : "rgba(5,5,5,0.3)"}
                border={plansList.length ? "1px solid #670150" : "none"}
                color={"#670150"}
                radius={"12px"}
                disabled={!plansList.length}
                onClick={next}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default UserPortfolio;
