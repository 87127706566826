import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Box } from "../../../../styles/util/icon";
import {
  Button,
  Card,
  Label,
  Text,
  TitleText,
} from "../../../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../../../Utils";

export const AdminSharesCard = ({
  // status = "200",
  title,
  description,
  _id,
  bids,
  picture,
  amount,
  handleDeleteModal,
}) => {
  const history = useHistory();
  return (
    <>
      <Card>
        <Card left={"16px"} right={"16px"} top={"16px"} bottom={"16px"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box width={"5em"} height={"5em"}>
                <img
                  src={picture}
                  alt={_id}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Label
                background={"rgba(242, 145, 27, 0.1)"}
                width={"8em"}
                height={"2.5em"}
                center
              >
                <Text
                  left={"auto"}
                  right={"auto"}
                  top={"0px"}
                  size={"12px"}
                  color={"#670150"}
                >
                  {bids?.length} Bids
                </Text>
              </Label>
              <Label
                background={"rgba(242, 145, 27, 1)"}
                width={"8em"}
                height={"2.5em"}
                top="1em"
                center
              >
                <Text
                  left={"auto"}
                  right={"auto"}
                  top={"0px"}
                  size={"12px"}
                  color={"white"}
                >
                  N {getFormattedAmount(amount)}
                </Text>
              </Label>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TitleText
                fontFamily={"Inter"}
                size={"16px"}
                lineheight={"20px"}
                color={"#171717"}
                heavy
              >
                {title}
              </TitleText>
              <Text
                top={"16px"}
                color={"#767676"}
                fontFamily={"Inter"}
                size={"14px"}
                lineheight={"20px"}
              >
                {description}
              </Text>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}></Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card flex spaceBetween>
                <Button
                  color={"#670150"}
                  border={"1px solid #EEEEEE"}
                  background={"white"}
                  width={"7em"}
                  onClick={() => history.push(`/admin/shares/view/${_id}`)}
                >
                  View Plan
                </Button>
                <Button
                  color={"white"}
                  border={"1px solid #EEEEEE"}
                  background={"#670150"}
                  width={"7em"}
                  onClick={() =>
                    handleDeleteModal({
                      title,
                      description,
                      _id,
                      bids,
                      picture,
                      amount,
                    })
                  }
                >
                  Delete Plan
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </>
  );
};
