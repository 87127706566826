import { MainSection } from "../../../../../styles/util/UtilStyles";
import { ApprovedShares } from "./ApprovedShares";
import { PendingShares } from "./PendingShares";

export const ViewMySharesBody = ({
  approvedShares,
  pendingShares,
  sharesType,
}) => {
  const renderBaseOnType = () => {
    switch (sharesType) {
      case "Approved":
        return <ApprovedShares sharesList={approvedShares} />;
      case "Pending":
        return <PendingShares sharesList={pendingShares} />;
      default:
        return "";
    }
  };
  return (
    <>
      <MainSection width={"100%"} top={"2em"}>
        {renderBaseOnType()}
      </MainSection>
    </>
  );
};
