import { Add } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { Button, Card, Text } from "../../../../../styles/util/UtilStyles";

const CreateShareButton = () => {
  const history = useHistory();
  return (
    <>
      <Button
        background={"#670150"}
        width={"10em"}
        radius={"5px"}
        left={"auto"}
        onClick={() => history.push("/admin/shares/create/")}
      >
        <Card background={"transparent"} left={"auto"} right={"auto"} flex>
          <Add />
          <Text left={"4px"} top={"5px"} color={"white"} size={"12px"}>
            Create Share
          </Text>
        </Card>
      </Button>
    </>
  );
};

export default CreateShareButton;
