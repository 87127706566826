import { useState } from "react";

import { useForm } from "react-hook-form";
import { SuspendUser } from "../../components/general";
import { Grid, TextField } from "@material-ui/core";
import { Button, Card, Text, TitleText } from "../../styles/util/UtilStyles";
import { updateAUserProfile } from "../../adapters/admin";
import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { Circle } from "../../styles/util/icon";
import {
  AccessibilityNewOutlined,
  CallOutlined,
  SignalWifi0BarOutlined,
  SupervisedUserCircle,
} from "@material-ui/icons";
import { useHistory } from "react-router";

const UserBasicDetails = ({
  userDetails,
  setUserDetails,
  fetching,
  appContext,
  userId,
  managedByMe,
}) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    data.userID = userId;
    updateAUserProfile(data, setUserDetails, setLoading, appContext);
  };

  const {
    user,
    // managersList
  } = appContext;

  const {
    firstName,
    lastName,
    managerID,
    phoneNumber,
    status,
    email,
    userType,
  } = userDetails;

  const history = useHistory();
  return (
    <>
      <Card radius={"10px"}>
        <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TitleText center>Basic Informations</TitleText>
            </Grid>
          </Grid>

          {fetching ? (
            <>
              <SkeletonCard loading={fetching} />
              <SkeletonCard loading={fetching} />
            </>
          ) : (
            <>
              {user?.userType !== "Admin" ? (
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card>
                      <TitleText
                        fontFamily={"Inter"}
                        size={"20px"}
                        lineheight={"29px"}
                        color={"#22242C"}
                        center
                      >
                        {firstName + " " + lastName}
                      </TitleText>
                      <Text
                        color={"#8E8EA1"}
                        size={"12px"}
                        lineheight={"17px"}
                        fontFamily={"Inter"}
                        center
                      >
                        {email}
                      </Text>
                    </Card>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Text
                      color={"#8E8EA1"}
                      size={"12px"}
                      lineheight={"17px"}
                      fontFamily={"Inter"}
                      bottom={"16px"}
                    >
                      Contact
                    </Text>

                    <Card flex>
                      <Circle
                        width={"32px"}
                        height={"32px"}
                        background={"#670150"}
                      >
                        <CallOutlined />
                      </Circle>
                      <Text
                        color={"#22242C"}
                        size={"10px"}
                        lineheight={"17px"}
                        fontFamily={"Inter"}
                        top={"8px"}
                        left={"4px"}
                        heavy
                      >
                        {phoneNumber ? phoneNumber : "Not Added Yet!"}
                      </Text>
                    </Card>
                    <Text
                      color={"#8E8EA1"}
                      size={"12px"}
                      lineheight={"17px"}
                      fontFamily={"Inter"}
                      bottom={"16px"}
                      top={"16px"}
                    >
                      Activeness
                    </Text>
                    <Card top={"16px"} flex>
                      <Circle
                        width={"32px"}
                        height={"32px"}
                        background={"#670150"}
                      >
                        <SignalWifi0BarOutlined />
                      </Circle>
                      <Text
                        color={"#22242C"}
                        size={"10px"}
                        lineheight={"17px"}
                        fontFamily={"Inter"}
                        top={"8px"}
                        left={"4px"}
                        heavy
                      >
                        {status}
                      </Text>
                    </Card>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Text
                      color={"#8E8EA1"}
                      size={"12px"}
                      lineheight={"17px"}
                      fontFamily={"Inter"}
                      bottom={"16px"}
                    >
                      Basic Info
                    </Text>

                    <Card flex>
                      <Circle
                        width={"32px"}
                        height={"32px"}
                        background={"#670150"}
                      >
                        <AccessibilityNewOutlined />
                      </Circle>
                      <Text
                        color={"#22242C"}
                        size={"10px"}
                        lineheight={"17px"}
                        fontFamily={"Inter"}
                        top={"8px"}
                        left={"4px"}
                        heavy
                      >
                        {userType}
                      </Text>
                    </Card>

                    <Text
                      color={"#8E8EA1"}
                      size={"12px"}
                      lineheight={"17px"}
                      fontFamily={"Inter"}
                      bottom={"16px"}
                      top={"16px"}
                    >
                      Managed By
                    </Text>

                    <Card flex>
                      <Circle
                        width={"32px"}
                        height={"32px"}
                        background={"#670150"}
                      >
                        <SupervisedUserCircle />
                      </Circle>
                      {managedByMe ? (
                        <Text
                          color={"#22242C"}
                          size={"10px"}
                          lineheight={"17px"}
                          fontFamily={"Inter"}
                          top={"8px"}
                          left={"4px"}
                          heavy
                        >
                          Me
                        </Text>
                      ) : (
                        <Text
                          color={"#22242C"}
                          size={"10px"}
                          lineheight={"17px"}
                          fontFamily={"Inter"}
                          top={"8px"}
                          left={"4px"}
                          heavy
                        >
                          {/*   <>
                              {managersList.dict[managerID]?.firstName
                                ? managersList.dict[managerID]?.firstName +
                                  " " +
                                  managersList.dict[managerID]?.lastName
                                : "None"}
                            </> */}
                          {managerID ? managerID : "Not Applicable"}
                        </Text>
                      )}
                    </Card>
                  </Grid>

                  {user?.userType === "Manager" && (
                    <Grid container spacing={2} style={{ marginTop: "4em" }}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button
                          type="submit"
                          background={"rgba(103, 1, 80, 1)"}
                          color={"white"}
                          // top={"32px"}
                          disabled={loading}
                        >
                          {loading ? "Please wait..." : "View User's Plans"}
                        </Button>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button
                          type="submit"
                          background={"rgba(103, 1, 80, 0.1)"}
                          color={"#670150"}
                          onClick={() =>
                            history.push(`/manager/user/transactions/${userId}`)
                          }
                          disabled={loading}
                        >
                          {loading
                            ? "Please wait..."
                            : "View User Transactions"}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TitleText
                        top={"16px"}
                        bottom={"16px"}
                        size={"20px"}
                        lineheight={"20px"}
                        center
                      >
                        {userDetails?.firstName + " " + userDetails?.lastName}
                      </TitleText>
                      <Text color={"rgba(0, 0, 0, 0.4)"} size={"12px"} center>
                        {userDetails?.email}
                      </Text>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        type="text"
                        defaultValue={userDetails?.firstName}
                        {...register("firstName")}
                        disabled={user?.userType === "Admin" ? false : true}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        type="text"
                        defaultValue={userDetails?.lastName}
                        {...register("lastName")}
                        disabled={user?.userType === "Admin" ? false : true}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        type="email"
                        defaultValue={userDetails?.email}
                        {...register("email")}
                        disabled={user?.userType === "Admin" ? false : true}
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        type="number"
                        defaultValue={userDetails?.phoneNumber}
                        {...register("phoneNumber")}
                        disabled={user?.userType === "Admin" ? false : true}
                        //   required
                        fullWidth
                      />
                    </Grid>
                    {user?.userType === "Admin" && (
                      <>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <SuspendUser
                            isActive={
                              userDetails?.status === "Active" ? true : false
                            }
                            appContext={appContext}
                            userId={userId}
                            setResult={setUserDetails}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <Button
                            type="submit"
                            background={"rgba(103, 1, 80, 0.1)"}
                            color={"#670150"}
                            onSubmit={handleSubmit(onSubmit)}
                            disabled={loading}
                          >
                            {loading ? "Please wait..." : "Update"}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </form>
              )}
            </>
          )}
        </Card>
      </Card>
    </>
  );
};

export default UserBasicDetails;
