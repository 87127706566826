import { toast } from "react-toastify";
import { STATUSES } from "../utility/enums";

import { fetchApi } from "../utility/fetchApi";

import { baseUrl } from "../utility/Util";

const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;

export const getAllInvestments = async (
  setLoading,
  setResults,
  appContext,
  pageNo,
  filter
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/investment/fetch/?page=${pageNo}${filter}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setResults([]);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.investments);
    }
  };

  const response = await getResponse();
  return response;
};

export const getInvestInfo = async (
  params,
  setLoading,
  setResults,
  appContext,
  setError,
  isAdmin = null
) => {
  const { token } = appContext;
  setLoading(true);

  let APIUrl = `${baseUrl}/investment/get/${params?.investmentID}`;

  if (isAdmin) {
    APIUrl = `${baseUrl}/admin/investment/get/${params?.investmentID}`;
  } else {
    APIUrl = `${baseUrl}/investment/get/${params?.investmentID}`;
  }

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setError(true);
      console.log("error", errorMessage);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.investment);
      console.log("invest details", data?.data?.investment);
    }
  };

  const response = await getResponse();
  return response;
};

export const getMyInvestments = async (
  setLoading,
  setResults,
  appContext,
  pageNo,
  setAllPlans,
  status
) => {
  const { token } = appContext;
  setLoading(true);
  let APIUrl = `${baseUrl}/transaction/transactions/?page=${pageNo}&type=completed`;

  if (status) {
    APIUrl = `${baseUrl}/transaction/investments?page=${pageNo}&status=${status}`;
  }

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      if (!status) {
        let dataResp = data?.data?.transactions;
        let result = [
          ...dataResp?.Cancelled,
          ...dataResp?.Completed,
          ...dataResp["In Progress"],
          ...dataResp?.Pending,
        ];

        dataResp.All = result;

        let plans = { ...dataResp };
        plans.In_Progress = dataResp["In Progress"];

        setAllPlans(plans);
        setResults(result);
      } else {
        setResults(data?.data?.transactions);
      }
    }
  };

  const response = await getResponse();
  return response;
};

export const getUserInvestments = async (
  setLoading,
  setResults,
  appContext,
  pageNo,
  setAllPlans,
  userId
) => {
  const { token, user } = appContext;
  setLoading(true);
  let APIUrl = `${baseUrl}/transaction/transactions/?page=${pageNo}`;

  if (user?.userType === "Admin") {
    APIUrl = `${baseUrl}/admin/transaction/userInvestments/${userId}?page=${pageNo}`;
  }
  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      let dataResp = data?.data?.transactions;
      let result = [
        ...dataResp?.Cancelled,
        ...dataResp?.Completed,
        ...dataResp["In Progress"],
        ...dataResp?.Pending,
      ];

      dataResp.All = result;

      let plans = { ...dataResp };
      plans.In_Progress = dataResp["In Progress"];

      setAllPlans(plans);
      setResults(result);
    }
  };

  const response = await getResponse();
  return response;
};

export const getMyInvestmentSummary = async (
  setLoading,
  appContext,
  setSummaryData
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/transaction/transactions/?page=1`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      let dataResp = data?.data?.transactions;
      let result = [...dataResp["In Progress"], ...dataResp?.Pending];
      console.log("seen data", dataResp);
      setSummaryData(result);
    }
  };

  const response = await getResponse();
  return response;
};

export const investNow = async (
  params,
  setLoading,
  // setBtnState,
  appContext,
  history
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/transaction/invest/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      // setBtnState({
      //   text: "Invest Now",
      //   status: false,
      // });
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      history.go();
      return toast.success("Successfully invested in this plan!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const cancelInvestment = async (
  params,
  setLoading,
  appContext,
  history
) => {
  const { token } = appContext;
  setLoading(true);

  const { investmentID } = params;

  const APIUrl = `${baseUrl}/investment/cancel/${investmentID}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "DELETE",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Successfully removed your investment from this plan!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return setTimeout(() => {
        history.go();
      }, 2000);
    }
  };

  const response = await getResponse();
  return response;
};

export const getMyTransactions = async (
  setLoading,
  setResults,
  appContext,
  pageNo
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/transaction/list/?page=${pageNo}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.transactions);
    }
  };

  const response = await getResponse();
  return response;
};

export const getManagersStat = async (setLoading, setResults, appContext) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/manager/usersCount/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setResults({});
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.users);
    }
  };

  const response = await getResponse();
  return response;
};

export const getUserTransactions = async (
  setLoading,
  setResults,
  appContext,
  pageNo,
  userId
) => {
  const { token, user } = appContext;
  setLoading(true);
  let isAdmin = user?.userType === "Admin" ? true : false;

  let APIUrl = `${baseUrl}/manager/userTransaction/${userId}?page=${pageNo}`;

  if (!isAdmin) {
    APIUrl = `${baseUrl}/manager/userTransaction/${userId}?page=${pageNo}`;
  } else {
    APIUrl = `${baseUrl}/admin/transaction/fetch?page=${pageNo}&userID=${userId}`;
  }

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.transactions);
    }
  };

  const response = await getResponse();
  return response;
};

// --- Shares ---
export const getAllSharesPlans = async (
  params,
  setLoading,
  appContext,
  setResults,
  pageNo
) => {
  setLoading(LOADING);
  const APIUrl = `${baseUrl}/shares/fetch`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(ERROR);
      setResults([]);
    } else {
      let shares = data.data.shares;
      if (shares?.length > 0) {
        setResults(shares);
        setLoading(DATA);
      } else {
        setResults([]);
        setLoading(NULL_DATA);
      }
    }
  };

  const response = await getResponse();
  return response;
};

export const bidForASharePlan = async (
  params,
  setLoading,
  appContext,
  history
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/shares/bid/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success(
        "Your bid has been accepted. We will get back to you shortly",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return setTimeout(() => {
        history.go();
      }, 2000);
    }
  };

  const response = await getResponse();
  return response;
};

export const getAShareInfo = async (
  params,
  setLoading,
  appContext,
  setResult
) => {
  setLoading(LOADING);
  const APIUrl = `${baseUrl}/shares/get/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(ERROR);
      setResult({});
    } else {
      let shares = data.data.shares;
      setLoading(DATA);
      setResult(shares);
    }
  };

  const response = await getResponse();
  return response;
};

export const getMyShares = async (
  params,
  setLoading,
  appContext,
  setResults
) => {
  setLoading(LOADING);
  let { limit, status, page } = params;

  let APIUrl = `${baseUrl}/shares/bids/?page=${page}`;

  if (limit) {
    APIUrl += `&limit=${limit}`;
  }

  if (status) {
    APIUrl += `&status=${status}`;
  }

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(ERROR);
      setResults([]);
    } else {
      let shares = data.data.bids;
      if (shares?.length > 0) {
        setLoading(DATA);
      } else {
        setLoading(NULL_DATA);
      }
      setResults(shares);
    }
  };

  const response = await getResponse();
  return response;
};
