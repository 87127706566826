import { useContext, useEffect, useState } from "react";
import { getAShareInfo } from "../../../adapters/investments";
import { STATUSES } from "../../../adapters/utility/enums";
import { AppContext } from "../../../App";
import { ViewAShareBody } from "../../../components/marketHub/shares/ViewASharePlan";
import ViewASharePlanLoader from "../../../components/marketHub/shares/ViewASharePlan/loading/ViewASharePlanLoader";
import UserLayout from "../../../components/userLayout/UserLayout";
import { GoBack } from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

const ViewSharesInfoPage = ({ match }) => {
  const appContext = useContext(AppContext);

  const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;
  const [status, setStatus] = useState(DATA);

  const [shareData, setShareData] = useState({});

  useEffect(() => {
    const {
      params: { shareId },
    } = match || {};
    let params = { _id: shareId };
    getAShareInfo(params, setStatus, appContext, setShareData);
    // eslint-disable-next-line
  }, [appContext, match]);

  const renderBaseOnStatus = () => {
    switch (status) {
      case LOADING:
        return <ViewASharePlanLoader />;
      case DATA:
        return <ViewAShareBody {...shareData} />;
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"No Shares Added Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"View Shares Plan"}
        pageTitleComponent={<GoBack />}
      >
        {renderBaseOnStatus()}
      </UserLayout>
    </>
  );
};

export default ViewSharesInfoPage;
