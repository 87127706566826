import { useContext, useEffect, useState } from "react";
import { getAdminShareHolders } from "../../../adapters/admin";
import { STATUSES } from "../../../adapters/utility/enums";
import { AppContext } from "../../../App";
import { LoadingAdminViewAllShares } from "../../../components/admin/shares/AdminViewAllShares";
import AdminViewBidders from "../../../components/admin/shares/AdminViewShareInfo/AdminViewShareInfoBody/AdminViewBidders/AdminViewBidders";
import UserLayout from "../../../components/userLayout/UserLayout";
import { GoBack } from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

const { LOADING, DATA, ERROR } = STATUSES;

const AdminViewShareHoldersPage = ({ match }) => {
  const appContext = useContext(AppContext);

  const [status, setStatus] = useState(LOADING);

  const [shareHolders, setShareHolders] = useState([]);

  useEffect(() => {
    const {
      params: { shareId },
    } = match || {};
    let params = { _id: shareId };
    getAdminShareHolders(params, setStatus, appContext, setShareHolders);
    // eslint-disable-next-line
  }, [appContext, match]);

  const renderBaseOnStatus = () => {
    switch (status) {
      case LOADING:
        return <LoadingAdminViewAllShares />;
      case DATA:
        return (
          <AdminViewBidders
            errorText="No Share Holders Yet!"
            bids={shareHolders}
            appContext={appContext}
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"View Share Holders"}
        pageTitleComponent={<GoBack />}
      >
        {renderBaseOnStatus()}
      </UserLayout>
    </>
  );
};

export default AdminViewShareHoldersPage;
