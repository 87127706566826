import { useHistory } from "react-router-dom";
import { Button } from "../../../../../styles/util/UtilStyles";

const ViewMySharesButton = () => {
  const history = useHistory();
  return (
    <>
      <Button
        radius={"5px"}
        background={"#670150"}
        width={"10em"}
        border="1px solid #670150"
        color="white"
        left={"auto"}
        onClick={() => history.push("/user/market/shares/portfolio/")}
      >
        View My Portfolio
      </Button>
    </>
  );
};

export default ViewMySharesButton;
