import { Grid, TextField } from "@material-ui/core";
import { Button, TextAreaField } from "../../../../../styles/util/UtilStyles";

const AdminCreateSharesForm = ({
  onSubmit,
  loading,
  register,
  handleSubmit,
  editShareData,
  isEdit,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              label="Name Of Share Plan"
              variant="outlined"
              type="text"
              {...register("title")}
              defaultValue={editShareData?.title}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              label="Amount"
              variant="outlined"
              type="number"
              {...register("amount")}
              defaultValue={editShareData?.amount}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              label="Minimum Amount"
              variant="outlined"
              type="number"
              {...register("minimum")}
              defaultValue={editShareData?.minimum}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextField
              label="Maximum Amount"
              variant="outlined"
              type="number"
              {...register("maxAmount")}
              defaultValue={editShareData?.maxAmount}
              required
              fullWidth
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextAreaField
              placeholder="Description of Share Plan"
              type="text"
              style={{ width: "100%", height: "10em" }}
              defaultValue={editShareData?.description}
              {...register("description")}
              required
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextAreaField
              placeholder="Terms"
              type="text"
              style={{ width: "100%", height: "10em" }}
              defaultValue={editShareData?.terms}
              {...register("terms")}
              required
            />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <TextAreaField
              placeholder="Privacy Policy"
              type="text"
              style={{ width: "100%", height: "10em" }}
              defaultValue={editShareData?.privacyPolicy}
              {...register("privacyPolicy")}
              required
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {isEdit ? (
              <Button
                type="submit"
                background={"rgba(103, 1, 80, 0.1)"}
                color={"#670150"}
                disabled={loading}
              >
                {loading ? "Please wait..." : "Update Plan"}
              </Button>
            ) : (
              <Button
                type="submit"
                background={"rgba(103, 1, 80, 0.1)"}
                color={"#670150"}
                disabled={loading}
              >
                {loading ? "Please wait..." : "Create Plan"}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AdminCreateSharesForm;
