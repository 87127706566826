import { useEffect, useState } from "react";

import { Avatar, Grid } from "@material-ui/core";

import {
  AccessibilityNewOutlined,
  CallOutlined,
  MoneyRounded,
  SignalWifi0BarOutlined,
} from "@material-ui/icons";

import { useHistory } from "react-router";

import { NotFoundError } from "../../pages/error";
import { Circle } from "../../styles/util/icon";
import { Button, Card, Text, TitleText } from "../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../Utils";

const InvestorCard = ({ userInfo }) => {
  const {
    userType,
    type,
    phoneNumber,
    firstName,
    lastName,
    email,
    _id,
    status,
    picture,
    isManager,
    amount,
  } = userInfo;
  const history = useHistory();
  return (
    <>
      <Card>
        <Card left={"16px"} right={"16px"} top={"16px"} bottom={"16px"}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Circle background={"#F5F5F5"}>
                <Avatar src={picture} />
              </Circle>
            </Grid>
            <Grid item lg={9} md={9} sm={9} xs={9}>
              <Card>
                <TitleText
                  fontFamily={"Inter"}
                  size={"20px"}
                  lineheight={"29px"}
                  color={"#22242C"}
                  center
                >
                  {firstName + " " + lastName}
                </TitleText>
                <Text
                  color={"#8E8EA1"}
                  size={"12px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  center
                >
                  {email}
                </Text>
              </Card>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
              >
                Contact
              </Text>

              <Card flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <CallOutlined />
                </Circle>
                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {phoneNumber ? phoneNumber : "Not Added Yet!"}
                </Text>
              </Card>
              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
                top={"16px"}
              >
                Activeness
              </Text>
              <Card top={"16px"} flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <SignalWifi0BarOutlined />
                </Circle>
                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {status ? status : "Active"}
                </Text>
              </Card>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
              >
                Basic Info
              </Text>

              <Card flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <AccessibilityNewOutlined />
                </Circle>
                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {userType ? userType : type}
                </Text>
              </Card>

              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
                top={"16px"}
              >
                Amount Invested
              </Text>

              <Card flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <MoneyRounded />
                </Circle>

                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {" "}
                  NGN {getFormattedAmount(amount)}
                </Text>
              </Card>
            </Grid>

            {!(type === "Admin" || userType === "Admin") && (
              <>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button
                    background={"white"}
                    color={"#670150"}
                    border={"1px solid #670150"}
                    // width={"10em"}
                    top={"1em"}
                    left={"auto"}
                    radius={"5px"}
                    onClick={() =>
                      history.push(
                        isManager
                          ? `/manager/user/profile/${_id}`
                          : `/user/profile/${_id}`
                      )
                    }
                  >
                    View User
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Card>
    </>
  );
};

const InvestmentInvestors = ({ investors }) => {
  const [investorsList, setInvestorsList] = useState([]);

  useEffect(() => {
    if (investors?.length) {
      console.log("investors", investors);
      setInvestorsList(investors);
    }
    // eslint-disable-next-line
  }, investors);

  return (
    <>
      <Card
        background={"transparent"}
        border={"1px solid #670150"}
        bottom={"2em"}
      >
        <TitleText bottom={"1em"} top={"1em"}>
          Investors
        </TitleText>
        {investorsList.length ? (
          <Grid container spacing={4}>
            {investorsList.map((userInfo, pos) => (
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <InvestorCard userInfo={userInfo?.userDetails} key={pos} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NotFoundError
            errorTitle={"Ops, no one has invested into this plan yet!"}
          />
        )}
      </Card>
    </>
  );
};

export default InvestmentInvestors;
