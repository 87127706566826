import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonCard = ({ loading }) => {
  return (
    <>
      {loading && (
        <SkeletonTheme>
          {<Skeleton count={1} height={150} />}
          <h1>{<Skeleton />}</h1>
        </SkeletonTheme>
      )}
    </>
  );
};

export default SkeletonCard;
