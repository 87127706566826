import { useContext, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { AppContext } from "../../App";
// import { WalletChart } from "../../components/dashboard";
import BalanceCard from "../../components/dashboard/BalanceCard";
import UserLayout from "../../components/userLayout/UserLayout";
import TransactionsCard from "../../components/wallet/TransactionsCard";
import {
  Button,
  Card,
  Label,
  LinkText,
  MainSection,
} from "../../styles/util/UtilStyles";

import { getMyInvestmentSummary } from "../../adapters/investments";
import { PendingInvestment } from "../../components/investments";
import { useHistory } from "react-router";
import { getFormattedAmount } from "../../Utils";
// import { TitleText } from "../../styles/util/UtilStyles";

// const balanceCards = [
//   { title: "Wallet Balance", amount: "4,509,063", color: "#DDF6F8" },
//   { title: "Amount Spent", amount: "3,041730", color: "#FEEED7" },
//   { title: "Amount Invested", amount: "1,021238", color: "#E5E2FF" },
// ];

const ViewMyManager = ({ userId }) => {
  const history = useHistory();
  return (
    <>
      <Button
        background={"#670150"}
        width={"10em"}
        radius={"5px"}
        left={"auto"}
        top={"2em"}
        onClick={() => history.push(`/user/profile/${userId}`)}
      >
        View My Manager
      </Button>
    </>
  );
};
const DefaultPage = () => {
  const appContext = useContext(AppContext);
  const { user } = appContext;

  const [walletOverview, setWalletOverview] = useState([{}]);

  const [loadingInvests, setLoadingInvests] = useState(true);

  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    setWalletOverview([
      {
        title: "Wallet Balance",
        amount: getFormattedAmount(user?.wallet),
        color: "#DDF6F8",
      },
      {
        title: "Amount Earned",
        amount: getFormattedAmount(user?.amountEarned),
        color: "#FEEED7",
      },
      {
        title: "Amount Invested",
        amount: getFormattedAmount(user?.amountInvested),
        color: "#E5E2FF",
      },
    ]);

    getMyInvestmentSummary(setLoadingInvests, appContext, setSummaryData);

    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <UserLayout
        pageTitle={"Overview"}
        pageTitleComponent={<ViewMyManager userId={user?.managerID} />}
      >
        {/* --- Overview cards section here ---- */}
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            {walletOverview.map((item, pos) => (
              <Grid key={pos} item lg={4} md={4} sm={4} xs={12}>
                <BalanceCard {...item} />
              </Grid>
            ))}
          </Grid>
        </MainSection>

        <MainSection width={"100%"} top={"2em"}>
          <PendingInvestment
            loading={loadingInvests}
            investedPlans={summaryData}
            title={"Portfolio Summary."}
          />
        </MainSection>

        {/* <MainSection width={"100%"} top={"2em"}>
          <WalletChart />
        </MainSection> */}

        <MainSection width={"100%"} top={"4em"}>
          <Card bottom={"16px"} background={"transparent"}>
            <Label
              border={"1px solid #670150"}
              width={"10em"}
              background={"white"}
              left={"auto"}
            >
              <LinkText
                to={"/user/transactions/"}
                nounderline={"true"}
                color={"#670150"}
                center
              >
                View All
              </LinkText>
            </Label>
          </Card>
          <TransactionsCard />
        </MainSection>
      </UserLayout>
    </>
  );
};

export default DefaultPage;
