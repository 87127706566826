import { useContext, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { Avatar, Grid, TextField } from "@material-ui/core";
import { Button, Card, Text, TitleText } from "../../styles/util/UtilStyles";
import { AppContext } from "../../App";
import { updateProfileApi, updateProfilePicApi } from "../../adapters/user";
import { getBase64 } from "../../adapters/utility/Util";

const ProfileInfoCard = () => {
  const inputFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);

  const appContext = useContext(AppContext);
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    updateProfileApi(data, setLoading, appContext);
  };

  const handleUpdatePic = (e) => {
    if (e?.target?.value?.length) {
      getBase64(e.target.files[0]).then((data) => setImage(data));
      updateProfilePicApi({ picture: image }, setUploading, appContext);
    }
  };

  const openFileUpload = () => {
    inputFile.current.click();
  };

  return (
    <>
      <Card radius={"10px"}>
        <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
          <Grid container spacing={3}>
            <Grid item lg={5} md={6} sm={6} xs={6}>
              <Card top={"16px"} bottom={"16px"} center verticalCenter>
                <Avatar
                  style={{
                    width: "5em",
                    height: "5em",
                    border: "1px solid rgba(103, 1, 80, 0.5)",
                  }}
                  src={
                    appContext?.user?.picture
                      ? appContext?.user?.picture
                      : image
                  }
                />
              </Card>
            </Grid>
            <Grid item lg={7} md={6} sm={6} xs={6}>
              <TitleText
                top={"16px"}
                bottom={"16px"}
                size={"20px"}
                lineheight={"20px"}
              >
                {appContext?.user?.firstName + " " + appContext?.user?.lastName}
              </TitleText>
              <Text color={"rgba(0, 0, 0, 0.4)"} size={"12px"}>
                {appContext?.user?.email}
              </Text>
              <Button
                top={"16px"}
                bottom={"16px"}
                background={"rgba(103, 1, 80, 0.1)"}
                color={"#670150"}
                onClick={openFileUpload}
              >
                {uploading ? "Uploading..." : "Change Photo"}
              </Button>

              <input
                onChange={handleUpdatePic}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
                accept=".jpg,.png,.bmp"
              />
            </Grid>
          </Grid>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  type="text"
                  defaultValue={appContext?.user?.firstName}
                  {...register("firstName")}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  type="text"
                  defaultValue={appContext?.user?.lastName}
                  {...register("lastName")}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  defaultValue={appContext?.user?.email}
                  {...register("email")}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  type="number"
                  defaultValue={appContext?.user?.phoneNumber}
                  {...register("phoneNumber")}
                  //   required
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Passcode"
                  variant="outlined"
                  type="number"
                  {...register("passcode")}
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 0.1)"}
                  color={"#670150"}
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Save Changes"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Card>
    </>
  );
};

export default ProfileInfoCard;
