import { createStyles, createTheme, Grid, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getUserTransactions } from "../../adapters/investments";
import { getFormattedDate } from "../../adapters/utility/Util";
import { AppContext } from "../../App";
import UserLayout from "../../components/userLayout/UserLayout";
import {
  Button,
  Card,
  GoBack,
  MainSection,
} from "../../styles/util/UtilStyles";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const customTheme = {
  palette: { mode: "light" },
};

const defaultTheme = createTheme(customTheme);

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: 0,
        color:
          theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor:
            theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderRight: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-cell": {
          color:
            theme.palette.mode === "light"
              ? "rgba(0,0,0,.85)"
              : "rgba(255,255,255,0.65)",
        },
        "& .MuiPaginationItem-root": {
          borderRadius: 0,
        },
        ...customCheckbox(theme),
      },
    }),
  { defaultTheme }
);

const columns = [
  { field: "_id", headerName: "ID", width: 70, hide: true },
  {
    field: "amount",
    headerClassName: "datagridHeader",
    cellClassName: "datagridContent",
    // headerAlign: "center",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "type",
    headerClassName: "datagridHeader",
    cellClassName: "datagridContent",
    // headerAlign: "center",
    headerName: "Type",
    width: 150,
  },
  {
    field: "status",
    headerClassName: "datagridHeader",
    cellClassName: "datagridContent",
    // headerAlign: "center",
    headerName: "Status",
    width: 200,
  },
  {
    field: "timestamp",
    headerClassName: "datagridHeader",
    cellClassName: "datagridContent",
    // headerAlign: "center",
    headerName: "Date",
    width: 200,
    renderCell: (rows) => {
      return (
        // {getFormattedDate('dd')}
        <div>{getFormattedDate(rows?.row?.timestamp)}</div>
      );
    },
  },
  {
    field: "Action",
    headerClassName: "datagridHeader",
    cellClassName: "datagridContent",
    // headerAlign: "center",
    headerName: "Action",
    width: 200,
    renderCell: (cellValues) => {
      return (
        <Button
          width={"100%"}
          height={"3.5em"}
          size={"10px"}
          background={"#670150"}
          border={"1px solid #670150"}
          color={"white"}
          radius={"12px"}
        >
          Delete
        </Button>
      );
    },
  },
];

const ViewAnyUserTransactionsPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  const appContext = useContext(AppContext);
  const history = useHistory();
  const [userId, setUserId] = useState(history.location.pathname.substr(27));

  useEffect(() => {
    setUserId(history.location.pathname.substr(27));
    getUserTransactions(setLoading, setResults, appContext, pageNo, userId);
    // eslint-disable-next-line
  }, [pageNo]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"User Transactions."}
        pageTitleComponent={<GoBack />}
      >
        <MainSection width={"100%"} top={"2em"}>
          <Card radius={"10px"}>
            <Card
              top={"16px"}
              height={"28em"}
              bottom={"16px"}
              right={"16px"}
              left={"16px"}
            >
              {/* -- I need to Fix the pagination issue but later sha --- */}
              <DataGrid
                rows={results}
                columns={columns}
                className={classes.root}
                // checkboxSelection
                pageSize={20}
                loading={loading}
                rowsPerPageOptions={[1]}
                getRowId={(r) => r._id}
              />
            </Card>
          </Card>

          <Card top={"32px"} bottom={"32px"} background={"transparent"}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                  border={pageNo > 1 ? "1px solid #670150" : "none"}
                  color={"white"}
                  radius={"12px"}
                  disabled={!pageNo}
                  onClick={previous}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={results.length ? "white" : "rgba(5,5,5,0.3)"}
                  border={results.length ? "1px solid #670150" : "none"}
                  color={"#670150"}
                  radius={"12px"}
                  disabled={!results.length}
                  onClick={next}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Card>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewAnyUserTransactionsPage;
