import { useContext, useEffect, useState } from "react";
import Carousel from "react-grid-carousel";

import {
  getAllInvestments,
  getMyInvestments,
} from "../../../adapters/investments";

import SkeletonCard from "../../../adapters/utility/SkeletonCard";
import { AppContext } from "../../../App";

import {
  InvestedPlan,
  InvestmentCard,
  PendingInvestment,
} from "../../../components/investments";

import UserLayout from "../../../components/userLayout/UserLayout";
import {
  Card,
  carouselDots,
  MainSection,
  TitleText,
} from "../../../styles/util/UtilStyles";

import ViewAllPlans from "../../../components/investments/util/ViewAllPlans";
import { NotFoundError } from "../../error";

// const investplans = [{}, {}, {}, {}];

const carouselsLoader = [{}, {}, {}];
const InvestmentHistoryPage = () => {
  const appContext = useContext(AppContext);

  const [plansList, setPlansList] = useState([{}, {}, {}, {}]);
  const [allPlans, setAllPlans] = useState([]);

  const [loadingPlans, setLoadingPlans] = useState(false);
  const [loadingInvests, setLoadingInvests] = useState(false);
  const [investments, setInvestments] = useState({
    Pending: [],
    In_Progress: [],
    Completed: [],
    Cancelled: [],
  });

  useEffect(() => {
    // setLoadingInvests,
    // setAllPlans,
    // appContext,
    // 1,

    getAllInvestments(
      setLoadingPlans,
      setPlansList,
      appContext,
      1,
      "&type=Pending,In Progress"
    );
    getMyInvestments(
      setLoadingInvests,
      setAllPlans,
      appContext,
      1,
      setInvestments
    );
    console.log("tested", allPlans);
    // eslint-disable-next-line
  }, [appContext]);

  return (
    <>
      <UserLayout
        pageTitle={"Investments History"}
      >
        <MainSection width={"100%"} top={"2em"}>
          <PendingInvestment
            loading={loadingInvests}
            investedPlans={investments?.Completed}
            title={"Completed Investments"}
            status="completed"
          />
        </MainSection>

        <MainSection width={"100%"} top={"2em"}>
          <PendingInvestment
            loading={loadingInvests}
            investedPlans={investments?.Cancelled}
            title={"Cancelled Investments"}
            status="cancelled"
          />
        </MainSection>

        <MainSection width={"100%"} top={"2em"}>
          <ViewAllPlans url={"/user/market/investments/portfolio/completed"} />
        </MainSection>
      </UserLayout>
    </>
  );
};

export default InvestmentHistoryPage;
