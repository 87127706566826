import { Card } from "../../../../../styles/util/UtilStyles";

const AdminCreateSharesImage = ({ image }) => {
  return (
    <>
      <Card
        height={"15em"}
        top={"16px"}
        bottom={"16px"}
        left={"16px"}
        right={"16px"}
        background={"rgba(5,5,5,0.1)"}
      >
        <img src={image} alt="" style={{ width: "100%", height: "100%" }} />
      </Card>
    </>
  );
};

export default AdminCreateSharesImage;
