import { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppContext } from "./App";
import {
  AdminCreateInvestPlanPage,
  AdminDashboardPage,
  AdminEditInvestPlanPage,
  AdminInvestPlansPage,
  AdminLoginPage,
  AdminSignUpPage,
  AdminViewInvestmentPage,
  AdminViewTransactionsPage,
  ViewAllUsersPage,
} from "./pages/admin";

import AdminCreateSharePage from "./pages/admin/shares/AdminCreateSharePage";
import AdminEditSharePage from "./pages/admin/shares/AdminEditSharePage";
import AdminViewAllSharesPage from "./pages/admin/shares/AdminViewAllSharesPage";
import AdminViewShareHoldersPage from "./pages/admin/shares/AdminViewShareHoldersPage";
import AdminViewSharePage from "./pages/admin/shares/AdminViewSharePage";

import { ForgotPasswordPage, LoginPage, RegisterPage } from "./pages/auth";
import { DashboardPage } from "./pages/dashboard";
import { ComingOutSoon } from "./pages/error";
import { ViewUserProfilePage } from "./pages/general";
import {
  AboutUsPage,
  ContactPage,
  FaqsPage,
  HomeInvestmentsPage,
  HomePage,
  TermsPage,
} from "./pages/home";
import {
  ManagerDashPage,
  ManagerLoginPage,
  ManagerSignUpPage,
  ManagerViewInvestPlansPage,
  ViewAnyUserTransactionsPage,
  ViewManagedUsersPage,
  ViewManagerUserProfilePage,
} from "./pages/manager";
import {
  InvestmentPage,
  ViewAllPlansPage,
  InvestmentPortfolioPage,
  ViewInvestPlanPage,
  ViewMyPortfolioPage,
  InvestmentHistoryPage,
} from "./pages/marketHub/investments";
import ViewAllMySharesPage from "./pages/marketHub/shares/ViewAllMySharesPage";
import ViewAllSharesPage from "./pages/marketHub/shares/ViewAllSharesPage";
import ViewSharesInfoPage from "./pages/marketHub/shares/ViewSharesInfoPage";
import ViewSharesPortfolioPage from "./pages/marketHub/shares/ViewSharesPortfolioPage";
import { ReferralPage, SettingsPage } from "./pages/settings";
import {
  ViewAccountPage,
  ViewBankPage,
  ViewMyTransactionsPage,
} from "./pages/wallet";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/auth/login/" />
      }
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/auth/login/" />
      }
    />
  );
};

const AppRoutes = () => {
  return (
    <>
      <Switch>
        {/* --- Admin Routes Start Here ---- */}
        <Route exact path="/admin/register/" component={AdminSignUpPage} />
        <Route exact path="/admin/login/" component={AdminLoginPage} />

        <AdminRoute
          exact
          path="/admin/transactions/"
          component={AdminViewTransactionsPage}
        />
        <AdminRoute exact path="/admin/users/" component={ViewAllUsersPage} />
        <AdminRoute
          exact
          path="/admin/investments/"
          component={AdminInvestPlansPage}
        />
        <AdminRoute
          exact
          path="/admin/investments/edit/*"
          component={AdminEditInvestPlanPage}
        />
        <AdminRoute
          exact
          path="/admin/investments/create/"
          component={AdminCreateInvestPlanPage}
        />
        <AdminRoute
          exact
          path="/admin/investment/*"
          component={AdminViewInvestmentPage}
        />

        <AdminRoute
          exact
          path="/admin/shares/"
          component={AdminViewAllSharesPage}
        />
        <AdminRoute
          exact
          path="/admin/shares/view/:shareId/holders"
          component={AdminViewShareHoldersPage}
        />
        <AdminRoute
          exact
          path="/admin/shares/view/:shareId"
          component={AdminViewSharePage}
        />

        <AdminRoute
          exact
          path="/admin/shares/edit/:shareId"
          component={AdminEditSharePage}
        />

        <AdminRoute
          exact
          path="/admin/shares/create/"
          component={AdminCreateSharePage}
        />

        <AdminRoute
          exact
          path="/admin/dashboard/"
          component={AdminDashboardPage}
        />
        <AdminRoute exact path="/admin/settings/" component={SettingsPage} />

        <Route exact path="/manager/register/" component={ManagerSignUpPage} />
        <Route exact path="/manager/login/" component={ManagerLoginPage} />
        <Route exact path="/manager/dashboard/" component={ManagerDashPage} />
        <Route
          exact
          path="/manager/view-my-users/"
          component={ViewManagedUsersPage}
        />
        <Route
          exact
          path="/manager/user/profile/*"
          component={ViewManagerUserProfilePage}
        />

        <Route
          exact
          path="/manager/user/transactions/*"
          component={ViewAnyUserTransactionsPage}
        />

        <Route
          exact
          path="/manager/investments/"
          component={ManagerViewInvestPlansPage}
        />

        <ProtectedRoute
          exact
          path="/user/profile/*"
          component={ViewUserProfilePage}
        />

        {/* --- Auth Routes Start Here ---- */}
        <Route exact path="/auth/register/*" component={RegisterPage} />
        <Route exact path="/auth/login/" component={LoginPage} />
        <Route
          exact
          path="/auth/recover-account/"
          component={ForgotPasswordPage}
        />

        <Route
          exact
          path="/user/market/investments/plans"
          component={InvestmentPage}
        />

        <Route
          exact
          path="/user/market/investments/portfolio"
          component={InvestmentPortfolioPage}
        />

        <Route
          exact
          path="/user/market/investments/history"
          component={InvestmentHistoryPage}
        />
        <Route
          exact
          path="/user/market/investments/all/"
          component={ViewAllPlansPage}
        />
        <Route
          exact
          path="/user/market/investments/portfolio/*"
          component={ViewMyPortfolioPage}
        />

        <Route
          exact
          path="/user/transactions/"
          component={ViewMyTransactionsPage}
        />

        <Route exact path="/investment/*" component={ViewInvestPlanPage} />

        <Route exact path="/user/account/view/" component={ViewAccountPage} />
        <Route exact path="/user/account/view/bank/" component={ViewBankPage} />
        <Route exact path="/user/settings/profile/" component={SettingsPage} />

        <Route
          exact
          path="/user/settings/referrals/"
          component={ReferralPage}
        />

        <Route exact path="/user/insurance" component={ComingOutSoon} />

        <Route
          exact
          path="/user/market/shares/plans"
          component={ViewAllSharesPage}
        />
        <Route
          exact
          path="/user/market/view-shares/:shareId"
          component={ViewSharesInfoPage}
        />
        <Route
          exact
          path="/user/market/shares/portfolio/"
          component={ViewSharesPortfolioPage}
        />
        <Route
          exact
          path="/user/market/shares/portfolio/all/"
          component={ViewAllMySharesPage}
        />

        {/* --- Dashboard Routes ---- */}
        <ProtectedRoute exact path="/user*" component={DashboardPage} />

        <Route exact path="/all-investments/" component={HomeInvestmentsPage} />
        <Route exact path="/faqs/" component={FaqsPage} />
        <Route exact path="/terms-privacy-policy/" component={TermsPage} />
        <Route exact path="/contact-us/" component={ContactPage} />
        <Route exact path="/about-us/" component={AboutUsPage} />
        <Route exact path="/*" component={HomePage} />
      </Switch>
    </>
  );
};

export default AppRoutes;
