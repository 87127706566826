import Footer from "./Footer";
import Header from "./Header";
import { Main } from "../../styles/util/UtilStyles";

const Layout = ({ children, notWhiteBg }) => {
  return (
    <>
      <Header notWhiteBg={notWhiteBg} />
      <Main width={"100%"}>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
