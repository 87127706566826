import { Grid } from "@material-ui/core";
import { useState } from "react";
import { MainSection } from "../../../../styles/util/UtilStyles";
import SharesBidModal from "../common/modal/SharesBidModal";
import { ViewAShareButton } from "./ViewAShareButton";
import { ViewAShareContent } from "./ViewAShareContent";
import { ViewAShareImage } from "./ViewAShareImage";
import { ViewASharePricing } from "./ViewASharePricing";

export const ViewAShareBody = ({
  picture,
  amount,
  addedDate,
  amountRemaining,
  bids,
  description,
  minimum,
  status,
  title,
  maxAmount,
  _id,
}) => {
  const [openBidModal, setOpenBidModal] = useState(false);
  const [selectedSharePlan, setSelectedSharePlan] = useState({});

  const handleOpenBidModal = () => {
    setSelectedSharePlan({ amount, minimum, amountRemaining, title, _id });
    setOpenBidModal(true);
  };

  const handleCloseBidModal = () => {
    setSelectedSharePlan({});
    setOpenBidModal(false);
  };

  return (
    <>
      <MainSection width="100%" top={"2em"}>
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ViewAShareImage picture={picture} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ViewASharePricing
              {...{ amount, minimum, amountRemaining, title, maxAmount }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <ViewAShareButton
              {...{
                handleOpenBidModal,
              }}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ViewAShareContent title="Description" text={description} />
          </Grid>
        </Grid>
      </MainSection>

      {openBidModal && (
        <SharesBidModal
          shareData={selectedSharePlan}
          handleClose={handleCloseBidModal}
          open={openBidModal}
        />
      )}
    </>
  );
};
