import { Grid } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { getAllInvestments } from "../../../adapters/investments";
import { AppContext } from "../../../App";
import SkeletonCard from "../../../adapters/utility/SkeletonCard";
import { InvestmentCard } from "../../../components/investments";
import UserLayout from "../../../components/userLayout/UserLayout";
import { SearchBar } from "../../../styles/util/Pickers";
import {
  Button,
  Card,
  GoBack,
  MainSection,
  Option,
  SelectField,
  Text,
} from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

// const placeHolders = [{}, {}, {}, {}, {}, {}];
const FilterOptions = ({ setFilter, filter }) => {
  return (
    <>
      <Card width={"8em"} left={"auto"} background={"transparent"}>
        <Text bottom={"16px"} heavy>
          Filter
        </Text>
        <SelectField
          defaultValue={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Option>Completed</Option>
          <Option>Pending</Option>
          <Option>In Progress</Option>
          <Option>Cancelled</Option>
          <Option>All</Option>
        </SelectField>
      </Card>
    </>
  );
};

const ViewAllPlansPage = () => {
  const appContext = useContext(AppContext);
  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [plansList, setPlansList] = useState([{}, {}, {}, {}, {}, {}]);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const handleSearch = () => {
    console.log(search);
  };

  useEffect(() => {
    //  "&type=Pending,In Progress"
    let newFilter = "&";
    if (filter === "All") {
      newFilter = "&";
    } else {
      newFilter = `&type=${filter}`;
    }

    getAllInvestments(
      setLoadingPlans,
      setPlansList,
      appContext,
      pageNo,
      newFilter
    );
    // eslint-disable-next-line
  }, [appContext, pageNo, filter]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"All Investment Plan"}
        pageTitleComponent={<GoBack url={"/user/market/investments/"} />}
      >
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={8} md={8} sm={12} xs={8}>
              <SearchBar
                bottom={"32px"}
                setSearch={setSearch}
                handleSearch={handleSearch}
              />
            </Grid>

            <Grid item lg={4} md={4} sm={12} xs={4}>
              <FilterOptions setFilter={setFilter} filter={filter} />
            </Grid>
          </Grid>

          {plansList.length ? (
            <Grid container spacing={4}>
              {plansList.map((item, pos) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={pos}>
                  {loadingPlans ? (
                    <SkeletonCard loading={loadingPlans} />
                  ) : (
                    <InvestmentCard appContext={appContext} {...item} />
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <div>
              <NotFoundError errorTitle={"No results found."} />
            </div>
          )}

          <Card top={"64px"} bottom={"32px"} background={"transparent"}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                  border={pageNo > 1 ? "1px solid #670150" : "none"}
                  color={"white"}
                  radius={"12px"}
                  disabled={!pageNo}
                  onClick={previous}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={plansList.length ? "white" : "rgba(5,5,5,0.3)"}
                  border={plansList.length ? "1px solid #670150" : "none"}
                  color={"#670150"}
                  radius={"12px"}
                  disabled={!plansList.length}
                  onClick={next}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Card>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewAllPlansPage;
