import {
  Card,
  Label,
  Text,
  TitleText,
} from "../../../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../../../Utils";

const AmountCard = ({ amount, text, left }) => {
  return (
    <>
      <Label
        background={"white"}
        border="1px solid #D2D2D2"
        width={"157px"}
        height="50px"
        position={"center"}
        radius="5px"
        left={left}
      >
        <Card left="auto" background="transparent" right="auto" width="auto">
          <Text
            size={"12px"}
            color={"#D2D2D2"}
            fontFamily={"Poppins"}
            left={"auto"}
            right={"auto"}
            position="center"
            weight="600"
          >
            {text}
          </Text>
          <Text
            size={"16px"}
            fontFamily={"Poppins"}
            left={"auto"}
            right={"auto"}
            weight="400"
            position="center"
          >
            N{getFormattedAmount(amount)}
          </Text>
        </Card>
      </Label>
    </>
  );
};
export const ViewASharePricing = ({
  amount,
  minimum,
  amountRemaining,
  title,
  maxAmount,
}) => {
  return (
    <>
      <TitleText lineHeight="48px" size="24px" weight="500">
        {title}
      </TitleText>
      <Card background="transparent" flex>
        <AmountCard amount={amount} text="Amount" />
        <AmountCard amount={maxAmount} text="Maximum" left="16px" />
        <AmountCard amount={minimum} text="Minimum" left="16px" />
      </Card>
    </>
  );
};
