import { Grid } from "@material-ui/core";

import {
  Card,
  MainSection,
  TitleText,
} from "../../../../../styles/util/UtilStyles";

import UploadShareImageButton from "../AdminCreateSharesButton/UploadShareImageButton";
import AdminCreateSharesForm from "./AdminCreateSharesForm";
import AdminCreateSharesImage from "./AdminCreateSharesImage";

const AdminCreateSharesBody = ({
  onSubmit,
  handleUpdatePic,
  openFileUpload,
  inputFile,
  loading,
  register,
  image,
  handleSubmit,
  editShareData,
  isEdit,
}) => {
  return (
    <>
      <MainSection width={"100%"}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card>
              <TitleText top={"16px"} left={"16px"}>
                Shares Plan Image
              </TitleText>
              <AdminCreateSharesImage image={image} />
              <UploadShareImageButton
                {...{ handleUpdatePic, openFileUpload, inputFile }}
              />
            </Card>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Card>
              <TitleText top={"16px"} left={"16px"}>
                Shares Plan Details
              </TitleText>
              <Card top={"16px"} bottom={"16px"} left={"16px"} right={"16px"}>
                <AdminCreateSharesForm
                  {...{
                    onSubmit,
                    handleUpdatePic,
                    openFileUpload,
                    inputFile,
                    loading,
                    register,
                    handleSubmit,
                    editShareData,
                    isEdit,
                  }}
                />
              </Card>
            </Card>
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default AdminCreateSharesBody;
