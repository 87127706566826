import { Grid, Modal } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router";
import { deleteInvestmentPlan } from "../../adapters/admin";
import { Box } from "../../styles/util/icon";
import {
  Button,
  Card,
  Label,
  ModalCard,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const DeletePlanModal = ({
  planInfo,
  open,
  handleClose,
  setRefresh,
  appContext,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    let params = {
      investmentID: planInfo?._id,
    };
    deleteInvestmentPlan(
      params,
      setLoading,
      appContext,
      setRefresh,
      handleClose
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard>
          <Card top={"32px"} left={"32px"} right={"32px"} bottom={"32px"}>
            <TitleText lineheight={"1.5em"} size={"18px"} bottom={"16px"}>
              You're about to delete <b>{planInfo?.title}</b>?
            </TitleText>
            <Grid container spacing={4}>
              <Grid item lg={6} sm={6} md={6} xs={6}>
                <Button
                  background={"white"}
                  color={"#670150"}
                  border={"1px solid #670150"}
                  top={"1em"}
                  left={"auto"}
                  radius={"5px"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={6}>
                <Button
                  background={"rgba(200,20,20,0.8)"}
                  color={"white"}
                  // border={"1px solid #670150"}
                  // width={"10em"}
                  top={"1em"}
                  left={"auto"}
                  radius={"5px"}
                  onClick={handleDelete}
                >
                  {loading ? "Please wait..." : " Delete"}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

const InvestPlanCard = ({
  description,
  title,
  status,
  picture,
  _id,
  setRefresh,
  appContext,
  removeDelete,
  isAdmin,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selPlan, setSelPlan] = useState(null);

  const toggleDelete = (planInfo) => {
    setOpen(true);
    setSelPlan(planInfo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card>
        <Card left={"16px"} right={"16px"} top={"16px"} bottom={"16px"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Box width={"5em"} height={"5em"}>
                <img
                  src={picture}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Label
                background={"rgba(242, 145, 27, 0.1)"}
                width={"8em"}
                height={"2.5em"}
                center
              >
                <Text
                  left={"auto"}
                  right={"auto"}
                  top={"8px"}
                  size={"12px"}
                  color={"#670150"}
                >
                  {status}
                </Text>
              </Label>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TitleText
                fontFamily={"Inter"}
                size={"16px"}
                lineheight={"20px"}
                color={"#171717"}
                heavy
              >
                {title}
              </TitleText>
              <Text
                top={"16px"}
                color={"#767676"}
                fontFamily={"Inter"}
                size={"14px"}
                lineheight={"20px"}
              >
                {description}
              </Text>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}></Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card flex spaceBetween>
                <Button
                  color={"#670150"}
                  border={"1px solid #EEEEEE"}
                  background={"white"}
                  width={"7em"}
                  onClick={() =>
                    isAdmin
                      ? history.push(`/admin/investment/${_id}`)
                      : history.push(`/investment/${_id}`)
                  }
                >
                  View Plan
                </Button>
                {!removeDelete && (
                  <Button
                    color={"#F26464"}
                    border={"1px solid #EEEEEE"}
                    background={"white"}
                    width={"7em"}
                    onClick={() => toggleDelete({ title, status, _id })}
                  >
                    Delete
                  </Button>
                )}
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Card>

      <DeletePlanModal
        planInfo={selPlan}
        open={open}
        handleClose={handleClose}
        setRefresh={setRefresh}
        appContext={appContext}
      />
    </>
  );
};

export default InvestPlanCard;
