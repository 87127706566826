import parseError from "./parseError";

const axios = require("axios").default;

/**
 *
 * @param {any} dataObject
 * @returns Promise<{ isError : boolean; data?: { message : { data : any }}; errorMessage ?: string}>
 */

export const fetchApi = async (dataObject, method, url, requestHeaders) => {
  try {
    const { data } = await axios({
      method: method,

      url,

      data: dataObject,

      timeout: 60000,

      headers: requestHeaders,
    });

    return { isError: false, data };
  } catch (error) {
    return parseError(error);
  }
};

export const fetchGetApi = async (dataObject, method, url, requestHeaders) => {
  try {
    // const { data } = await axios({
    //   method: method,

    //   url,

    //   data: dataObject,

    //   timeout: 60000,

    //   headers: requestHeaders,
    // });

    const { data } = axios.get(
      "https://vimevest.herokuapp.com/admin/investment/get",
      {
        params: {
          investmentID: "6210dc1ec74f82242a8e9c9f",
        },
        headers: requestHeaders,
      }
    );

    return { isError: false, data };
  } catch (error) {
    return parseError(error);
  }
};
