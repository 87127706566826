import { Label, LinkText } from "../../../styles/util/UtilStyles";

export const ViewAllPlans = ({ url }) => {
  return (
    <>
      <Label
        border={"1px solid #670150"}
        width={"10em"}
        background={"white"}
        left={"auto"}
      >
        <LinkText
          to={url ? url : "/user/market/investments/all/"}
          nounderline={"true"}
          color={"#670150"}
          center
        >
          View All
        </LinkText>
      </Label>
    </>
  );
};
