import { Grid, TextField } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { managerSignUpApi } from "../../adapters/user";
import {
  Button,
  Card,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const ManagerSignUpPage = () => {
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    
    managerSignUpApi(data, setLoading, history);
  };

  return (
    <Grid container spacing={4} justifyContent={"center"}>
      <Grid item lg={6} md={8} sm={11} xs={12}>
        <MainSection top={"2em"}>
          <Card flexEnd>
            <LinkText
              to="/manager/login/"
              color={"rgba(21, 20, 57, 0.4)"}
              position={"right"}
              lineheight={"64px"}
              nounderline={"true"}
            >
              Already have an Account?{" "}
              <b style={{ color: "#F2994A" }}>Sign In</b>
            </LinkText>
          </Card>
          <Card bottom={"32px"}>
            <TitleText
              top={"0.5em"}
              color={"#670150"}
              lineheight={"32px"}
              size={"32px"}
              heavy
            >
              Create a Portfolio Manager Account.
            </TitleText>
          </Card>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Card top={"8px"} bottom={"8px"}>
              <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
                MANAGER FIRSTNAME
              </Text>
              <TextField
                placeholder="Enter Your Fullname"
                variant="outlined"
                type="text"
                {...register("firstName")}
                required
                fullWidth
              />
            </Card>

            <Card top={"8px"} bottom={"8px"}>
              <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
                MANAGER LASTNAME
              </Text>
              <TextField
                placeholder="Your Last Name"
                variant="outlined"
                type="text"
                {...register("lastName")}
                required
                fullWidth
              />
            </Card>

            <Card top={"8px"} bottom={"8px"}>
              <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
                EMAIL
              </Text>
              <TextField
                placeholder="Enter Your Email"
                variant="outlined"
                type="text"
                {...register("email")}
                required
                fullWidth
              />
            </Card>

            <Card top={"8px"} bottom={"8px"}>
              <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
                PASSWORD
              </Text>
              <TextField
                placeholder="Your Password"
                variant="outlined"
                type="password"
                {...register("password")}
                minLength={8}
                required
                fullWidth
              />
            </Card>

            <Card top={"32px"}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Button
                    type="submit"
                    right={"16px"}
                    background={"#670150"}
                    radius={"100px"}
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Sign Up"}
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Text color={"rgba(21, 20, 57, 0.4)"}>
                    By signing up you agree to Our Terms and Privacy Policy
                  </Text>
                </Grid>
              </Grid>
            </Card>
          </form>
        </MainSection>
      </Grid>
    </Grid>
  );
};

export default ManagerSignUpPage;
