import Carousel from "react-grid-carousel";
// import { useHistory } from "react-router";
import { InvestmentCard } from ".";
import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { NotFoundError } from "../../pages/error";
// import { Box } from "../../styles/util/icon";
import {
  // Button,
  Card,
  carouselDots,
  // Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import ViewAllPlans from "./util/ViewAllPlans";

const carouselsLoader = [{}, {}, {}];

const PendingInvestment = ({
  investedPlans,
  loading,
  appContext,
  title,
  status,
}) => {
  // const history = useHistory();

  return (
    <>
      <Card bottom={"16px"} background={"transparent"}>
        <TitleText>{title}</TitleText>
        <ViewAllPlans url={`/user/market/investments/portfolio/${status}`} />
      </Card>
      <Card
        background={"transparent"}
        borderColor={"0.5px solid rgba(5,5,5,0.3)"}
      >
        <Card
          left={"16px"}
          right={"16px"}
          top={"20px"}
          bottom={"20px"}
          background={"transparent"}
        >
          {loading ? (
            <>
              {carouselsLoader.map((item, pos) => (
                <Carousel.Item key={pos}>
                  <SkeletonCard loading={loading} />
                </Carousel.Item>
              ))}
            </>
          ) : (
            <>
              {investedPlans.length > 0 ? (
                <Carousel
                  cols={2}
                  rows={1}
                  gap={30}
                  dot={carouselDots}
                  loop
                  autoplay={2000}
                  style={{ width: "100%" }}
                  showDots
                >
                  {investedPlans.map((item, pos) => (
                    <Carousel.Item key={pos}>
                      <InvestmentCard
                        appContext={appContext}
                        {...item}
                        //  userState={filter}
                      />
                      {/* <Card
                        left={"16px"}
                        right={"16px"}
                        background={"#F7F8FA"}
                        radius={"7px"}
                      >
                        <Card
                          background={"transparent"}
                          left={"16px"}
                          top={"16px"}
                          right={"16px"}
                          bottom={"16px"}
                        >
                          <Box>
                            <img
                              src={item?.picture}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                          <Card top={"16px"} background={"transparent"} flex>
                            <Text
                              size={"11px"}
                              color={"rgba(0, 0, 0, 0.4)"}
                              lineheight={"29px"}
                            >
                              Investment -
                            </Text>
                            <Text
                              color={"#00000"}
                              size={"16px"}
                              lineheight={"29px"}
                            >
                              N {item?.amountInvested}
                            </Text>
                          </Card>
                          <Card background={"transparent"} flex>
                            <Text
                              size={"11px"}
                              color={"rgba(0, 0, 0, 0.4)"}
                              lineheight={"29px"}
                            >
                              Total Value -
                            </Text>
                            <Text
                              color={"#00000"}
                              size={"16px"}
                              lineheight={"29px"}
                            >
                              N {item?.amount}
                            </Text>
                          </Card>
                          <Button
                            radius={"5px"}
                            background={"#670150"}
                            color={"white"}
                            width={"10em"}
                            top={"16px"}
                            onClick={() =>
                              history.push(`/investment/${item?.investmentID}`)
                            }
                          >
                            View Info
                          </Button>
                        </Card>
                      </Card> */}
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <NotFoundError
                  errorTitle={"No Recently Invested Plans Found!"}
                />
              )}
            </>
          )}
        </Card>
      </Card>
    </>
  );
};

export default PendingInvestment;
