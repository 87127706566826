import { Button, Card } from "../../../../../styles/util/UtilStyles";

const UploadShareImageButton = ({
  openFileUpload,
  handleUpdatePic,
  inputFile,
}) => {
  return (
    <>
      <Card top={"16px"} bottom={"16px"} left={"16px"} right={"16px"}>
        <Button
          top={"16px"}
          bottom={"16px"}
          background={"#670150"}
          color={"white"}
          radius={"5px"}
          onClick={openFileUpload}
        >
          Select Image
        </Button>
        <input
          onChange={handleUpdatePic}
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          accept=".jpg,.png,.bmp"
        />
      </Card>
    </>
  );
};

export default UploadShareImageButton;
