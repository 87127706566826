import { useState } from "react";
import Carousel from "react-grid-carousel";
import {
  Card,
  carouselDots,
  TitleText,
} from "../../../../../styles/util/UtilStyles";

import { ViewAllPlans } from "../../../common";
import SharesUnbidModal from "../../common/modal/SharesUnbidModal";
import { SharesCard } from "../../common/SharesCard";

export const PendingShares = ({ sharesList = [] }) => {
  const [openUnbidModal, setOpenUnbidModal] = useState(false);
  const [selectedSharePlan, setSelectedSharePlan] = useState({});

  const handleClose = () => {
    setOpenUnbidModal(false);
    setSelectedSharePlan({});
  };

  const handleOpen = (sharePlan) => {
    setOpenUnbidModal(true);
    setSelectedSharePlan(sharePlan);
  };
  return (
    <>
      <Card bottom={"16px"} top="32px" background={"transparent"}>
        <TitleText>Pending Shares</TitleText>
        <ViewAllPlans url={`/user/market/shares/portfolio/all/`} />
      </Card>
      <Card
        background={"transparent"}
        borderColor={"0.5px solid rgba(5,5,5,0.3)"}
      >
        <Card
          left={"16px"}
          right={"16px"}
          top={"20px"}
          bottom={"20px"}
          background={"transparent"}
        >
          <Carousel
            cols={2}
            rows={1}
            gap={30}
            dot={carouselDots}
            loop
            autoplay={2000}
            style={{ width: "100%" }}
            showDots
          >
            {sharesList.map((item, pos) => (
              <Carousel.Item key={pos}>
                <SharesCard
                  {...item?.sharesID}
                  status={item?.status}
                  bidAmount={item?.amount}
                  hasUnbidButton
                  isBiddedPlan
                  handleOpen={handleOpen}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Card>
      </Card>

      {openUnbidModal && (
        <SharesUnbidModal
          shareData={selectedSharePlan}
          open={openUnbidModal}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
