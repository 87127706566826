import { useContext, useEffect, useState } from "react";

import { DataGrid } from "@material-ui/data-grid";

import {
  Button,
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import { Collapse, createTheme, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core";
import { AppContext } from "../../App";

import { getFormattedDate } from "../../adapters/utility/Util";

import UserLayout from "../../components/userLayout/UserLayout";
import {
  approveTransaction,
  getAllTransactions,
  revokeTransaction,
} from "../../adapters/admin";
import { getFormattedAmount } from "../../Utils";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const customTheme = {
  palette: { mode: "light" },
};

const defaultTheme = createTheme(customTheme);

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        "& .MuiDataGrid-renderingZone": {
          maxHeight: "none !important",
        },

        "& .MuiDataGrid-row": {
          maxHeight: "none !important",
          maxWidth: "none !important",
        },

        border: 0,
        color:
          theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor:
            theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderRight: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-cell": {
          color:
            theme.palette.mode === "light"
              ? "rgba(0,0,0,.85)"
              : "rgba(255,255,255,0.65)",
          lineHeight: "unset !important",
          maxHeight: "none !important",
          maxWidth: "auto !important",
          whiteSpace: "normal",
        },
        "& .MuiPaginationItem-root": {
          borderRadius: 0,
        },
        ...customCheckbox(theme),
      },
    }),
  { defaultTheme }
);

const AdminViewTransactionsPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [expand, setExpand] = useState({});
  const [success, setSuccess] = useState({});
  const [approving, setApproving] = useState({});

  const appContext = useContext(AppContext);

  useEffect(() => {
    getAllTransactions(setLoading, setResults, appContext, pageNo);
    // eslint-disable-next-line
  }, [appContext, pageNo, success]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleExpand = (pos) => {
    setExpand({ [pos]: true });
  };

  const columns = [
    { field: "_id", headerName: "ID", width: 70, hide: true },
    {
      field: "amount",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Amount",
      minWidth: 150,
      flex: 1,
      renderCell: (rows) => {
        return <div>N{getFormattedAmount(rows?.row?.amount)}</div>;
      },
    },
    {
      field: "userId",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "User",
      minWidth: 250,
      flex: 1,
      renderCell: (rows) => {
        return (
          <Card height={"auto"} background="transparent">
            <Card
              left={"8px"}
              right={"8px"}
              top={"8px"}
              bottom={"16px"}
              background="transparent"
            >
              {!expand[rows.id] && (
                <Button
                  width={"50%"}
                  height={"3.5em"}
                  size={"10px"}
                  background={"white"}
                  border={"1px solid #670150"}
                  color={"#670150"}
                  radius={"12px"}
                  // left={"auto"}
                  right={"auto"}
                  onClick={() => handleExpand(rows.id)}
                >
                  Click to view
                </Button>
              )}
              <Collapse in={expand[rows.id] ? true : false}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TitleText size={"16px"} heavy>
                      <b>User Info:</b>
                    </TitleText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text size={"14px"}>Email : {rows?.row?.user?.email}</Text>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text size={"14px"}>
                      Full Name :{" "}
                      {rows?.row?.user?.firstName +
                        " " +
                        rows?.row?.user?.lastName}
                    </Text>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Text size={"14px"}>
                      Phone Number : {rows?.row?.user?.phoneNumber}
                    </Text>
                  </Grid>
                </Grid>
              </Collapse>
            </Card>
          </Card>
        );
      },
    },
    {
      field: "type",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Type",
      minWidth: 130,
      flex: 1,
      renderCell: (rows) => {
        return <div>{rows?.row?.type}</div>;
      },
    },
    {
      field: "description",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Description",
      minWidth: 170,
      flex: 1,
      renderCell: (rows) => {
        return <div>{rows?.row?.description}</div>;
      },
    },
    {
      field: "userIdk",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Bank Account",
      minWidth: 250,
      flex: 1,
      renderCell: (rows) => {
        return (
          <Card height={"auto"} background="transparent">
            <Card
              left={"8px"}
              right={"8px"}
              top={"8px"}
              bottom={"16px"}
              background="transparent"
            >
              {rows?.row?.type === "Deposit" ||
              rows?.row?.type === "Withdrawal" ? (
                <>
                  {!expand[rows.id] && (
                    <Button
                      width={"50%"}
                      height={"3.5em"}
                      size={"10px"}
                      background={"white"}
                      border={"1px solid #670150"}
                      color={"#670150"}
                      radius={"12px"}
                      // left={"auto"}
                      right={"auto"}
                      onClick={() => handleExpand(rows.id)}
                    >
                      Click to view
                    </Button>
                  )}
                  <Collapse in={expand[rows.id] ? true : false}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TitleText size={"16px"} heavy>
                          {rows?.row?.type === "Deposit" ? (
                            <b>Depositor Bank:</b>
                          ) : (
                            <b>Withdrawal Bank:</b>
                          )}
                        </TitleText>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text size={"14px"}>
                          Account Number :{" "}
                          {rows?.row?.depositAccount?.accountNumber}
                        </Text>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text size={"14px"}>
                          Account Name :{" "}
                          {rows?.row?.depositAccount?.accountName}
                        </Text>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text size={"14px"}>
                          Bank Name : {rows?.row?.depositAccount?.bankName}
                        </Text>
                      </Grid>
                    </Grid>
                  </Collapse>
                </>
              ) : (
                "Not Applicable"
              )}
            </Card>
          </Card>
        );
      },
    },
    {
      field: "status",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (rows) => {
        return (
          <div style={{ textTransform: "uppercase" }}>{rows?.row?.status}</div>
        );
      },
    },
    {
      field: "timestamp",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Date",
      minWidth: 150,
      flex: 1,
      renderCell: (rows) => {
        return (
          // {getFormattedDate('dd')}
          <div>{getFormattedDate(rows?.row?.timestamp)}</div>
        );
      },
    },
    {
      field: "Action",
      headerClassName: "datagridHeader",
      cellClassName: "datagridContent",
      // headerAlign: "center",
      headerName: "Action",
      minWidth: 220,
      flex: 1,
      renderCell: (rows) => {
        return (
          <Card width={"25em"} background={"transparent"} flex spaceBetween>
            {rows?.row?.type === "Deposit" ||
            rows?.row?.type === "Withdrawal" ? (
              <>
                {rows?.row?.status === "Completed" ? (
                  <Button
                    width={"9em"}
                    height={"3.5em"}
                    size={"10px"}
                    background={"white"}
                    border={"1px solid #670150"}
                    color={"#670150"}
                    radius={"12px"}
                    disabled={approving[rows.id] ? true : false}
                    onClick={() => handleRevoke(rows.id)}
                  >
                    {approving[rows.id] ? "Please wait!" : "Revoke"}
                  </Button>
                ) : (
                  <Button
                    width={"9em"}
                    height={"3.5em"}
                    size={"10px"}
                    background={"white"}
                    border={"1px solid #670150"}
                    color={"#670150"}
                    radius={"12px"}
                    disabled={approving[rows.id] ? true : false}
                    onClick={() => handleApprove(rows.id)}
                  >
                    {approving[rows.id] ? "Please wait!" : "Approve"}
                  </Button>
                )}
              </>
            ) : (
              <Button
                width={"9em"}
                height={"3.5em"}
                size={"10px"}
                background={"white"}
                border={"1px solid rgba(5,5,5,0.1)"}
                color={"#670150"}
                radius={"12px"}
                disabled={false}
              >
                Not Applied
              </Button>
            )}
            <Button
              width={"9em"}
              height={"3.5em"}
              size={"10px"}
              background={"#670150"}
              border={"1px solid #670150"}
              color={"white"}
              radius={"12px"}
            >
              Delete
            </Button>
          </Card>
        );
      },
    },
  ];

  const handleApprove = (id) => {
    approveTransaction(setApproving, setSuccess, appContext, id);
  };

  const handleRevoke = (id) => {
    revokeTransaction(setApproving, setSuccess, appContext, id);
  };

  return (
    <>
      <UserLayout pageTitle={"View All Transactions"}>
        <MainSection width={"100%"} top={"2em"}>
          <Card radius={"10px"}>
            <Card
              top={"16px"}
              height={"82em"}
              bottom={"16px"}
              right={"16px"}
              left={"16px"}
            >
              {/* -- I need to Fix the pagination issue but later sha --- */}
              <DataGrid
                rows={results}
                columns={columns}
                className={classes.root}
                // checkboxSelection
                pageSize={15}
                loading={loading}
                rowsPerPageOptions={[2]}
                getRowId={(r) => r._id}
              />
            </Card>
          </Card>

          <Card top={"32px"} bottom={"32px"} background={"transparent"}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                  border={pageNo > 1 ? "1px solid #670150" : "none"}
                  color={"white"}
                  radius={"12px"}
                  disabled={!pageNo}
                  onClick={previous}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={results.length ? "white" : "rgba(5,5,5,0.3)"}
                  border={results.length ? "1px solid #670150" : "none"}
                  color={"#670150"}
                  radius={"12px"}
                  disabled={!results.length}
                  onClick={next}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Card>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default AdminViewTransactionsPage;
