import { Grid } from "@material-ui/core";
import { Circle } from "../../styles/util/icon";
import { Card, Text, TitleText } from "../../styles/util/UtilStyles";

const DashboardCard = ({ title, count, icon, color, bgColor }) => {
  return (
    <>
      <Card height={"10em"}>
        <Card left={"16px"} right={"16px"} top={"32px"} bottom={"16px"}>
          <Grid container spacing={1}>
            <Grid item lg={4} md={6} sm={6} xs={4}>
              <Circle
                style={{ opacity: "0.3" }}
                width={"4em"}
                height={"4em"}
                background={bgColor}
                color={color}
              >
                {icon}
              </Circle>
            </Grid>

            <Grid item lg={8} md={6} sm={6} xs={8}>
              <Card>
                <TitleText
                  fontFamily={"Inter"}
                  color={"#171717"}
                  size={"16px"}
                  heavy
                >
                  {count}
                </TitleText>
                <Text size={"14px"} color={"#B9B9B9"} lineheight={"17px"}>
                  {title}
                </Text>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default DashboardCard;
