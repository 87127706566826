import { Grid } from "@material-ui/core";
import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteSharePlan } from "../../../../../adapters/admin";
import {
  Button,
  Card,
  Label,
  LinkText,
  Text,
  TitleText,
} from "../../../../../styles/util/UtilStyles";
import {
  getFormattedAmount,
  getPercentageRate,
  getTimeAgo,
} from "../../../../../Utils";

const PlanBoxes = ({ title, text }) => {
  return (
    <>
      <Card
        left={"0.5px"}
        right={"0.5px"}
        height={"4.5em"}
        width={"100%"}
        borderColor={"1px double #D2D2D2"}
      >
        <Text
          size={"16px"}
          color={"rgba(0, 0, 0, 0.4)"}
          fontFamily={"Poppins"}
          top={"6px"}
          center
        >
          {title}
        </Text>
        <Text
          lineheight={"27px"}
          size={"18px"}
          color={"#000000"}
          fontFamily={"Poppins"}
          heavy
          center
        >
          {text}
        </Text>
      </Card>
    </>
  );
};

const AdminShareOverview = ({
  title,
  description,
  status,
  addedDate,
  amount,
  bids,
  minimum,
  maxAmount,
  amountRemaining,
  _id,
  appContext,
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleDeletePlan = () => {
    deleteSharePlan({ _id }, setLoading, appContext, history, "/admin/shares/");
  };

  return (
    <>
      <Card bottom={"32px"}>
        <Card top={"16px"} bottom={"16px"} left={"16px"} right={"16px"}>
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TitleText
                color={"#000000"}
                size={"20px"}
                lineheight={"30px"}
                fontFamily={"Poppins"}
                style={{ textTransform: "uppercase" }}
                heavy
              >
                {title}
              </TitleText>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Label
                style={{ display: "block" }}
                position={"center"}
                background={"white"}
                border={"1px solid #670150"}
                radius={"8px"}
              >
                <Text
                  color={"#670150"}
                  size={"16px"}
                  top={"8px"}
                  fontFamily={"Poppins"}
                  center
                  heavy
                >
                  {status}
                </Text>
              </Label>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Card left={"auto"} width={"fit-content"} flex>
                <Text
                  fontFamily={"Poppins"}
                  lineheight={"21px"}
                  size={"14px"}
                  color={"rgba(0, 0, 0, 0.4)"}
                >
                  {getTimeAgo(addedDate)}
                </Text>
                <div>
                  <Text
                    left={"16px"}
                    color={"#670150"}
                    size={"14px"}
                    lineheight={"21px"}
                  >
                    {bids?.length} Share Holders
                  </Text>
                </div>
              </Card>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <PlanBoxes
                    title={"Amount"}
                    text={`N ${getFormattedAmount(amount)}`}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <PlanBoxes
                    title={"Minimum"}
                    text={`N ${getFormattedAmount(minimum)}`}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <PlanBoxes
                    title={"Maximum"}
                    text={`N ${getFormattedAmount(maxAmount)}`}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={6}>
                  <PlanBoxes title={"Total Bids"} text={bids?.length} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TitleText color={"#000000"} size={"18px"} lineheight={"27px"}>
                Overview
              </TitleText>
              <Text
                color={"rgba(0, 0, 0, 0.4)"}
                size={"14px"}
                fontFamily={"Poppins"}
                lineheight={"21px"}
                top={"16px"}
              >
                {description}
              </Text>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TitleText
                bottom={"16px"}
                color={"#000000"}
                size={"16px"}
                lineheight={"27px"}
              >
                Purchase Completion
              </TitleText>
              <ProgressBar
                height={"1em"}
                completed={100 - getPercentageRate(amountRemaining, amount)}
                maxCompleted={100}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <LinkText
                left="auto"
                right="auto"
                top="16px"
                to={`/admin/shares/view/${_id}/holders`}
              >
                View Share Holders
              </LinkText>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              {status === "Pending" && (
                <Button
                  background={"rgba(200,20,20,0.8)"}
                  color={"white"}
                  top={"1em"}
                  left={"auto"}
                  radius={"5px"}
                  onClick={handleDeletePlan}
                  disabled={loading}
                >
                  {loading ? "Deleting..." : "Delete Plan"}
                </Button>
              )}
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Button
                top={"1em"}
                radius={"5px"}
                background={"#670150"}
                color={"white"}
                onClick={() => history.push(`/admin/shares/edit/${_id}`)}
              >
                Edit Plan
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default AdminShareOverview;
