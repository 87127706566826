import { useState } from "react";

import { MainSection } from "../../../../../styles/util/UtilStyles";
import AdminDeleteShareModal from "../../common/modal/AdminDeleteShareModal";
import { AdminViewShares } from "./AdminViewShares";
import { AdminViewSharesNav } from "./AdminViewSharesNav";

export const AdminViewAllSharesBody = ({ sharesList = [] }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedSharePlan, setSelectedSharePlan] = useState({});

  const handleDeleteModal = (shareData) => {
    setSelectedSharePlan(shareData);
    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setSelectedSharePlan({});
    setOpenDeleteModal(false);
  };

  return (
    <>
      <MainSection width="100%">
        <AdminViewShares
          {...{ sharesList }}
          handleDeleteModal={handleDeleteModal}
        />
        <AdminViewSharesNav />
      </MainSection>

      {openDeleteModal && (
        <AdminDeleteShareModal
          open={openDeleteModal}
          handleClose={handleCloseModal}
          sharesPlanData={selectedSharePlan}
        />
      )}
    </>
  );
};
