import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ForumIcon from "@material-ui/icons/Forum";
import { MeetingRoomRounded } from "@material-ui/icons";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SettingsIcon from "@material-ui/icons/Settings";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PeopleIcon from "@material-ui/icons/People";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const dashboardUpper = [
  {
    icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
    text: "HOME",
    route: "#",
    privilege: "All",
    inner: [
      {
        icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Dashboard",
        route: "/user/",
      },
    ],
  },
  {
    icon: <LibraryBooksIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
    text: "MARKET HUB",
    route: "#",
    privilege: "User",
    inner: [
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Insurance",
        route: "/user/insurance/",
        privilege: "User",
      },
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Investment",
        route: "#",
        subGroup: true,
        key: 8,
        inner: [
          {
            icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
            text: "Investment Plans",
            route: "/user/market/investments/plans",
          },
          {
            icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
            text: "Investment Portfolio",
            route: "/user/market/investments/portfolio",
          },
          {
            icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
            text: "Investment History",
            route: "/user/market/investments/history",
          },
        ],
      },
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Shares",
        route: "#",
        subGroup: true,
        privilege: "User",
        key: 9,
        inner: [
          {
            icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
            text: "Shares Plans",
            route: "/user/market/shares/plans",
          },
          {
            icon: <DashboardIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
            text: "Shares Portfolio",
            route: "/user/market/shares/portfolio",
          },
        ],
      },
    ],
  },
  {
    icon: <MeetingRoomRounded style={{ color: "rgba(103, 1, 80, 1)" }} />,
    text: "ACCOUNT",
    route: "/user/calendar/",
    privilege: "User",
    inner: [
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Manage Account",
        route: "/user/account/view/",
        privilege: "User",
      },
    ],
  },
  {
    icon: <SupervisorAccountIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
    text: "ADMIN",
    route: "#",
    privilege: "Admin",
    inner: [
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "All Users",
        route: "/admin/users/",
      },
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Create Investment",
        route: "/admin/investments/",
      },
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Transactions",
        route: "/admin/transactions/",
      },
    ],
  },

  {
    icon: <PeopleAltOutlinedIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
    text: "SETTINGS",
    route: "/user/",
    privilege: "All",
    inner: [
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Profile",
        route: "/user/settings/profile/",
        privilege: "All",
      },
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Invite a friend",
        route: "/user/settings/referrals/",
        privilege: "User",
      },
      {
        icon: <ForumIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
        text: "Log Out",
        route: "/user/",
        privilege: "All",
      },
    ],
  },
];

export const dashboardAdmins = [
  {
    icon: <SupervisorAccountIcon />,
    text: "Dashboard",
    route: "/admin/dashboard/",
  },
  {
    icon: <MonetizationOnIcon />,
    text: "Investment",
    route: "/admin/investments/",
  },
  {
    icon: <MonetizationOnIcon />,
    text: "Shares",
    route: "/admin/shares/",
  },
  {
    icon: <PeopleIcon />,
    text: "Users",
    route: "/admin/users/",
  },
  {
    icon: <ReceiptIcon />,
    text: "Transactions",
    route: "/admin/transactions/",
  },
  {
    icon: <SupervisorAccountIcon />,
    text: "Settings",
    route: "/admin/settings/",
  },
];

export const dashboardManager = [
  {
    icon: <SupervisorAccountIcon />,
    text: "Dashboard",
    route: "/manager/dashboard",
  },
  {
    icon: <MonetizationOnIcon />,
    text: "Investment",
    route: "/manager/investments/",
  },
  {
    icon: <PeopleIcon />,
    text: "My Users",
    route: "/manager/view-my-users/",
  },
  {
    icon: <SupervisorAccountIcon />,
    text: "Settings",
    route: "/user/settings/profile/",
  },
];

export const dashboardBottom = [
  {
    icon: <SettingsIcon style={{ color: "rgba(103, 1, 80, 1)" }} />,
    text: "Settings",
    route: "/user/",
    inner: [],
  },
  // {
  //   icon: <ExitToAppIcon style={{ color: "#C03030" }} />,
  //   text: "Logout",
  //   route: "/auth/logout/",
  //   inner: [],
  // },
];
