import { useContext, useState } from "react";

import { useForm } from "react-hook-form";

import { Grid, TextField } from "@material-ui/core";
import { Button, Card, TitleText } from "../../styles/util/UtilStyles";
import { changePasswordApi } from "../../adapters/user";
import { AppContext } from "../../App";

const ChangePasswordCard = () => {
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    changePasswordApi(data, setLoading, appContext);
  };

  return (
    <>
      <Card radius={"10px"}>
        <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
          <TitleText bottom={"16px"} fontFamil={"Poppins"}>
            Change Password
          </TitleText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Current Password"
                  variant="outlined"
                  type="password"
                  {...register("currentPassword")}
                  required
                  fullWidth
                />
                {errors.exampleRequired && <span>This field is required</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="New Password"
                  variant="outlined"
                  type="password"
                  {...register("newPassword")}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Confirm New Password"
                  variant="outlined"
                  type="password"
                  {...register("confirmPassword")}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 0.1)"}
                  color={"#670150"}
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Save New Password"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Card>
    </>
  );
};

export default ChangePasswordCard;
