import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Button,
  Card,
  LinkText,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const YourReferralLinkCard = ({ user }) => {
  const [copied, setCopied] = useState(false);
  return (
    <>
      <Card radius={"10px"}>
        <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
          <TitleText bottom={"16px"} fontFamil={"Poppins"}>
            Your Referral Link
          </TitleText>
          <Text bottom={"16px"} color={"rgba(0, 0, 0, 0.4)"} size={"12px"}>
            Help your friends discover Vimevest and earn 2% on their investment
            interest when they sign up with your referral link.
          </Text>

          <Card
            background={"rgba(230, 247, 255, 0.3)"}
            left={"2em"}
            right={"2em"}
            top={"16px"}
            bottom={"16px"}
          >
            <Card
              background={"transparent"}
              top={"16px"}
              bottom={"16px"}
              right={"16px"}
              left={"16px"}
            >
              <LinkText center to="">
                https://vimevest.com/auth/register/{user?.userCode}
              </LinkText>
              <CopyToClipboard
                text={`https://vimevest.com/auth/register/${user?.userCode}`}
                onCopy={() => setCopied(true)}
              >
                <Button
                  width={"10em"}
                  center
                  radius={"10px"}
                  background={"#670150"}
                  color={"white"}
                  top={"16px"}
                >
                  {copied ? "Copied" : "Copy"}
                </Button>
              </CopyToClipboard>
            </Card>
          </Card>
        </Card>
      </Card>
    </>
  );
};

export default YourReferralLinkCard;
