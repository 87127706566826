import {
  Faqs,
  HomeContactUs,
  HomeTopView,
  Subscribers,
  Testimonials,
  WhyChooseVimevest,
} from "../../components/home";

import Layout from "../../components/homeLayout/Layout";

const HomePage = () => {
  return (
    <>
      <Layout notWhiteBg>
        <HomeTopView />
        <WhyChooseVimevest />
        <Testimonials />
        <HomeContactUs />
        <Faqs />
        <Subscribers />
      </Layout>
    </>
  );
};

export default HomePage;
