import { FilledInput, Grid } from "@material-ui/core";
import { Button, Card } from "./UtilStyles";

export const SearchBar = ({ top, bottom, setSearch, handleSearch }) => {
  return (
    <Card width={"100%"} top={top} background={"white"} bottom={bottom}>
      <Card
        left={"8px"}
        top={"8px"}
        right={"8px"}
        background={"white"}
        bottom={"8px"}
      >
        <Grid container spacing={0}>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <FilledInput
              type="text"
              placeholder={"Type something here..."}
              required
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              disableUnderline
              style={{
                background: "white",
                border: "1px solid #D2D2D2",
                borderRadius: "5px 0px 0px 5px",
                width: "100%",
                height: "3em",
              }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Button
              border={"1px solid white"}
              width={"100%"}
              background={"#670150"}
              color={"white"}
              radius={"0px 5px 5px 0px"}
              height={"3.5em"}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};
