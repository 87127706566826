import { AuthLayout, Login } from "../../components/auth";

const LoginPage = () => {
  return (
    <>
      <AuthLayout>
        <Login />
      </AuthLayout>
    </>
  );
};

export default LoginPage;
