import { Grid } from "@material-ui/core";
import { SecurityOutlined } from "@material-ui/icons";
import { Box } from "../../styles/util/icon";
import {
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const whyChooseContent = [
  {
    title: "CLIENT FOCUSED",
    text: "We are sensitive to the needs of our clients and our utmost concern is their satisfaction and comfort.",
    icon: "",
  },
  {
    title: "EXPERTISE & EXPERIENCE",
    text: "Vimevest provides experts that offer our clients relevant information regarding their assets and investment.",
    icon: "",
  },
  {
    title: "SECURITY",
    text: "We offer a secure platform and secure plans for our clients.",
    icon: "",
  },
];

const WhyChooseVimevest = () => {
  return (
    <>
      <MainSection>
        <Card
          top={"32px"}
          shadowAlt={"0px 20px 50px 5px rgba(30, 37, 94, 0.15)"}
        >
          <TitleText
            color={"#11142D"}
            fontFamily={"Mulish"}
            size={"24px"}
            top={"24px"}
            heavy
            center
          >
            Why invest with Vimevest?
          </TitleText>
          <Card top={"32px"} bottom={"32px"}>
            <Grid container spacing={4}>
              {whyChooseContent.map((item, pos) => (
                <Grid item lg={4} md={4} sm={4} xs={12} key={pos}>
                  <Card
                    style={{ borderRight: "1px solid #670150" }}
                    top={"16px"}
                  >
                    <Box radius={"50%"} background={"#670150"} center>
                      <SecurityOutlined
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      />
                    </Box>
                    <Text
                      top={"16px"}
                      color={"#120216"}
                      fontFamily={"Manrope"}
                      size={"20px"}
                      heavy
                      center
                    >
                      {item?.title}
                    </Text>
                    <Text
                      fontFamily={"DM Sans"}
                      color={"#120216"}
                      top={"20px"}
                      size={"14px"}
                      left={"8px"}
                      right={"8px"}
                      lineheight={"24px"}
                      center
                    >
                      {item?.text}
                    </Text>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Card>
      </MainSection>
    </>
  );
};

export default WhyChooseVimevest;
