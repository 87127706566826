import { useContext, useEffect, useState } from "react";
import { getMyShares } from "../../../adapters/investments";
import { STATUSES } from "../../../adapters/utility/enums";
import { AppContext } from "../../../App";

import {
  ViewMySharesBody,
  ViewMySharesLoading,
} from "../../../components/marketHub/shares/ViewMySharesPortfolio";
import UserLayout from "../../../components/userLayout/UserLayout";
import { GoBack } from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;

const ViewSharesPortfolioPage = () => {
  const appContext = useContext(AppContext);

  const [status, setStatus] = useState(LOADING);
  const [status2, setStatus2] = useState(LOADING);

  const [approvedShares, setApprovedShares] = useState([]);
  const [pendingShares, setPendingShares] = useState([]);

  useEffect(() => {
    let params = { limit: 6, status: "Approved" };
    getMyShares(params, setStatus2, appContext, setApprovedShares);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let params = { limit: 6, status: "Pending" };
    getMyShares(params, setStatus, appContext, setPendingShares);
    // eslint-disable-next-line
  }, []);

  const renderBaseOnStatusPending = () => {
    switch (status) {
      case LOADING:
        return <ViewMySharesLoading />;
      case DATA:
        return (
          <ViewMySharesBody
            {...{ approvedShares, pendingShares }}
            sharesType={"Pending"}
          />
        );
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"No Shares Added Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  const renderBaseOnStatusApproved = () => {
    switch (status2) {
      case LOADING:
        return <ViewMySharesLoading />;
      case DATA:
        return (
          <ViewMySharesBody
            {...{ approvedShares, pendingShares }}
            sharesType={"Approved"}
          />
        );
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"No Shares Added Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"My Shares Portfolio"}
      >
        {renderBaseOnStatusApproved()}

        {renderBaseOnStatusPending()}
      </UserLayout>
    </>
  );
};

export default ViewSharesPortfolioPage;
