import { useContext, useEffect, useState } from "react";
import { getAllSharesPlans } from "../../../adapters/investments";
import { STATUSES } from "../../../adapters/utility/enums";
import { goNextPage, goPreviousPage } from "../../../adapters/utility/Util";
import { AppContext } from "../../../App";
import ViewMySharesButton from "../../../components/marketHub/shares/common/button/ViewMySharesButton";

import {
  ViewSharesBody,
  ViewSharesPreloader,
} from "../../../components/marketHub/shares/ViewAllShares";

import UserLayout from "../../../components/userLayout/UserLayout";

import { NotFoundError } from "../../error";

const ViewAllSharesPage = () => {
  const appContext = useContext(AppContext);

  const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;
  const [status, setStatus] = useState(DATA);
  const [pageNo, setPageNo] = useState(0);

  const [sharesList, setSharesList] = useState([]);

  useEffect(() => {
    getAllSharesPlans({}, setStatus, appContext, setSharesList, pageNo);
    // eslint-disable-next-line
  }, [appContext]);

  const renderBaseOnStatus = () => {
    switch (status) {
      case LOADING:
        return <ViewSharesPreloader />;
      case DATA:
        return (
          <ViewSharesBody
            {...{
              previous: goPreviousPage(pageNo, setPageNo),
              next: goNextPage(pageNo, setPageNo),
              pageNo,
              sharesList,
            }}
          />
        );
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"No Shares Added Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"Shares Plan"}
        pageTitleComponent={<ViewMySharesButton />}
      >
        {renderBaseOnStatus()}
      </UserLayout>
    </>
  );
};

export default ViewAllSharesPage;
