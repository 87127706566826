import { Grid, Modal } from "@material-ui/core";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteSharePlan } from "../../../../../adapters/admin";
import { AppContext } from "../../../../../App";

import {
  Button,
  Card,
  ModalCard,
  TitleText,
  Text,
} from "../../../../../styles/util/UtilStyles";

const AdminDeleteShareModal = ({ open, handleClose, sharesPlanData }) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const appContext = useContext(AppContext);

  const handleDelete = () => {
    deleteSharePlan(sharesPlanData, setLoading, appContext, history);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard radius={"10px"} top={"20vh"} width={"50%"} WidthResponsive>
          <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
            <Text fontFamily={"Poppins"} bottom={"16px"} heavy>
              {sharesPlanData?.title}
            </Text>
            <TitleText bottom={"16px"} heavy>
              Are You Sure?
            </TitleText>

            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 0.1)"}
                  color={"#670150"}
                  top={"16px"}
                  onClick={handleClose}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 1)"}
                  color={"white"}
                  top={"16px"}
                  onClick={handleDelete}
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Delete"}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

export default AdminDeleteShareModal;
