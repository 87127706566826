import { toast } from "react-toastify";
import { STATUSES } from "../utility/enums";

import { fetchApi } from "../utility/fetchApi";

import { baseUrl } from "../utility/Util";

const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;

export const updateAUserProfile = async (
  params,
  setResult,
  setLoading,
  appContext
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/users/edit`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResult(data?.data?.user);
      return toast.success("Succesfully updated the user profile.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const getAllUsers = async (
  setLoading,
  setResults,
  appContext,
  pageNo,
  filter
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/users/usersFilter/?page=${pageNo}${filter}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.users);
    }
  };

  const response = await getResponse();
  return response;
};

export const suspendOrActivateAUser = async (
  isActive,
  params,
  setResult,
  setLoading,
  appContext
) => {
  setLoading(true);
  let APIUrl = `${baseUrl}`;

  if (isActive) {
    APIUrl = `${baseUrl}/admin/users/suspend`;
  } else {
    APIUrl = `${baseUrl}/admin/users/unsuspend`;
  }

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResult(data?.data?.user);
      return toast.success(isActive ? "User suspended." : "User Activated.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const createInvestPlan = async (
  params,
  setLoading,
  appContext,
  history
) => {
  if (
    !params?.picture
    // &&!params?.confirmPassword
  ) {
    return toast.warning("Ensure an image is selected.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/admin/investment/create/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Succesfully created the investment plan.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return setTimeout(() => {
        history.push("/admin/investments/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const deleteUser = async (
  params,
  setLoading,
  appContext,
  setRefresh,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/users/delete/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "DELETE",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setRefresh(Date.now());
      handleClose();
      return toast.success("User deleted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const deleteInvestmentPlan = async (
  params,
  setLoading,
  appContext,
  setRefresh,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/investment/delete/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setRefresh(Date.now());
      handleClose();
      return toast.success("Plan deleted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const deleteUserdd = async (
  params,
  setLoading,
  appContext,
  setRefresh,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/users/delete/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "DELETE",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setRefresh(Date.now());
      handleClose();
      return toast.success("User deleted successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const getInvestPlanDetails = async (params, setLoading, appContext) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/investment/get/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      console.log(data);
    }
  };

  const response = await getResponse();
  return response;
};

// ---- Invvestmnents ----

export const getInvestInfoAsAdmin = async (
  params,
  setLoading,
  setResults,
  appContext
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/investment/get/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  console.log("received", params);
  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      console.log("error", errorMessage);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      // setResults(data?.data?.users);
      console.log("results", data?.data);
    }
  };

  const response = await getResponse();
  return response;
};

export const getAllTransactions = async (
  setLoading,
  setResults,
  appContext,
  pageNo
) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/transaction/fetch?page=${pageNo}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.transactions);
      console.log("my transactions", data?.data?.transactions);
    }
  };

  const response = await getResponse();
  return response;
};

export const approveTransaction = async (
  setLoading,
  setSuccess,
  appContext,
  transactionID
) => {
  const { token } = appContext;
  // setLoading(true);
  const APIUrl = `${baseUrl}/admin/transaction/complete/${transactionID}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      { transactionID },
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading({ [transactionID]: true });
      setSuccess(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // setLoading(false);
      setSuccess(true);
      return toast.success("Transaction approved successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const revokeTransaction = async (
  setLoading,
  setSuccess,
  appContext,
  transactionID
) => {
  const { token } = appContext;
  // setLoading(true);
  const APIUrl = `${baseUrl}/admin/transaction/cancel/${transactionID}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      { transactionID },
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading({ [transactionID]: true });
      setSuccess(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // setLoading(false);
      setSuccess(true);
      return toast.success("Transaction revoked successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const getStatData = async (setLoading, setStatData, appContext) => {
  const { token } = appContext;
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/profile/stats/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setStatData(data?.data);
    }
  };

  const response = await getResponse();
  return response;
};

export const assignManagerToUser = async (
  userId,
  managerId,
  setLoading,
  appContext
) => {
  setLoading(true);

  let APIUrl = `${baseUrl}/admin/users/assignToManager`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      {
        userEmail: userId,
        managerEmail: managerId,
      },
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      return toast.success(
        "New Manager has been assigned to the user successfully.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const response = await getResponse();
  return response;
};

// ----- Shares Feature ----

export const getAdminSharesPlan = async (
  params,
  setLoading,
  appContext,
  setResults
) => {
  setLoading(LOADING);
  const APIUrl = `${baseUrl}/admin/shares/fetch`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(ERROR);
      setResults([]);
    } else {
      let shares = data.data.shares;
      if (shares?.length > 0) {
        setResults(shares);
        setLoading(DATA);
      } else {
        setResults([]);
        setLoading(NULL_DATA);
      }
    }
  };

  const response = await getResponse();
  return response;
};

export const createSharePlan = async (
  params,
  setLoading,
  appContext,
  history
) => {
  if (!params?.picture) {
    return toast.warning("Ensure an image is selected.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/admin/shares/create/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Succesfully created the share plan.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return setTimeout(() => {
        history.push("/admin/shares/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const updateSharePlan = async (params, setLoading, appContext) => {
  if (!params?.picture) {
    return toast.warning("Ensure an image is selected.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/admin/shares/edit/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Succesfully updated.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const deleteSharePlan = async (
  params,
  setLoading,
  appContext,
  history,
  redirectUrl
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/shares/delete/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "DELETE",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Succesfully deleted the investment plan.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return setTimeout(() => {
        redirectUrl ? history.push(redirectUrl) : history.go();
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const getAdminApproveBid = async (
  params,
  setLoading,
  appContext,
  setStatus
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/shares/approve/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setStatus("Pending");
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setStatus("Approved");
      toast.success("User's bid approved.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const getAdminRevokeBid = async (
  params,
  setLoading,
  appContext,
  setStatus
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/admin/shares/disapprove/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setStatus("Approved");
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setStatus("Pending");
      toast.success("User's bid revoked.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const getAdminShareInfo = async (
  params,
  setLoading,
  appContext,
  setResult
) => {
  setLoading(LOADING);
  const APIUrl = `${baseUrl}/admin/shares/get/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(ERROR);
      setResult({});
    } else {
      let shares = data.data.shares;
      setLoading(DATA);
      setResult(shares);
    }
  };

  const response = await getResponse();
  return response;
};

export const getAdminShareHolders = async (
  params,
  setLoading,
  appContext,
  setResult
) => {
  setLoading(LOADING);
  const APIUrl = `${baseUrl}/admin/shares/approvedBids/${params?._id}`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi(
      params,
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(ERROR);
      setResult([]);
    } else {
      setLoading(DATA);
      setResult(data.data?.approvedBids);
      console.log("result", data.data?.approvedBids);
    }
  };

  const response = await getResponse();
  return response;
};
