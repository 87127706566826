import { Grid } from "@material-ui/core";
import { createRef } from "react";
import ReactToPdf from "react-to-pdf";

import { getFormattedDate } from "../../adapters/utility/Util";
import {
  Button,
  Card,
  Divider,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../Utils";

const Transact = ({ amount, description, timestamp, type, status }) => {
  return (
    <>
      <Card bottom="1em">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Card>
              <Text lineheight="40px" size="14px">
                Amount:
              </Text>
              <Text lineheight="40px" size="14px">
                Type:
              </Text>
              <Text lineheight="40px" size="14px">
                Description:
              </Text>
              <Text lineheight="40px" size="14px">
                Status:
              </Text>
              <Text lineheight="40px" size="14px">
                Date:
              </Text>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Card width="fit-content">
              <Text lineheight="40px" size="14px" color="rgba(5,5,5,0.5)">
                N{getFormattedAmount(amount)}
              </Text>
              <Text lineheight="40px" size="14px" color="rgba(5,5,5,0.5)">
                {type}
              </Text>
              <Text lineheight="40px" size="14px" color="rgba(5,5,5,0.5)">
                {description ? description : "Nothing here."}
              </Text>
              <Text lineheight="40px" size="14px" color="rgba(5,5,5,0.5)">
                {status}
              </Text>
              <Text lineheight="40px" size="14px" color="rgba(5,5,5,0.5)">
                {getFormattedDate(timestamp)}
              </Text>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider color="rgba(230,230,230,1)" size="0.2px" />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const TransactionReceiptPage = ({ results }) => {
  const ref = createRef();
  //   const options = {
  //     orientation: "landscape",
  //     unit: "in",
  //     // format: [4, 2],
  //   };

  return (
    <>
      <MainSection bottom="2em">
        <Card ref={ref}>
          <Card left="auto" width="90%" top="2em" right="auto">
            <TitleText color="#670150" bottom="1em" heavy={true}>
              My Transaction Summary
            </TitleText>
            {results.map((item, pos) => (
              <Transact key={pos} {...item} />
            ))}
          </Card>
        </Card>
        <ReactToPdf targetRef={ref} filename="my-transaction-summary.pdf">
          {({ toPdf }) => (
            <Button
              onClick={toPdf}
              width={"10em"}
              radius={"5px"}
              left={"auto"}
              top={"2em"}
              color={"white"}
              right="2em"
              bottom="2em"
              background="#670150"
            >
              Download
            </Button>
          )}
        </ReactToPdf>
      </MainSection>
    </>
  );
};

export default TransactionReceiptPage;
