import React, { useEffect, useState } from "react";

import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import BlockIcon from "@material-ui/icons/Block";
// import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

import {
  UserNoteCard,
  UserNoteCardHead,
  UserNoteLeftTitle,
  UserNoteRightTitle,
} from "../../styles/dashboard/UtilLayoutStyles";
import { Card, Text } from "../../styles/util/UtilStyles";
import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { getNotifications } from "../../adapters/user";
import { getFormattedDate } from "../../adapters/utility/Util";
import { getTimeAgo } from "../../Utils";

// const notes = [
//   {
//     id: 1,
//     img: "",
//     text: "Hi, welcome to Vimevest. Stay tuned!.",
//     date:
//       new Date().getDate() +
//       " / " +
//       new Date().getMonth() +
//       " / " +
//       new Date().getFullYear(),
//   },
//   {
//     id: 1,
//     img: "",
//     text: "Hi, welcome to Vimevest. Stay tuned!.",
//     date:
//       new Date().getDate() +
//       " / " +
//       new Date().getMonth() +
//       " / " +
//       new Date().getFullYear(),
//   },
//   {
//     id: 1,
//     img: "",
//     text: "Hi, welcome to Vimevest. Stay tuned!.",
//     date:
//       new Date().getDate() +
//       " / " +
//       new Date().getMonth() +
//       " / " +
//       new Date().getFullYear(),
//   },
// ];

export const UserNotes = (props) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);

  const { appContext } = props;
  useEffect(() => {
    if (props?.popOpen2) {
      getNotifications(appContext, setFetching, setData);
    }
    // eslint-disable-next-line
  }, [appContext, props?.popOpen2]);

  return (
    <Menu
      id="fade-menu"
      anchorEl={props?.anchorEl2}
      // keepMounted
      style={{ zIndex: "10000000", marginTop: "4em", marginLeft: "-2em" }}
      open={props?.popOpen2}
      onClose={props?.handleClose2}
      TransitionComponent={Fade}
    >
      <UserNoteCard>
        <UserNoteCardHead style={{ display: "none" }}>
          <UserNoteLeftTitle>Notifications</UserNoteLeftTitle>
          <UserNoteRightTitle>
            Mark as Read{" "}
            <BlockIcon style={{ fontSize: "1em", marginLeft: "0.3em" }} />{" "}
          </UserNoteRightTitle>
        </UserNoteCardHead>

        {/* {notes} */}
        {fetching ? (
          <Card left={"16px"} right={"16px"} top={"8px"} radius={"8px"}>
            <SkeletonCard loading={fetching} height={"20em"} />
          </Card>
        ) : (
          <>
            {!data.length ? (
              <Card
                left={"16px"}
                right={"16px"}
                background={"rgba(103, 1, 80, 0.05)"}
                top={"16px"}
                radius={"8px"}
                // height={"4em"}
              >
                <Card
                  left={"16px"}
                  right={"16px"}
                  bottom={"20px"}
                  background={"transparent"}
                  top={"20px"}
                >
                  <Text size={"12px"} heavy>
                    You do not have recent notifications.
                  </Text>
                </Card>
              </Card>
            ) : (
              <Card>
                {data.map((eachNote, key) => (
                  <Card
                    key={key}
                    left={"16px"}
                    right={"16px"}
                    background={"rgba(103, 1, 80, 0.05)"}
                    top={"8px"}
                    radius={"8px"}
                    // height={"4em"}
                  >
                    <Card
                      left={"16px"}
                      right={"16px"}
                      bottom={"16px"}
                      background={"transparent"}
                      top={"16px"}
                    >
                      {/* <TitleText size={"16px"} lineheight={"32px"} heavy>
                        Welcome Back!
                      </TitleText> */}
                      <Text lineheight={"20px"} size={"14px"}>
                        {eachNote?.description}
                      </Text>
                      <Text lineheight={"32px"} size={"12px"}>
                        {getFormattedDate(eachNote?.timestamp)}

                        {" - " + getTimeAgo(eachNote?.timestamp)}
                      </Text>
                    </Card>
                  </Card>
                ))}
              </Card>
            )}
          </>
        )}

        {/* <Card left={"16px"} right={"16px"}>
          <Button>Read more</Button>
        </Card> */}
      </UserNoteCard>
    </Menu>
  );
};
