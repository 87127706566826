import { useForm } from "react-hook-form";

import { Grid, TextField } from "@material-ui/core";
import { Button, Card, TitleText } from "../../styles/util/UtilStyles";

const TwoFaCard = () => {
    const { register, handleSubmit } = useForm();
    const onSubmit = data => console.log(data);

    return (
        <>
            <Card radius={"10px"}>
                <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
                    <TitleText bottom={"16px"} fontFamil={"Poppins"}>Two factor Authentication</TitleText>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField id="outlined-required" label="Enter Pin" variant="outlined" type="number"   {...register("pin")} required fullWidth />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Button type="submit" background={"rgba(103, 1, 80, 0.1)"} color={"#670150"}>Save New Passcode</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            </Card>
        </>
    )
};

export default TwoFaCard;