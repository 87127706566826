import { Grid, Modal } from "@material-ui/core";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../../../App";
import {
  Button,
  Card,
  Label,
  ModalCard,
  Text,
  TitleText,
} from "../../../../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../../../../Utils";

const SharesUnbidModal = ({ shareData, handleClose, open }) => {
  const { title, amountRemaining, bidAmount, amount } = shareData;
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const appContext = useContext(AppContext);

  const handleSubmit = () => {
    setLoading(true);
    console.log(appContext);
    console.log(history);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard radius={"10px"} top={"20vh"} width={"50%"} WidthResponsive>
          <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TitleText bottom={"16px"} fontFamil={"Poppins"} heavy>
                  Proceed to Revoke Your Bid
                </TitleText>
                <Text color={"rgba(103, 1, 80, 1)"} heavy>
                  {title}
                </Text>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card flex spaceBetween>
                  <Card>
                    <Text
                      size={"12px"}
                      fontFamily={"Poppins"}
                      left={"auto"}
                      bottom="8px"
                      heavy
                    >
                      My Bid Price
                    </Text>
                    <Label
                      background={"white"}
                      width={"8em"}
                      position={"center"}
                      border={"1px solid rgba(103, 1, 80, 0.1)"}
                    >
                      <Text
                        size={"12px"}
                        color={"#670150"}
                        fontFamily={"Poppins"}
                        left={"auto"}
                        right={"auto"}
                        heavy
                      >
                        N {getFormattedAmount(bidAmount)}
                      </Text>
                    </Label>
                  </Card>
                  <Card>
                    <Text
                      size={"12px"}
                      fontFamily={"Poppins"}
                      left={"auto"}
                      bottom="8px"
                      heavy
                    >
                      Share Price
                    </Text>
                    <Label
                      background={"white"}
                      width={"8em"}
                      position={"center"}
                      border={"1px solid rgba(103, 1, 80, 0.1)"}
                    >
                      <Text
                        size={"12px"}
                        color={"#670150"}
                        fontFamily={"Poppins"}
                        left={"auto"}
                        right={"auto"}
                        heavy
                      >
                        N {getFormattedAmount(amount)}
                      </Text>
                    </Label>
                  </Card>
                  <Card>
                    <Text
                      size={"12px"}
                      fontFamily={"Poppins"}
                      left={"auto"}
                      bottom="8px"
                      heavy
                    >
                      Remaining Amount
                    </Text>
                    <Label
                      background={"white"}
                      width={"8em"}
                      position={"center"}
                      border={"1px solid rgba(103, 1, 80, 0.1)"}
                    >
                      <Text
                        size={"12px"}
                        color={"#670150"}
                        fontFamily={"Poppins"}
                        left={"auto"}
                        right={"auto"}
                        heavy
                      >
                        N {getFormattedAmount(amountRemaining)}
                      </Text>
                    </Label>
                  </Card>
                </Card>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Button
                  background={"white"}
                  border={"1px solid rgba(103, 1, 80, 0.3)"}
                  color={"#670150"}
                  top={"16px"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 0.1)"}
                  color={"#670150"}
                  top={"16px"}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? "Please wait..." : "Confirm"}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

export default SharesUnbidModal;
