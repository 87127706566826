import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../App";
import {
  LayoutHeader,
  LayoutHeaderInner,
} from "../../styles/dashboard/UtilLayoutStyles";
import { VimeVestIcon } from "../../styles/util/icon";
import {
  Button,
  Card,
  LinkText,
  ScreenControl,
} from "../../styles/util/UtilStyles";

const Header = ({ notWhiteBg }) => {
  const history = useHistory();

  const appContext = useContext(AppContext);

  const gotoDashoard = () => {
    const { user } = appContext;
    if (user?.userType === "Manager") {
      return history.push("/manager/dashboard");
    }

    if (user?.userType === "Admin") {
      return history.push("/admin/dashboard");
    }
    return history.push("/user/");
  };

  return (
    <>
      <LayoutHeader notWhiteBg={notWhiteBg}>
        <LayoutHeaderInner>
          <Grid container spacing={4}>
            <Grid item lg={3} md={3} sm={5} xs={3}>
              <VimeVestIcon />
            </Grid>
            {/* https://www.figma.com/file/QbaXRjEmzktOZrMPkqhuIu/Viminvest?node-id=48%3A182 */}
            <Grid item lg={5} md={3} sm={1} xs={2}>
              <ScreenControl lg md>
                <Card background="transparent" flex spaceBetween>
                  <LinkText color="#120216" to="/" nounderline="true">
                    Home
                  </LinkText>
                  <LinkText color="#120216" to="/about-us/" nounderline="true">
                    About Us
                  </LinkText>
                  <LinkText
                    to="/all-investments/"
                    color="#120216"
                    nounderline="true"
                  >
                    Investments
                  </LinkText>
                  <LinkText
                    color="#120216"
                    to="/contact-us/"
                    nounderline="true"
                  >
                    Contact Us
                  </LinkText>
                </Card>
              </ScreenControl>
            </Grid>
            <Grid item lg={4} md={3} sm={6} xs={7}>
              {appContext?.user ? (
                <Card background={"transparent"} flex flexEnd>
                  <Button
                    width={"10em"}
                    background="transparent"
                    border="1px solid #670150"
                    color="#670150"
                    radius="5px"
                    onClick={gotoDashoard}
                  >
                    My Dashboard
                  </Button>
                </Card>
              ) : (
                <Card background={"transparent"} flex flexEnd>
                  <Button
                    width={"10em"}
                    background="transparent"
                    border="1px solid #670150"
                    color="#670150"
                    radius="5px"
                    onClick={() => history.push("/auth/login/")}
                  >
                    Sign In
                  </Button>
                  <Button
                    width={"10em"}
                    left={"32px"}
                    background="#670150"
                    color="white"
                    radius="5px"
                    onClick={() => history.push("/auth/register/")}
                  >
                    Sign Up
                  </Button>
                </Card>
              )}
            </Grid>
          </Grid>
        </LayoutHeaderInner>
      </LayoutHeader>
    </>
  );
};

export default Header;
