import { useContext, useEffect, useState } from "react";
import Carousel from "react-grid-carousel";

import {
  getAllInvestments,
  getMyInvestments,
} from "../../../adapters/investments";

import SkeletonCard from "../../../adapters/utility/SkeletonCard";
import { AppContext } from "../../../App";

import {
  InvestedPlan,
  InvestmentCard,
  PendingInvestment,
} from "../../../components/investments";

import UserLayout from "../../../components/userLayout/UserLayout";
import {
  Card,
  carouselDots,
  MainSection,
  TitleText,
} from "../../../styles/util/UtilStyles";

import ViewAllPlans from "../../../components/investments/util/ViewAllPlans";
import { NotFoundError } from "../../error";

// const investplans = [{}, {}, {}, {}];

const carouselsLoader = [{}, {}, {}];
const InvestmentPortfolioPage = () => {
  const appContext = useContext(AppContext);

  const [plansList, setPlansList] = useState([{}, {}, {}, {}]);
  const [allPlans, setAllPlans] = useState([]);

  const [loadingPlans, setLoadingPlans] = useState(false);
  const [loadingInvests, setLoadingInvests] = useState(false);
  const [investments, setInvestments] = useState({
    Pending: [],
    In_Progress: [],
    Completed: [],
    Cancelled: [],
  });

  useEffect(() => {
    // setLoadingInvests,
    // setAllPlans,
    // appContext,
    // 1,

    getAllInvestments(
      setLoadingPlans,
      setPlansList,
      appContext,
      1,
      "&type=Pending,In Progress"
    );
    getMyInvestments(
      setLoadingInvests,
      setAllPlans,
      appContext,
      1,
      setInvestments
    );
    console.log("tested", allPlans);
    // eslint-disable-next-line
  }, [appContext]);

  return (
    <>
      <UserLayout pageTitle={"Investments Portfolio"}>
        <MainSection width={"100%"} top={"2em"}>
          <Card bottom={"16px"} background={"transparent"}>
            <TitleText top={"0px"}>Your Portfolio</TitleText>
            <ViewAllPlans url={"/user/market/investments/portfolio/"} />
          </Card>
          <Card
            background={"transparent"}
            borderColor={"0.5px solid rgba(5,5,5,0.3)"}
          >
            <Card
              background={"transparent"}
              left={"16px"}
              right={"16px"}
              top={"20px"}
              bottom={"20px"}
            >
              {loadingInvests ? (
                <>
                  {carouselsLoader.map((item, pos) => (
                    <Carousel.Item key={pos}>
                      <SkeletonCard loading={loadingPlans} />
                    </Carousel.Item>
                  ))}
                </>
              ) : (
                <>
                  {[...investments["In_Progress"], ...investments["Pending"]]
                    ?.length > 0 ? (
                    <Carousel
                      cols={2}
                      rows={1}
                      gap={30}
                      dot={carouselDots}
                      loop
                      autoplay={2000}
                      style={{ width: "100%" }}
                      showDots
                    >
                      {[
                        ...investments["In_Progress"],
                        ...investments["Pending"],
                      ]?.map((item, pos) => (
                        <Carousel.Item key={pos}>
                          <InvestedPlan {...item} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  ) : (
                    <NotFoundError
                      errorTitle={"No Recently Invested Plans Found!"}
                    />
                  )}
                </>
              )}
            </Card>
          </Card>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default InvestmentPortfolioPage;
