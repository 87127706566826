import moment from "moment";
// ---- all Algorithms here ---

export const getPercentageRate = (value, total) => {
  return (value * 100) / total;
};

export const getTimeAgo = (date) => {
  return moment(date).fromNow(true) + " Ago";
};

export const getPercentageTime = (dateStarted, noOfDays) => {
  let startedDate = moment(dateStarted).format("X");
  let futureDate = moment(dateStarted).add(noOfDays, "days").format("X");
  let currentDate = moment().format("X");

  let percent =
    ((currentDate - startedDate) / (futureDate - startedDate)) * 100;

  if (percent <= 100) {
    return Number.parseInt(percent);
  }

  return 100;
};

export const getFormattedFutureDate = (startDate, noOfDays) =>
  moment(startDate).add(noOfDays, "days").format("MMMM DD, YYYY");

export const truncateText = (text, number) => {
  if (text !== undefined) {
    return text.replace(/^(.{40}[^\s]*).*/, "$1");
  } else {
    return "";
  }
};

export const reverseString = (str) => {
  var newString = "";
  for (var i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
};

export const getFormattedAmount = (amt) => {
  if (amt === undefined || amt === null) {
    return;
  }

  let amount = String(amt);
  let size = String(amt).length;
  let div = Number.parseInt(size / 3);
  let newAmount = "";
  let rem = size % 3;

  for (let i = div; i >= 0; i--) {
    let end = i * 3 + rem;
    let start = end - 3;
    let newAmt = amount.substring(start, end);

    if (i === 0) {
      newAmount += reverseString(newAmt);
    } else {
      newAmt = "," + newAmt;
      newAmount += reverseString(newAmt);
    }
  }

  if (rem === 0) {
    newAmount = reverseString(newAmount).substring(1);
  } else {
    newAmount = reverseString(newAmount);
  }

  return newAmount;
};
