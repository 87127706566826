import { toast } from "react-toastify";

import { fetchApi } from "../utility/fetchApi";

import { baseUrl } from "../utility/Util";

export const updateBank = async (params, setLoading, appContext) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/profile/update`;

  const { token, handleSetUser } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      handleSetUser(data?.data?.user);
      return toast.success("Withdrawal Bank updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const addNewBank = async (
  params,
  setLoading,
  appContext,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/profile/addBank`;

  const { token, handleSetUser } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);

      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      handleSetUser(data?.data?.user);
      handleClose();
      return toast.success("Withdrawal Bank updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const fundWallet = async (
  params,
  setLoading,
  appContext,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/fund/fund/`;

  const { token, handleSetUser } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      handleSetUser(data?.data?.user);
      handleClose();
      return toast.success("Successfully funded your wallet!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const withdralWallet = async (
  params,
  setLoading,
  appContext,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/fund/withdrawal/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      handleClose();
      return toast.success(
        "Your withdrawal has been initiated. It will reflect in your bank when approved.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const response = await getResponse();
  return response;
};

export const depositWallet = async (
  params,
  setLoading,
  appContext,
  handleClose
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/fund/deposit/`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      handleClose();
      return toast.success(
        "Your Deposit has been initiated. It will reflect in your wallet when approved.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const response = await getResponse();
  return response;
};
