import { useContext, useEffect, useState } from "react";
import { getMyShares } from "../../../adapters/investments";
import { STATUSES } from "../../../adapters/utility/enums";
import { goNextPage, goPreviousPage } from "../../../adapters/utility/Util";
import { AppContext } from "../../../App";

import {
  LoadingViewAllMyShares,
  ViewAllMySharesBody,
} from "../../../components/marketHub/shares/ViewAllMyShares";

import UserLayout from "../../../components/userLayout/UserLayout";
import { GoBack } from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

const ViewAllMySharesPage = () => {
  const appContext = useContext(AppContext);

  const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;
  const [status, setStatus] = useState(DATA);
  const [pageNo, setPageNo] = useState(1);
  const [shares, setShares] = useState([]);
  const [sharesType, setSharesType] = useState("Approved");
  useEffect(() => {
    console.log(appContext);
    let params = { limit: 20, status: sharesType, page: pageNo };
    getMyShares(params, setStatus, appContext, setShares);
    // eslint-disable-next-line
  }, [sharesType, pageNo]);

  const renderBaseOnStatus = () => {
    switch (status) {
      case LOADING:
        return <LoadingViewAllMyShares />;
      case DATA:
        return (
          <ViewAllMySharesBody
            sharesList={shares}
            filter={sharesType}
            setFilter={setSharesType}
            {...{
              previous: goPreviousPage(pageNo, setPageNo),
              next: goNextPage(pageNo, setPageNo),
              pageNo,
            }}
          />
        );
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"You Have Bought None Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"View All My Shares"}
        pageTitleComponent={<GoBack />}
      >
        {renderBaseOnStatus()}
      </UserLayout>
    </>
  );
};

export default ViewAllMySharesPage;
