import {
  Button,
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import homeImage from "../../assets/img/home/Web-BannerArtboard-1.png";
import { useHistory } from "react-router";

const HomeTopView = () => {
  const history = useHistory();

  return (
    <>
      <Card top={"0em"} height={"90vh"} background={"#EAEAEE"}>
        <MainSection top={"8em"}>
          <TitleText
            fontFamily={"Mulish"}
            color={"#120216"}
            size={"32px"}
            heavy
            center
          >
            VikkyMore Investment Management
          </TitleText>
          <Text
            fontFamily={"Mulish"}
            color={"#120216"}
            top={"20px"}
            lineheight={"32px"}
            center
          >
            We will help to develop every smallest thing into a big one for your
            company.
          </Text>
          <Button
            width={"10em"}
            top={"20px"}
            background={"#670150"}
            color={"white"}
            onClick={() => history.push("/all-investments/")}
            center
          >
            Invest Now
          </Button>
        </MainSection>
      </Card>

      <MainSection
        top={"-25vh"}
        width={"85%"}
        bottom={"10em"}
        // style={{ border: "1px solid red" }}
      >
        <img src={homeImage} alt="" style={{ width: "100%", height: "100%" }} />
      </MainSection>
    </>
  );
};

export default HomeTopView;
