import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";

import { createSharePlan } from "../../../adapters/admin";
import { getBase64 } from "../../../adapters/utility/Util";
import { AppContext } from "../../../App";

import AdminCreateSharesBody from "../../../components/admin/shares/AdminCreateShares/AdminCreateSharesBody/AdminCreateSharesBody";
import UserLayout from "../../../components/userLayout/UserLayout";
import { GoBack } from "../../../styles/util/UtilStyles";

const AdminCreateSharePage = () => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const appContext = useContext(AppContext);

  const inputFile = useRef(null);

  const openFileUpload = () => {
    inputFile.current.click();
  };

  const handleUpdatePic = (e) => {
    if (e?.target?.value?.length) {
      getBase64(e.target.files[0]).then((data) => setImage(data));
    }
  };

  const history = useHistory();

  const onSubmit = (data) => {
    data.picture = image;
    createSharePlan(data, setLoading, appContext, history);
  };

  return (
    <>
      <UserLayout pageTitle={"Create Share Plan"} pageTitleComponent={<GoBack />}>
        <AdminCreateSharesBody
          {...{
            onSubmit,
            handleUpdatePic,
            openFileUpload,
            inputFile,
            loading,
            register,
            handleSubmit,
            image,
          }}
        />
      </UserLayout>
    </>
  );
};

export default AdminCreateSharePage;
