import { Grid, Modal, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { withdralWallet } from "../../adapters/account";
import {
  Button,
  Card,
  ModalCard,
  Option,
  SelectField,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const WithdrawModal = ({ open, handleClose, appContext }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);

  const [userInfo, setUserInfo] = useState({ accountNumber: "" });

  useEffect(() => {
    if ((appContext?.user !== null) & (appContext?.user !== undefined)) {
      setUserInfo(appContext?.user);
      setBanks(appContext?.user?.banks);
      console.log("user info", userInfo);
    }
    // eslint-disable-next-line
  }, [appContext]);

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.amount = Number.parseInt(data.amount);
    data.bankID = Number.parseInt(data?.bankID);
    console.log("data", data);
    withdralWallet(data, setLoading, appContext, handleClose);
  };

  // console.log('user info', )
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard radius={"10px"} top={"20vh"} width={"50%"} WidthResponsive>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TitleText color={"#0F2B3D"} size={"18px"} heavy>
                    Withdraw Money
                  </TitleText>

                  {banks.length ? (
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                          Amount
                        </Text>
                        <TextField
                          id="outlined-required"
                          label="Enter Amount"
                          variant="outlined"
                          type="number"
                          {...register("amount")}
                          // max={200}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text
                          color={"#2D3748"}
                          top={"0px"}
                          fontFamily={"Helvetica"}
                          lineheight={"20px"}
                          size={"14px"}
                          bottom={"16px"}
                        >
                          Select an Account
                        </Text>
                        <SelectField
                          label="Bank Name"
                          variant="outlined"
                          type="text"
                          height={"4em"}
                          {...register("bankID")}
                          // defaultValue={bankName}
                          required
                          fullWidth
                        >
                          {banks.map((bank, pos) => (
                            <Option key={pos} value={pos}>
                              {bank?.accountNumber}
                            </Option>
                          ))}
                        </SelectField>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text
                          color={"#393939"}
                          size={"14px"}
                          lineheight={"32px"}
                          bottom={"16px"}
                        >
                          Unfortunately, you are yet to add withdrawal details
                          to your account.
                          <br />
                          Click the button below to do that.
                        </Text>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          width={"12em"}
                          background={"#670150"}
                          radius={"15px"}
                          onClick={() =>
                            history.push("/user/account/view/bank/")
                          }
                        >
                          Go to Account.
                        </Button>
                      </Grid>
                      {/* <Grid item lg={12} md={12} sm={12} xs={6}>
                                      <Text color={"#393939"} size={"14px"} bottom={"16px"}>Payment type</Text>
                                      <TextField id="outlined-required" label="Enter Transfer Id" variant="outlined" type="text" placeholder="Transfer to..." required fullWidth />
                                  </Grid> */}
                    </Grid>
                  )}
                </Grid>

                {/* Check if the user already added their account number there */}
                {banks?.length ? (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TitleText color={"#0F2B3D"} size={"18px"} heavy>
                      Verify Identity
                    </TitleText>
                    <Text
                      bottom={"12px"}
                      color={"rgba(57, 57, 57, 0.5)"}
                      size={"14px"}
                    >
                      Enter your password to verify your identity and place your
                      withdrawal.
                    </Text>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                          Password
                        </Text>
                        <TextField
                          id="outlined-required"
                          label="Enter Password"
                          variant="outlined"
                          type="password"
                          {...register("password")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          width={"12em"}
                          background={loading ? "rgba(5,5,5,0.3)" : "#670150"}
                          radius={"15px"}
                          disabled={loading}
                          type="submit"
                        >
                          {loading ? "Please wait..." : "Withdraw"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Card>
          </form>
        </ModalCard>
      </Modal>
    </>
  );
};

export default WithdrawModal;
