import { MainSection } from "../../../../../styles/util/UtilStyles";

import { MySharesFilter } from "./MySharesFilter";
import { ViewMyShares } from "./ViewMyShares";
import { ViewMySharesNav } from "./ViewMySharesNav";

export const ViewAllMySharesBody = ({
  sharesList = [],
  filter,
  setFilter,
  next,
  previous,
  pageNo,
}) => {
  return (
    <>
      <MainSection width={"100%"} top={"2em"}>
        <MySharesFilter {...{ filter, setFilter }} />
        <ViewMyShares {...{ sharesList, filter }} />
        <ViewMySharesNav {...{ sharesList, next, previous, pageNo }} />
      </MainSection>
    </>
  );
};
