import { Button } from "../../../../styles/util/UtilStyles";

export const ViewAShareButton = ({ handleOpenBidModal }) => {
  return (
    <>
      <Button
        width={"159px"}
        height={"3.5em"}
        size={"12px"}
        background="#670150"
        color={"white"}
        radius={"5px"}
        left="auto"
        top="20px"
        onClick={handleOpenBidModal}
      >
        Bid Now
      </Button>
    </>
  );
};
