import { CircularProgress, Grid } from "@material-ui/core";
import { Button, Card, Text, TitleText } from "../../styles/util/UtilStyles";

const ProfileActionCard = ({ title, description, hideBtn }) => {
  return (
    <>
      <Card background={"#320528"} radius={"10px"}>
        <Card
          background={"transparent"}
          top={"16px"}
          bottom={"16px"}
          right={"16px"}
          left={"16px"}
        >
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={12} xs={6}>
              <Card background={"transparent"}>
                <CircularProgress
                  style={{ width: "5em", height: "5em", color: "white" }}
                />
              </Card>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={6}>
              <TitleText color={"white"}>
                {title ? title : "Profile Information"}
              </TitleText>
              <Text color={"rgba(255, 255, 255, 0.7)"} size={"12px"}>
                {description
                  ? description
                  : "Please complete your profile information"}
              </Text>
            </Grid>
            {!hideBtn && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  type="submit"
                  background={"#670150"}
                  color={"white"}
                  radius={"10px"}
                >
                  Save Changes
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default ProfileActionCard;
