import { Avatar, Grid, Modal } from "@material-ui/core";
import {
  AccessibilityNewOutlined,
  CallOutlined,
  SignalWifi0BarOutlined,
  SupervisedUserCircle,
} from "@material-ui/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import { deleteUser } from "../../adapters/admin";
import { Circle } from "../../styles/util/icon";
import {
  Button,
  Card,
  ModalCard,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const DeleteUserModal = ({
  user,
  open,
  handleClose,
  setRefresh,
  appContext,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    let params = {
      userID: user?._id,
    };
    deleteUser(params, setLoading, appContext, setRefresh, handleClose);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard>
          <Card top={"32px"} left={"32px"} right={"32px"} bottom={"32px"}>
            <TitleText lineheight={"1.5em"} size={"18px"} bottom={"16px"}>
              You're about to delete{" "}
              <b>{user?.firstName + " " + user?.lastName}</b>?
            </TitleText>
            <Grid container spacing={4}>
              <Grid item lg={6} sm={6} md={6} xs={6}>
                <Button
                  background={"white"}
                  color={"#670150"}
                  border={"1px solid #670150"}
                  top={"1em"}
                  left={"auto"}
                  radius={"5px"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={6}>
                <Button
                  background={"rgba(200,20,20,0.8)"}
                  color={"white"}
                  // border={"1px solid #670150"}
                  // width={"10em"}
                  top={"1em"}
                  left={"auto"}
                  radius={"5px"}
                  onClick={handleDelete}
                >
                  {loading ? "Please wait..." : " Delete"}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

const UsersCard = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  status,
  userType,
  type,
  wallet,
  managerID,
  picture,
  setRefresh,
  _id,
  appContext,
  managedByMe,
  isManager,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [selUser, setSelUser] = useState(null);
  const { user } = appContext;
  // --- Get the List of Managers ---
  const { managersList } = appContext;

  const toggleDelete = (user) => {
    setOpen(true);
    setSelUser(user);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card>
        <Card left={"16px"} right={"16px"} top={"16px"} bottom={"16px"}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={3}>
              <Circle background={"#F5F5F5"}>
                <Avatar src={picture} />
              </Circle>
            </Grid>
            <Grid item lg={9} md={9} sm={9} xs={9}>
              <Card>
                <TitleText
                  fontFamily={"Inter"}
                  size={"20px"}
                  lineheight={"29px"}
                  color={"#22242C"}
                  center
                >
                  {firstName + " " + lastName}
                </TitleText>
                <Text
                  color={"#8E8EA1"}
                  size={"12px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  center
                >
                  {email}
                </Text>
              </Card>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
              >
                Contact
              </Text>

              <Card flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <CallOutlined />
                </Circle>
                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {phoneNumber ? phoneNumber : "Not Added Yet!"}
                </Text>
              </Card>
              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
                top={"16px"}
              >
                Activeness
              </Text>
              <Card top={"16px"} flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <SignalWifi0BarOutlined />
                </Circle>
                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {status ? status : "Active"}
                </Text>
              </Card>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
              >
                Basic Info
              </Text>

              <Card flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <AccessibilityNewOutlined />
                </Circle>
                <Text
                  color={"#22242C"}
                  size={"10px"}
                  lineheight={"17px"}
                  fontFamily={"Inter"}
                  top={"8px"}
                  left={"4px"}
                  heavy
                >
                  {userType ? userType : type}
                </Text>
              </Card>

              <Text
                color={"#8E8EA1"}
                size={"12px"}
                lineheight={"17px"}
                fontFamily={"Inter"}
                bottom={"16px"}
                top={"16px"}
              >
                Managed By
              </Text>

              <Card flex>
                <Circle width={"32px"} height={"32px"} background={"#670150"}>
                  <SupervisedUserCircle />
                </Circle>
                {managedByMe ? (
                  <Text
                    color={"#22242C"}
                    size={"10px"}
                    lineheight={"17px"}
                    fontFamily={"Inter"}
                    top={"8px"}
                    left={"4px"}
                    heavy
                  >
                    Me
                  </Text>
                ) : (
                  <Text
                    color={"#22242C"}
                    size={"10px"}
                    lineheight={"17px"}
                    fontFamily={"Inter"}
                    top={"8px"}
                    left={"4px"}
                    heavy
                  >
                    {managerID ? (
                      <>
                        {managersList.dict[managerID]?.firstName
                          ? managersList.dict[managerID]?.firstName +
                            " " +
                            managersList.dict[managerID]?.lastName
                          : "None"}
                      </>
                    ) : (
                      "Not Applicable"
                    )}
                  </Text>
                )}
              </Card>
            </Grid>

            {!(type === "Admin" || userType === "Admin") && (
              <>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  {user?.userType === "Admin" && (
                    <Button
                      background={"rgba(200,20,20,0.8)"}
                      color={"white"}
                      // border={"1px solid #670150"}
                      // width={"10em"}
                      top={"1em"}
                      left={"auto"}
                      radius={"5px"}
                      onClick={() =>
                        toggleDelete({
                          _id,
                          firstName,
                          lastName,
                        })
                      }
                    >
                      Delete {userType}
                    </Button>
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button
                    background={"white"}
                    color={"#670150"}
                    border={"1px solid #670150"}
                    // width={"10em"}
                    top={"1em"}
                    left={"auto"}
                    radius={"5px"}
                    onClick={() =>
                      history.push(
                        isManager
                          ? `/manager/user/profile/${_id}`
                          : `/user/profile/${_id}`
                      )
                    }
                  >
                    View User
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Card>

      <DeleteUserModal
        appContext={appContext}
        user={selUser}
        open={open}
        handleClose={handleClose}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default UsersCard;
