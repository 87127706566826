import { Grid, Modal, TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { bidForASharePlan } from "../../../../../adapters/investments";
import { AppContext } from "../../../../../App";

import {
  Button,
  Card,
  ModalCard,
  Text,
  TitleText,
} from "../../../../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../../../../Utils";

const SharesBidModal = ({ shareData, handleClose, open }) => {
  const { title, _id, minimum, amountRemaining } = shareData;
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const appContext = useContext(AppContext);

  const onSubmit = (data) => {
    data._id = _id;
    bidForASharePlan(data, setLoading, appContext, history);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard radius={"10px"} top={"20vh"} width={"50%"} WidthResponsive>
          <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={4}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TitleText bottom={"16px"} fontFamil={"Poppins"} heavy>
                    Proceed to Submit Your Bid
                  </TitleText>
                  <Text color={"rgba(103, 1, 80, 1)"} bottom={"20px"} heavy>
                    {title}
                  </Text>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        label="Enter Amount"
                        variant="outlined"
                        type="number"
                        {...register("amount")}
                        required
                        fullWidth
                      />
                      {errors.exampleRequired && (
                        <span>This field is required</span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card top="32px">
                      <Text
                        bottom={"16px"}
                        color={"rgba(57, 57, 57, 0.7)"}
                        size={"12px"}
                        heavy
                      >
                        Minimum Amount : N{getFormattedAmount(minimum)}
                      </Text>
                      <Text
                        bottom={"16px"}
                        color={"rgba(57, 57, 57, 0.7)"}
                        size={"12px"}
                        heavy
                      >
                        Maximum Amount : N{getFormattedAmount(amountRemaining)}
                      </Text>
                    </Card>
                  </Grid>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Text
                    bottom={"8px"}
                    color={"rgba(57, 57, 57, 0.7)"}
                    size={"12px"}
                    top="32px"
                  >
                    - You will be charged from your current wallet balance.
                  </Text>
                  <Text
                    bottom={"8px"}
                    color={"rgba(57, 57, 57, 0.7)"}
                    size={"12px"}
                  >
                    - However, if you do not have any amount in your balance,
                    you will be required to fund it first.
                  </Text>
                  <Text
                    bottom={"8px"}
                    color={"rgba(57, 57, 57, 0.7)"}
                    size={"12px"}
                  >
                    - The minimum amount above indicates the lowest amount you
                    can invest for this plan.
                  </Text>
                  <Text
                    bottom={"8px"}
                    color={"rgba(57, 57, 57, 0.7)"}
                    size={"12px"}
                  >
                    - The amount remaining above indicates the highest amount
                    left and required at the moment to start this plan.
                  </Text>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        type="submit"
                        background={"rgba(103, 1, 80, 0.1)"}
                        color={"#670150"}
                        top={"16px"}
                        disabled={loading}
                      >
                        {loading ? "Please wait..." : "Submit Bid"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

export default SharesBidModal;
