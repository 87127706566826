import React, { useContext, useEffect, useState } from "react";

import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";

import {
  UserHeader,
  UserHeaderAvatar,
  UserHeaderNote,
  UserHeaderLogoBox,
  UserHeaderName,
  UserHeaderRight,
  UserSideBarMenu,
  UserSideBarMenuIcon,
  // UserLogo,
  UserPopUpCard,
  UserPopUpEach,
  UserPopUpText,
  UserPopUpIcon,
  LayoutTitle,
  LayoutEnvironment,
  LayoutContainer,
} from "../../styles/dashboard/UtilLayoutStyles";
import { Link, useHistory } from "react-router-dom";

import { Avatar, Grid } from "@material-ui/core";
import { UserNotes } from "./UserNotes";
import { useStyles } from "./useStyles";
import { dashboardUpper, dashboardAdmins, dashboardManager } from "./Navs";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Card, LinkText, Text, TitleText } from "../../styles/util/UtilStyles";
import { AppContext } from "../../App";
import { getAllManagers, getUserDetailsApi } from "../../adapters/user";
import { Circle, VimeVestIcon } from "../../styles/util/icon";

const UserLayout = ({ pageTitle, pageTitleComponent, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  // const[position,setPosition]=useState()

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopUp = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAway = (e) => {
    const button = document.querySelector("#toggle-btn");
    if (!button.contains(e.target)) {
      handleDrawerClose();
    }
  };

  // const popOpen = Boolean(anchorEl);
  // const id = popOpen ? "simple-popover" : undefined;
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    if (anchorEl2 !== undefined) {
      setAnchorEl2(event.currentTarget);
    } else {
      setAnchorEl2(null);
    }
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const popOpen2 = Boolean(anchorEl2);
  const id2 = popOpen2 ? "simple-popover" : undefined;

  // handle the log out here
  const handleLogout = () => {
    localStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      history.push("/auth/login/");
    }, 3000);
  };

  const [expand, setExpand] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  });

  const handleDropDown = (pos) => {
    if (expand?.[pos]) {
      setExpand({ ...expand, [pos]: false });
    } else {
      setExpand({ ...expand, [pos]: true });
    }
  };

  const history = useHistory();
  const appContext = useContext(AppContext);
  const { user } = appContext;

  // const user = {
  //   isAdmin: false,
  // };

  // --- Get the User Details Here ---
  useEffect(() => {
    getUserDetailsApi(history, appContext);
    // eslint-disable-next-line
  }, [history]);

  // --- Set The list of managers here ----
  useEffect(() => {
    getAllManagers(appContext);
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />

        {/* --- Layout Header --- */}
        <UserHeader>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={6} md={6} sm={6}>
              <UserSideBarMenuIcon>
                <IconButton
                  id="toggle-btn"
                  color="inherit"
                  // ariaLabel="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                  key={2}
                >
                  <MenuIcon style={{ color: "rgba(31, 40, 71, 1)" }} />
                </IconButton>
                <LayoutTitle>
                  {/* dggg */}
                  <VimeVestIcon style={{ float: "right" }} />
                  {/* {pageTitle} */}
                </LayoutTitle>
              </UserSideBarMenuIcon>
            </Grid>

            {/* <Grid item lg={4} xs={4} md={3} sm={3}>
              <UserLogo to="/">
                <img src={} alt="" style={{ width: "80%" }} />
              </UserLogo>
            </Grid> */}

            <Grid item lg={6} xs={6} md={6} sm={6}>
              <UserHeaderRight>
                <UserHeaderNote onClick={handleClick2}>
                  <NotificationsNoneIcon />
                </UserHeaderNote>
                <UserHeaderAvatar
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                >
                  <Avatar
                    style={{
                      width: "1.7em",
                      height: "1.7em",
                      marginTop: "0.3em",
                    }}
                  />
                </UserHeaderAvatar>
                <UserHeaderName
                  style={{ cursor: "pointer" }}
                  onClick={handleClick}
                >
                  {/* Hi{" appContext?.user?.email"} */}
                  Hi, {user?.firstName}.
                  <ArrowDropDownRoundedIcon />
                </UserHeaderName>
              </UserHeaderRight>
            </Grid>
          </Grid>
        </UserHeader>

        <ClickAwayListener onClickAway={handleClickAway}>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <UserHeaderLogoBox to="/">
                {/* <UserHeaderLogo src={vimePayLogo} /> */}
              </UserHeaderLogoBox>
              <UserSideBarMenu>
                <IconButton
                  onClick={handleDrawerClose}
                  key={1}
                  style={{ color: "white" }}
                >
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </UserSideBarMenu>
            </div>
            <Divider />

            {user?.userType === "User" && (
              <Card left={"1em"}>
                <List>
                  {dashboardUpper.map((eachItem, pos) => (
                    <div
                      key={pos}
                      style={{
                        display:
                          eachItem?.privilege === "All"
                            ? "initial"
                            : user?.userType === eachItem?.privilege
                            ? "initial"
                            : "none",
                      }}
                    >
                      <div
                        key={eachItem.text}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem
                          button
                          onClick={() => handleDropDown(pos + 1)}
                        >
                          <ListItemText secondary="">
                            <Text size={"12px"}>{eachItem?.text} </Text>
                          </ListItemText>
                          {eachItem?.inner.length ? (
                            <>
                              {expand?.[pos + 1] ? (
                                <ExpandLess
                                  style={{ color: "rgba(5,5,5,0.8)" }}
                                />
                              ) : (
                                <ExpandMore
                                  style={{ color: "rgba(5,5,5,0.8)" }}
                                />
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </ListItem>

                        <Collapse
                          in={expand?.[pos + 1]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {eachItem?.inner.map((innerItem, key) => (
                              <>
                                {innerItem?.subGroup ? (
                                  <ListItem
                                    style={{
                                      marginTop: "-0.5em",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <ListItemIcon>
                                        {innerItem?.icon}
                                      </ListItemIcon>
                                      <Text
                                        size={"12px"}
                                        color={"rgba(5,5,5,0.7)"}
                                        style={{
                                          marginLeft: "-16px",
                                          width: "100%",
                                        }}
                                      >
                                        <>
                                          <div
                                            key={innerItem.text}
                                            style={{
                                              textDecoration: "none",
                                            }}
                                            onClick={() => {
                                              handleDropDown(innerItem.key);
                                            }}
                                          >
                                            <ListItem button>
                                              <ListItemText secondary="">
                                                <Text size={"12px"}>
                                                  {innerItem?.text}
                                                </Text>
                                              </ListItemText>
                                              {innerItem?.inner?.length ? (
                                                <>
                                                  {expand?.[innerItem.key] ? (
                                                    <ExpandLess
                                                      style={{
                                                        color:
                                                          "rgba(5,5,5,0.8)",
                                                      }}
                                                    />
                                                  ) : (
                                                    <ExpandMore
                                                      style={{
                                                        color:
                                                          "rgba(5,5,5,0.8)",
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </ListItem>
                                          </div>
                                        </>
                                      </Text>
                                    </div>
                                    <Collapse
                                      in={expand?.[innerItem.key]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List
                                        component="div"
                                        disablePadding
                                        style={{ marginLeft: "40px" }}
                                      >
                                        {innerItem?.inner.map(
                                          (subItem, key) => (
                                            <Link
                                              to={subItem.route}
                                              style={{
                                                textDecoration: "none",
                                              }}
                                            >
                                              <ListItem
                                                style={{
                                                  paddingLeft: "0px",
                                                }}
                                              >
                                                <Text
                                                  size={"12px"}
                                                  color={"rgba(5,5,5,0.7)"}
                                                >
                                                  {subItem.text}
                                                </Text>
                                              </ListItem>
                                            </Link>
                                          )
                                        )}
                                      </List>
                                    </Collapse>
                                  </ListItem>
                                ) : (
                                  <Link
                                    to={innerItem?.route}
                                    key={key}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <ListItem style={{ marginTop: "-0.5em" }}>
                                      <ListItemIcon>
                                        {innerItem?.icon}
                                      </ListItemIcon>
                                      <Text
                                        size={"12px"}
                                        color={"rgba(5,5,5,0.7)"}
                                      >
                                        {innerItem?.text}
                                      </Text>
                                    </ListItem>
                                  </Link>
                                )}
                              </>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    </div>
                  ))}
                </List>

                <Divider />
              </Card>
            )}

            {user?.userType === "Admin" && (
              <Card left={"1em"}>
                <List>
                  {dashboardAdmins.map((eachItem, pos) => (
                    <LinkText to={eachItem?.route} key={pos} nounderline>
                      <div
                        key={eachItem.text}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem style={{ marginTop: "1em" }}>
                          <ListItemIcon>
                            {/* #8E8EA1 */}
                            <Circle background={"#670150"} color={"white"}>
                              {eachItem?.icon}
                            </Circle>
                          </ListItemIcon>
                          <Text size={"14px"} color={"#8E8EA1"}>
                            {eachItem?.text}
                          </Text>
                        </ListItem>
                      </div>
                    </LinkText>
                  ))}
                </List>
              </Card>
            )}

            {user?.userType === "Manager" && (
              <Card left={"1em"}>
                <List>
                  {dashboardManager.map((eachItem, pos) => (
                    <LinkText to={eachItem?.route} key={pos} nounderline>
                      <div
                        key={eachItem.text}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem style={{ marginTop: "1em" }}>
                          <ListItemIcon>
                            {/* #8E8EA1 */}
                            <Circle background={"#670150"} color={"white"}>
                              {eachItem?.icon}
                            </Circle>
                          </ListItemIcon>
                          <Text size={"14px"} color={"#8E8EA1"}>
                            {eachItem?.text}
                          </Text>
                        </ListItem>
                      </div>
                    </LinkText>
                  ))}
                </List>
              </Card>
            )}
          </Drawer>
        </ClickAwayListener>

        <LayoutEnvironment>
          <LayoutContainer>
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={7}>
                <TitleText
                  fontFamily={"Poppins"}
                  // lineheight={"45px"}
                  top={"1em"}
                  // size={"16px"}
                  // color={"#464255"}
                  heavy
                >
                  {pageTitle}
                </TitleText>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={5}>
                {pageTitleComponent}
              </Grid>
            </Grid>

            {children}
          </LayoutContainer>
        </LayoutEnvironment>
      </div>

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        // keepMounted
        style={{ zIndex: "10000000", marginTop: "4em", marginLeft: "-1em" }}
        open={openPopUp}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <UserPopUpCard>
          <UserPopUpEach
            style={{
              color: "rgba(31, 40, 71, 1)",
              fontWeight: "bold",
              background: "white",
            }}
          >
            <UserPopUpText
              style={{
                color: "rgba(31, 40, 71, 1)",
                fontWeight: "bold",
              }}
            >{`${user?.firstName} -> ${user?.userType}`}</UserPopUpText>
            <UserPopUpIcon></UserPopUpIcon>
          </UserPopUpEach>
          <Link to="/user/settings/profile/" style={{ textDecoration: "none" }}>
            <UserPopUpEach>
              <UserPopUpText>Profile</UserPopUpText>
              <UserPopUpIcon>
                <PersonOutlineIcon />{" "}
              </UserPopUpIcon>
            </UserPopUpEach>
          </Link>
          <Link to="/user/account/view/" style={{ textDecoration: "none" }}>
            <UserPopUpEach>
              <UserPopUpText>My Account</UserPopUpText>
              <UserPopUpIcon>
                <SettingsIcon />{" "}
              </UserPopUpIcon>
            </UserPopUpEach>
          </Link>
          <UserPopUpEach onClick={handleLogout}>
            <UserPopUpText style={{ color: "#C03030" }} onClick={handleLogout}>
              Log out
            </UserPopUpText>
            <UserPopUpIcon style={{ color: "#C03030" }}>
              <ExitToAppIcon />{" "}
            </UserPopUpIcon>
          </UserPopUpEach>
        </UserPopUpCard>
      </Menu>

      {/* -- Import the Notifcication card here */}
      <UserNotes
        id={id2}
        popOpen2={popOpen2}
        anchorEl2={anchorEl2}
        appContext={appContext}
        handleClose2={handleClose2}
      />
    </>
  );
};

export default UserLayout;
