import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../App";
import {
  YourReferralEarningCard,
  YourReferralLinkCard,
} from "../../components/settings";
import UserLayout from "../../components/userLayout/UserLayout";
import { MainSection } from "../../styles/util/UtilStyles";

const ReferralPage = () => {
  const appContext = useContext(AppContext);
  const { user } = appContext;
  return (
    <>
      <UserLayout pageTitle={"Invite a friend"}>
        {/* --- Referral Cards section here ---- */}
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <YourReferralLinkCard user={user} />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <YourReferralEarningCard user={user} />
            </Grid>
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ReferralPage;
