import { makeStyles } from "@material-ui/core";

const drawerWidth = 220;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: "1000000",
    whiteSpace: "nowrap",
  },

  drawerOpen: {
    borderTop: "1px solid white",
    background: "white",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&::-webkit-scrollbar": {
      width: "1px",
      background: "yellow",
    },
  },
  drawerClose: {
    borderTop: "1px solid white",
    background: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(27) + 1,
    // width: theme.spacing(7) + 1,
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(23) + 1,
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(0) + 1,
    },
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "rgba(103, 1, 80, 1)",
    },
  },
  toolbar: {
    background: "rgba(103, 1, 80, 0.1)",
    display: "flex",
    alignItems: "center",
    // position: 'fixed',
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
