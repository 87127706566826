import { Grid } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getAUserInfoApi } from "../../adapters/user";
import { AppContext } from "../../App";
import {
  UserBasicDetails,
  UserPortfolio,
  UserProfilePic,
  UserTransactions,
} from "../../components/general";

import UserLayout from "../../components/userLayout/UserLayout";

import {
  Button,
  GoBack,
  MainSection,
  Card,
  TitleText,
  Text,
  SelectField,
  Option,
} from "../../styles/util/UtilStyles";

import { ActualBalance, WithdrawalBalance } from "../../components/wallet";

import { Box } from "../../styles/util/icon";
import { assignManagerToUser } from "../../adapters/admin";

const ViewUserProfilePage = () => {
  const appContext = useContext(AppContext);

  const { managersList } = appContext;

  const [userDetails, setUserDetails] = useState({
    banks: [],
    amountEarned: 0,
    amountInvested: 0,
    userType: null,
  });

  const history = useHistory();
  const [userId, setUserId] = useState(history.location.pathname.substr(14));

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setUserId(history.location.pathname.substr(14));
    getAUserInfoApi(userId, setUserDetails, setFetching, appContext);
    // eslint-disable-next-line
  }, [appContext]);

  const [loading, setLoading] = useState(false);
  const [selManager, setSelManager] = useState("");

  const updateManager = () => {
    assignManagerToUser(userDetails?.email, selManager, setLoading, appContext);
  };

  return (
    <>
      <UserLayout
        pageTitle={"User Profile"}
        pageTitleComponent={<GoBack url={`/admin/users/`} />}
      >
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <UserProfilePic picture={userDetails?.picture} />
            </Grid>

            <Grid item lg={8} md={8} sm={12} xs={12}>
              <UserBasicDetails
                fetching={fetching}
                appContext={appContext}
                setUserDetails={setUserDetails}
                userDetails={userDetails}
                userId={userId}
              />
            </Grid>

            {appContext?.user?.userType === "Admin" && !fetching && (
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Card radius={"10px"} background={"transparent"}>
                  <TitleText bottom={"16px"}>Update User's Manager</TitleText>
                  <Card>
                    <Card left="1em" top="1em" right="1em" bottom="1em">
                      <SelectField
                        onChange={(e) => setSelManager(e.target.value)}
                        defaultValue={
                          managersList.dict[userDetails?.managerID]?.email
                        }
                      >
                        {managersList.array.map((manager, pos) => (
                          <Option value={manager?.email} key={pos}>
                            Manager -{" "}
                            {manager?.firstName + " " + manager?.lastName} ({" "}
                            {manager?.email} )
                            {/* <p>Email - {manager?.email}</p> */}
                          </Option>
                        ))}
                      </SelectField>

                      <Button
                        type="submit"
                        background={"rgba(103, 1, 80, 0.1)"}
                        color={"#670150"}
                        disabled={loading}
                        onClick={updateManager}
                        width="12em"
                        top="1em"
                      >
                        {loading ? "Please wait..." : "Update"}
                      </Button>
                    </Card>
                  </Card>
                </Card>
              </Grid>
            )}

            {(appContext?.user?.userType === "Admin" ||
              appContext?.user?.userType === "Manager") && (
              <>
                {userDetails?.userType === "User" && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card radius={"10px"} background={"transparent"}>
                      <TitleText bottom={"16px"}>The User's Wallet</TitleText>

                      <Grid container spacing={4}>
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                          <ActualBalance appContext={{ user: userDetails }} />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                          <WithdrawalBalance
                            appContext={{ user: userDetails }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <Card height={"13em"} width={"100%"}>
                            <Card
                              top={"16px"}
                              bottom={"16px"}
                              right={"16px"}
                              left={"16px"}
                            >
                              <Box
                                background={"#670150"}
                                radius={"12px"}
                                center
                              />
                              <Text
                                color={"#2D3748"}
                                top={"16px"}
                                lineheight={"40px"}
                                size={"18px"}
                                center
                                heavy
                              >
                                Wallet Balance
                              </Text>
                              <Text size={"14px"} color={"#A0AEC0"} center>
                                Balance
                              </Text>
                              <Text
                                size={"16px"}
                                lineheight={"40px"}
                                center
                                heavy
                              >
                                N {userDetails?.wallet}
                              </Text>
                            </Card>
                          </Card>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                )}

                {userDetails?.userType === "User" && (
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Card radius={"10px"} background={"transparent"}>
                      <TitleText bottom={"16px"}>
                        The User's Bank Accounts
                      </TitleText>

                      <Card style={{ overflow: "scroll", maxHeight: "20em" }}>
                        {userDetails.banks.map((bank, pos) => (
                          <Card height={"auto"} width={"100%"} key={pos}>
                            <Card
                              top={"16px"}
                              bottom={"8px"}
                              right={"16px"}
                              left={"16px"}
                            >
                              <Text size={"16px"} lineheight={"32px"} heavy>
                                Account Details {pos + 1}
                              </Text>
                              <Text
                                color={"#A0AEC0"}
                                size={"14px"}
                                lineheight={"32px"}
                              >
                                Account Number: {bank?.accountNumber}
                              </Text>
                              <Text
                                color={"#A0AEC0"}
                                size={"14px"}
                                lineheight={"32px"}
                              >
                                Account Name : {bank?.accountName}
                              </Text>
                              <Text
                                color={"#A0AEC0"}
                                size={"14px"}
                                lineheight={"32px"}
                              >
                                Bank Name : {bank?.bankName}
                              </Text>
                            </Card>
                          </Card>
                        ))}
                      </Card>
                    </Card>
                  </Grid>
                )}
                {userDetails?.userType === "User" && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <UserTransactions
                      fetching={fetching}
                      appContext={appContext}
                      setUserDetails={setUserDetails}
                      userDetails={userDetails}
                      userId={userId}
                    />
                  </Grid>
                )}
                {userDetails?.userType === "User" && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <UserPortfolio
                      fetching={fetching}
                      appContext={appContext}
                      setUserDetails={setUserDetails}
                      userDetails={userDetails}
                      userId={userId}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewUserProfilePage;
