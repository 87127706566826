import { toast } from "react-toastify";

import { fetchApi } from "../utility/fetchApi";

import { baseUrl } from "../utility/Util";

export const getMyUsers = async (
  setLoading,
  setResults,
  appContext,
  setError,
  pageNo
) => {
  const { token } = appContext;
  setLoading(true);

  const APIUrl = `${baseUrl}/manager/users/?page=${pageNo}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setError(true);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      if (data?.data?.users?.length === 0) {
        setError(true);
      }
      setResults(data?.data?.users);
    }
  };

  const response = await getResponse();
  return response;
};

export const getMyUsersStat = async (
  setLoading,
  setResults,
  appContext,
  setError
) => {
  const { token } = appContext;
  setLoading(true);

  const APIUrl = `${baseUrl}/manager/usersCount/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setError(true);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      setResults(data?.data?.users);
    }
  };

  const response = await getResponse();
  return response;
};
