import { useContext, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { UsersCard } from "../../components/admin";
import UserLayout from "../../components/userLayout/UserLayout";
import {
  Button,
  Card,
  MainSection,
  Option,
  SelectField,
  Text,
} from "../../styles/util/UtilStyles";
import { getAllUsers } from "../../adapters/admin";
import { AppContext } from "../../App";
import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { NotFoundError } from "../error";

const usersPlaceholderList = [{}, {}, {}, {}];

const FilterUsers = ({ filter, handleFilter }) => {
  return (
    <>
      <Card width={"8em"} left={"auto"} background={"transparent"}>
        <Text bottom={"16px"} heavy>
          Filter
        </Text>
        <SelectField onChange={(e) => handleFilter(e.target.value)}>
          <Option value={"All"} default>
            All Users
          </Option>
          <Option value="Customer">All Customers</Option>
          <Option value={"CustomerWithFund"}>Customers with funds</Option>
          <Option value={"CustomerWithInvestment"}>
            Customers with investments
          </Option>
          <Option value={"Manager"}>Managers</Option>
          <Option value={"Admin"}>Admins</Option>
        </SelectField>
      </Card>
    </>
  );
};

const ViewAllUsersPage = () => {
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const appContext = useContext(AppContext);
  const [refresh, setRefresh] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    let newFilter = "&";
    if (filter !== "All") {
      newFilter = `&type=${filter}`;
    }
    console.log("new filer", filter);
    getAllUsers(setLoading, setUsersList, appContext, pageNo, newFilter);
  }, [appContext, refresh, pageNo, filter]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleFilter = (e) => {
    console.log(e);
    setFilter(e);
  };
  return (
    <>
      <UserLayout
        pageTitle={"View All Users"}
        pageTitleComponent={
          <FilterUsers filter={filter} handleFilter={handleFilter} />
        }
      >
        {!loading ? (
          <MainSection width={"100%"} top={"2em"}>
            {usersList.length ? (
              <Grid container spacing={4}>
                {usersList.map((item, pos) => (
                  <Grid key={pos} item lg={4} md={6} sm={6} xs={12}>
                    <UsersCard
                      {...item}
                      setRefresh={setRefresh}
                      appContext={appContext}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div>
                <NotFoundError errorTitle={"No users found."} />
              </div>
            )}
            <Card top={"64px"} bottom={"32px"} background={"transparent"}>
              <Grid container spacing={4}>
                <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
                <Grid item lg={4} md={6} sm={6} xs={6}>
                  <Button
                    width={"100%"}
                    height={"3.5em"}
                    size={"12px"}
                    background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                    border={pageNo > 1 ? "1px solid #670150" : "none"}
                    color={"white"}
                    radius={"12px"}
                    disabled={!pageNo}
                    onClick={previous}
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={6}>
                  <Button
                    width={"100%"}
                    height={"3.5em"}
                    size={"12px"}
                    background={usersList.length ? "white" : "rgba(5,5,5,0.3)"}
                    border={usersList.length ? "1px solid #670150" : "none"}
                    color={"#670150"}
                    radius={"12px"}
                    disabled={!usersList.length}
                    onClick={next}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </MainSection>
        ) : (
          <MainSection width={"100%"} top={"2em"}>
            <Grid container spacing={4}>
              {usersPlaceholderList.map((item, pos) => (
                <Grid key={pos} item lg={4} md={6} sm={6} xs={12}>
                  <SkeletonCard loading={loading} />
                </Grid>
              ))}
            </Grid>
          </MainSection>
        )}
      </UserLayout>
    </>
  );
};

export default ViewAllUsersPage;
