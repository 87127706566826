import React, { createContext, useState } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { PageTransition } from "./AppStyle.js";

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import AppRoutes from "./AppRoute.js";

export const AppContext = createContext({
  isLoggedIn: false,
  user: {},
  token: null,
  managersList: [],
  handleSetUser: (_) => {},
  handleSetIsLoggedIn: (_) => {},
  handleSetToken: (_) => {},
  handleSetManagers: (_) => {},
  clearData: () => {},
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("accessToken") !== null ? true : false
  );

  const [user, setUser] = useState(
    localStorage.getItem("user") !== null &&
    localStorage.getItem("user") !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : {}
  );

  const [managersList, setManagersList] = useState({
    dict: {},
    array: [],
  });

  const [token, setToken] = useState(
    localStorage.getItem("accessToken") !== null
      ? localStorage.getItem("accessToken")
      : null
  );

  const handleSetUser = (state) => {
    localStorage.setItem("user", JSON.stringify(state));
    setUser(state);
  };

  const handleSetIsLoggedIn = (state) => {
    setIsLoggedIn(state);
  };

  const handleSetToken = (state) => {
    localStorage.setItem("accessToken", state);
    setToken(state);
  };

  const handleSetManagers = (state) => {
    setManagersList(state);
  };
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        zIndex={10000000000000000000000}
      />

      <AppContext.Provider
        value={{
          token,
          handleSetToken,
          user,
          handleSetUser,
          isLoggedIn,
          handleSetIsLoggedIn,
          managersList,
          handleSetManagers,
        }}
      >
        <Router>
          <PageTransition>
            <AppRoutes />
          </PageTransition>
        </Router>
      </AppContext.Provider>
    </div>
  );
};

export default App;
