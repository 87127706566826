import { Grid } from "@material-ui/core";
import { MainSection } from "../../../../../styles/util/UtilStyles";
import AdminSharesImage from "../../common/AdminSharesImage";
import AdminShareOverview from "./AdminShareOverview";
import AdminViewBidders from "./AdminViewBidders/AdminViewBidders";

const AdminViewShareInfoBody = ({
  appContext,
  title,
  description,
  status,
  addedDate,
  amount,
  bids,
  interest,
  minimum,
  maxAmount,
  duration,
  amountRemaining,
  picture,
  _id,
}) => {
  return (
    <>
      <MainSection width={"100%"} top={"2em"}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <AdminSharesImage image={picture} />
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <AdminShareOverview
              appContext={appContext}
              {...{
                title,
                description,
                status,
                addedDate,
                amount,
                bids,
                interest,
                minimum,
                duration,
                amountRemaining,
                _id,
                maxAmount,
              }}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AdminViewBidders bids={bids} appContext={appContext} hasTitle />
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default AdminViewShareInfoBody;
