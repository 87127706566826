import { Checkbox, Grid } from "@material-ui/core";
import { Card, Text, TitleText } from "../../styles/util/UtilStyles";

const NotificationCard = () => {
  return (
    <Card radius={"10px"} style={{ display: "none" }}>
      <Card
        radius={"10px"}
        top={"16px"}
        bottom={"16px"}
        right={"16px"}
        left={"16px"}
      >
        <TitleText top={"16px"} bottom={"16px"}>
          Notifications
        </TitleText>
        <Text bottom={"16px"} color={"rgba(0, 0, 0, 0.4)"} size={"12px"}>
          Choose type of noticifications to receive
        </Text>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card flex>
              <Checkbox />
              <Text top={"12px"} color={"rgba(0, 0, 0, 0.4)"} size={"14px"}>
                Daily Report
              </Text>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card flex>
              <Checkbox />
              <Text top={"12px"} color={"rgba(0, 0, 0, 0.4)"} size={"14px"}>
                Weely Report
              </Text>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card flex>
              <Checkbox />
              <Text top={"12px"} color={"rgba(0, 0, 0, 0.4)"} size={"14px"}>
                Payment Success
              </Text>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card flex>
              <Checkbox />
              <Text top={"12px"} color={"rgba(0, 0, 0, 0.4)"} size={"14px"}>
                Withdrawal Success
              </Text>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card flex>
              <Checkbox />
              <Text top={"12px"} color={"rgba(0, 0, 0, 0.4)"} size={"14px"}>
                Password Change
              </Text>
            </Card>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card flex>
              <Checkbox />
              <Text top={"12px"} color={"rgba(0, 0, 0, 0.4)"} size={"14px"}>
                Newsletter
              </Text>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};

export default NotificationCard;
