import { useState } from "react";

import { Grid } from "@material-ui/core";
import { Button, Card, Text } from "../../styles/util/UtilStyles";
import WithdrawModal from "./WithdrawModal";
import DepositModal from "./DepositModal";

const AccountCTA = ({ appContext }) => {
  const [openWithdral, setOpenWithdral] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);

  const handleCloseWithdral = () => {
    setOpenWithdral(false);
  };
  const handleCloseDeposit = () => {
    setOpenDeposit(false);
  };

  return (
    <>
      <Card width={"100%"}>
        <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Text
                color={"#2D3748"}
                top={"16px"}
                fontFamily={"Helvetica"}
                lineheight={"20px"}
                size={"14px"}
                center
                heavy
              >
                Need some cash now?
              </Text>
              <Button
                onClick={() => setOpenWithdral(true)}
                top={"16px"}
                background={"#670150"}
                radius={"15px"}
              >
                Withdraw
              </Button>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Text
                color={"#2D3748"}
                top={"16px"}
                fontFamily={"Helvetica"}
                lineheight={"20px"}
                size={"14px"}
                center
                heavy
              >
                Fund Your Wallet Now
              </Text>
              <Button
                onClick={() => setOpenDeposit(true)}
                top={"16px"}
                background={"white"}
                border={"1px solid #670150"}
                color={"#670150"}
                radius={"15px"}
              >
                Deposit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Card>

      <WithdrawModal
        open={openWithdral}
        appContext={appContext}
        handleClose={handleCloseWithdral}
      />
      {/* <Deposit */}
      <DepositModal
        appContext={appContext}
        open={openDeposit}
        handleClose={handleCloseDeposit}
      />
    </>
  );
};

export default AccountCTA;
