import { Grid } from "@material-ui/core";
import SkeletonCard from "../../../../../adapters/utility/SkeletonCard";

const placeHolders = [{}, {}, {}, {}, {}, {}];
export const ViewSharesPreloader = () => {
  return (
    <>
      <Grid container spacing={4}>
        {placeHolders.map((p, key) => (
          <Grid item lg={4} md={4} sm={6} xs={12} key={key}>
            <SkeletonCard loading={true} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
