import { Grid } from "@material-ui/core";
import { Button, Card } from "../../../../../styles/util/UtilStyles";

const ViewSharesBodyNavButtons = ({ previous, next, pageNo, sharesList }) => {
  return (
    <>
      <Card top={"64px"} bottom={"32px"} background={"transparent"}>
        <Grid container spacing={4}>
          <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
          <Grid item lg={4} md={6} sm={6} xs={6}>
            <Button
              width={"100%"}
              height={"3.5em"}
              size={"12px"}
              background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
              border={pageNo > 1 ? "1px solid #670150" : "none"}
              color={"white"}
              radius={"12px"}
              disabled={!pageNo}
              onClick={previous}
            >
              Previous
            </Button>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={6}>
            <Button
              width={"100%"}
              height={"3.5em"}
              size={"12px"}
              background={sharesList.length ? "white" : "rgba(5,5,5,0.3)"}
              border={sharesList.length ? "1px solid #670150" : "none"}
              color={"#670150"}
              radius={"12px"}
              disabled={!sharesList.length}
              onClick={next}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ViewSharesBodyNavButtons;
