import { Grid } from "@material-ui/core";
import {
  Card,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import logo from "../../assets/img/home/VimeVest 2.png";
import { Box } from "../../styles/util/icon";

const Footer = () => {
  return (
    <>
      <footer>
        <MainSection bottom={"64px"} top={"32px"}>
          <Grid container spacing={4} justifyContent={"space-between"}>
            <Grid item lg={1} md={1} sm={12} xs={12}>
              <Card width={"50%"} left={"auto"} right={"auto"}>
                <Box
                  width={"fit-content"}
                  background={"transparent"}
                  top={"4em"}
                  bottom={"4em"}
                  center
                >
                  <img src={logo} alt="Logo" />
                </Box>
              </Card>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={6}>
              <Card width={"60%"} left={"auto"} right={"auto"}>
                <TitleText
                  bottom="1em"
                  color={"#120216"}
                  size={"18px"}
                  lineheight={"30px"}
                >
                  Learn More
                </TitleText>
                <LinkText
                  to="/home/"
                  color={"#670150"}
                  size={"14px"}
                  lineheight={"30px"}
                  nounderline
                >
                  Home
                </LinkText>
                <LinkText
                  to="/about-us/"
                  color={"#670150"}
                  size={"14px"}
                  lineheight={"30px"}
                  nounderline
                >
                  About Us
                </LinkText>
                <LinkText
                  to="/all-investments/"
                  color={"#670150"}
                  size={"14px"}
                  lineheight={"30px"}
                  nounderline
                >
                  Investments
                </LinkText>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Card width={"60%"} left={"auto"} right={"auto"}>
                <TitleText
                  bottom="1em"
                  color={"#120216"}
                  size={"18px"}
                  lineheight={"30px"}
                >
                  Legal
                </TitleText>
                {/* <Text color={"#120216"} size={"14px"} lineheight={"30px"}>
                  Privacy Policy
                </Text> */}
                <LinkText
                  to="/terms-privacy-policy/"
                  color={"#670150"}
                  size={"14px"}
                  lineheight={"30px"}
                  nounderline
                >
                  Terms & Conditions
                </LinkText>
              </Card>
            </Grid>

            <Grid item lg={2} md={2} sm={6} xs={6}>
              <Card width={"60%"} left={"auto"} right={"auto"}>
                <TitleText
                  bottom="1em"
                  color={"#120216"}
                  size={"18px"}
                  lineheight={"30px"}
                >
                  Support
                </TitleText>
                <LinkText
                  to="/faqs/"
                  color={"#670150"}
                  size={"14px"}
                  lineheight={"30px"}
                  nounderline
                >
                  FAQs
                </LinkText>
                <LinkText
                  to="/contact-us/"
                  color={"#670150"}
                  size={"14px"}
                  lineheight={"30px"}
                  nounderline
                >
                  Contact Us
                </LinkText>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Card width={"60%"} left={"auto"} right={"auto"}>
                <TitleText
                  color={"#120216"}
                  bottom="1em"
                  size={"18px"}
                  lineheight={"30px"}
                >
                  Social
                </TitleText>
                <a
                  href="https://instagram.com/"
                  target="__blank"
                  style={{ textDecoration: "none" }}
                >
                  <Text
                    color={"#670150"}
                    lineheight={"30px"}
                    nounderline
                    size={"14px"}
                  >
                    LinkedIn
                  </Text>
                </a>
                <a
                  href="mailto:vimevest@gmail.com"
                  target="__blank"
                  style={{ textDecoration: "none" }}
                >
                  <Text
                    color={"#670150"}
                    lineheight={"30px"}
                    nounderline
                    size={"14px"}
                  >
                    vimevest@gmail.com
                  </Text>
                </a>
                <Text
                  color={"#670150"}
                  lineheight={"30px"}
                  nounderline
                  size={"14px"}
                >
                  (+234)(0) 812-2450-145
                </Text>
              </Card>
            </Grid>
          </Grid>
        </MainSection>
      </footer>
    </>
  );
};

export default Footer;
