import { Divider, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { Box } from "../../styles/util/icon";
import { Button, Card, Text, TitleText } from "../../styles/util/UtilStyles";
import { getFormattedAmount, getFormattedFutureDate, getPercentageTime } from "../../Utils";

const InvestedPlan = ({
  amountInvested,
  amount,
  duration,
  interest,
  picture,
  status,
  title,
  startDate,
  investmentID,
}) => {
  const history = useHistory();

  return (
    <>
      <Card>
        <Card left={"16px"} top={"16px"} right={"16px"} bottom={"16px"}>
          <TitleText
            fontFamily={"Poppins"}
            size={"14px"}
            color={"#000000"}
            lineheight={"20px"}
            bottom={"20px"}
            heavy
          >
            Your Invested Plan
          </TitleText>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Card flex>
                <Box>
                  <img
                    src={picture}
                    alt={title}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Box>
                <Text
                  fontFamily={"Poppins"}
                  color={"#000000"}
                  size={"20px"}
                  left={"8px"}
                  lineheight={"30px"}
                >
                  {title}
                </Text>
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Text
                fontFamily={"Poppins"}
                color={"rgba(0,0,0,0.9)"}
                size={"12px"}
                heavy
                lineheight={"20px"}
              >
                You can’t withdraw funds until maturity date which is
                {" " + getFormattedFutureDate(startDate, duration)}
              </Text>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card bottom={"16px"} flex spaceBetween>
                <Text fontFamily={"Poppins"}>
                  {getPercentageTime(startDate, duration)}%
                </Text>
                <Text fontFamily={"Poppins"}>
                  <Button
                    radius={"5px"}
                    background={"white"}
                    color={"#670150"}
                    width={"8em"}
                    disabled={true}
                    border={"1px solid #670150"}
                  >
                    {status}
                  </Button>
                </Text>
              </Card>
              <Divider />
              <Text fontFamily={"Poppins"}>Investment status bar</Text>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card top={"16px"} bottom={"8px"} flex spaceBetween>
              <Text
                fontFamily={"Poppins"}
                color={"rgba(0, 0, 0, 0.4)"}
                size={"16px"}
                lineheight={"27px"}
              >
                Invested
              </Text>
              <Text
                color={"#393C47"}
                fontFamily={"Poppins"}
                size={"16px"}
                lineheight={"27px"}
                heavy
              >
                N{getFormattedAmount(amountInvested)}
              </Text>
            </Card>
            <Divider />
            <Card top={"8px"} flex spaceBetween>
              <Text
                fontFamily={"Poppins"}
                color={"rgba(0, 0, 0, 0.4)"}
                size={"16px"}
                lineheight={"27px"}
              >
                Interest
              </Text>
              <Text
                color={"#393C47"}
                fontFamily={"Poppins"}
                size={"16px"}
                lineheight={"27px"}
                heavy
              >
                {interest}%
              </Text>
            </Card>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card top={"32px"} flex spaceBetween>
              {/* <Button
              radius={"5px"}
              background={"#670150"}
              color={"white"}
              width={"8em"}
            >
              Invest Now
            </Button> */}
              <Button
                radius={"5px"}
                background={"#670150"}
                color={"white"}
                width={"8em"}
                left={"auto"}
                onClick={() => history.push(`/investment/${investmentID}`)}
              >
                More Info
              </Button>
            </Card>
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default InvestedPlan;
