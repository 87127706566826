import { Grid } from "@material-ui/core";

import { SharesCard } from "../../common/SharesCard";

export const ViewMyShares = ({ sharesList = [], filter }) => {
  return (
    <>
      <Grid container spacing={4}>
        {sharesList.map((share, key) => (
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <SharesCard
              {...share}
              key={key}
              hasUnbidButton={filter === "Pending"}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
