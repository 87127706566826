import { Avatar } from "@material-ui/core";
import Carousel from "react-grid-carousel";
import { Box } from "../../styles/util/icon";

import {
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const testimonials = [
  {
    title: "NO REGRETS WITH VIMEVEST",
    desc: "Vimevest is a very good and reliable platform. I've invested on the platform for more than 3 years and there have been no regrets.",
    name: "Miss clara Iloh",
    job: "",
  },
  {
    title: "A PLATFORM WORTHY OF REFERRAL ",
    desc: "It takes trust to refer! Vimevest has never failed me and as a result, I have been referring my family and friends to the platform. More than 2 years on a spin and the platform has proven to be trustworthy.",
    name: "Rita Adesina",
    job: "",
  },
  {
    title: "CONSISTENT QUALITY SERVICE",
    desc: "I am only two years on this platform and the level of service rendered has been top notch. Vimevest has made investing super easy. The quality of service catches the eye and mind all-together. Long long shall this brand identity reign.  ",
    name: "Charity oduche",
    job: "",
  },
];

const TestimonialsCard = ({ title, desc, name, job }) => {
  return (
    <>
      <Card top={"2em"} radius={"8px"} height="15em">
        <Card left={"16px"} top={"16px"} right={"16px"} bottom={"16px"}>
          <TitleText
            color={"#18191F"}
            size={"24px"}
            lineheight={"32px"}
            fontFamily={"Inter"}
            bottom="1em"
            heavy
          >
            {title}
          </TitleText>
          <Text
            color={"#18191F"}
            fontFamily={"Mulish"}
            size={"14px"}
            lineheight={"24px"}
          >
            {desc}
          </Text>
        </Card>
      </Card>

      <Box top={"16px"} background={"transparent"} center>
        <Avatar />
      </Box>

      <Text
        color={"#18191F"}
        fontFamily={"Inter"}
        size={"18px"}
        lineheight={"28px"}
        center
        heavy
      >
        {name}
      </Text>
      <Text
        color={"#474A57"}
        fontFamily={"Inter"}
        size={"14px"}
        lineheight={"24px"}
        center
      >
        {job}
      </Text>
    </>
  );
};

const OurClientSpeaks = () => {
  return (
    <>
      <MainSection top={"32px"} bottom={"64px"}>
        <TitleText
          color={"#18191F"}
          fontFamily={"Mulish"}
          lineheight={"64px"}
          center
          heavy
        >
          Our Clients Speak
        </TitleText>
        <Text color={"#18191F"} size={"18px"} lineheight={"32px"} center>
          We have been working with clients around the world
        </Text>

        <Carousel cols={3} rows={1} gap={15} loop autoplay={1000} showDots>
          {testimonials.map((item, pos) => (
            <Carousel.Item key={pos}>
              <TestimonialsCard {...item} />
            </Carousel.Item>
          ))}
        </Carousel>
      </MainSection>
    </>
  );
};

export default OurClientSpeaks;
