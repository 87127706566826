import { useState } from "react";

import { Grid, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

import {
  Button,
  Card,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import { setNewPassAcctApi } from "../../adapters/user";

import { useHistory } from "react-router-dom";

const RecoverPassword = ({ code, email }) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.email = email;
    data.code = code;
    console.log("data", data);

    setNewPassAcctApi(data, setLoading, history);
  };

  return (
    <>
      <MainSection>
        <Card flexEnd>
          <LinkText
            to="/auth/register/"
            color={"rgba(21, 20, 57, 0.4)"}
            position={"right"}
            lineheight={"64px"}
            nounderline={"true"}
          >
            New here? <b style={{ color: "#F2994A" }}>Sign Up</b>
          </LinkText>
        </Card>
        <Card bottom={"16px"}>
          <TitleText
            top={"3em"}
            color={"#670150"}
            lineheight={"52px"}
            size={"32px"}
            heavy
          >
            Generate New Password
          </TitleText>
        </Card>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card top={"8px"} bottom={"8px"}>
                <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
                  ENTER NEW PASSWORD
                </Text>
                <TextField
                  placeholder="Enter a new password"
                  variant="outlined"
                  type="password"
                  {...register("newPassword")}
                  required
                  fullWidth
                />
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card top={"8px"} bottom={"8px"}>
                <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
                  CONFIRM NEW PASSWORD
                </Text>
                <TextField
                  placeholder="Confirm your new password"
                  variant="outlined"
                  type="password"
                  {...register("confirmPassword")}
                  required
                  fullWidth
                />
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card>
                <Button
                  type="submit"
                  background={"#670150"}
                  //   radius={"100px"}
                  height={"4em"}
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Recover Account"}
                </Button>
              </Card>
            </Grid>
          </Grid>
        </form>
      </MainSection>
    </>
  );
};

export default RecoverPassword;
