import { Grid } from "@material-ui/core";

import { AdminSharesCard } from "../../common/AdminSharesCard";

export const AdminViewShares = ({ sharesList = [], handleDeleteModal }) => {
  return (
    <>
      <Grid container spacing={4}>
        {sharesList.map((share, key) => (
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <AdminSharesCard
              {...share}
              key={key}
              handleDeleteModal={handleDeleteModal}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
