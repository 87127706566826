import styled from "styled-components";

import { Link } from "react-router-dom";

export const LayoutHeader = styled.header`
  height: 5em;
  width: 100%;
  // background: #EAEAEE;
  background: white;
  box-shadow: 0px 2.50326px 10.013px rgba(134, 140, 144, 0.05);
  position: fixed;
  z-index: 1000;
  top: 0;
  background: ${(props) => (props.notWhiteBg ? "#EAEAEE" : "white")};
  @media screen and (max-width: 769px) {
    height: 4.5em;
  }
`;

export const LayoutHeaderInner = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5em;
`;

export const LayoutTitle = styled.h5`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2em;
  // line-height: 30px;
  letter-spacing: 0.3px;
  margin-top: 0.5em;
  float: left;
  color: #121212;
  z-index: 100000000000000000000;
  // margin-left:13.5em;
  margin-left: 34%;
  @media screen and (max-width: 1025px) {
    margin-left: 0em;
  }
`;

export const LayoutIconsBox = styled.div`
  // float:right;
  width: 5em;
  background: red;
  justify-content: space-between;
  display: flex;
`;

export const LayoutIcons = styled.span`
  color: white;
`;

//

export const UserHeader = styled.header`
  height: 5em;
  width: 100%;
  background: rgb(247, 248, 252);
  box-shadow: 0px 2.50326px 10.013px rgba(134, 140, 144, 0.05);
  position: fixed;
  z-index: 1000;
  top: 0;
  @media screen and (max-width: 769px) {
    height: 4.5em;
  }
`;

export const UserHeaderLogo = styled.img`
  width: 6em;
  margin-top: 0em;
`;

export const UserSideBar = styled.section`
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 100000;
`;

export const UserSideBarMenu = styled.div`
  display: none;
  @media screen and (max-width: 992px) {
    display: initial;
  }
`;

export const UserSideBarMenuIcon = styled.div`
  margin-left: 1em;
  margin-top: 0.5em;
  // background:red;
  display: flex;
`;

export const UserHeaderLogoBox = styled(Link)`
  width: 100%;
  background: white;
  box-shadow: 0px 2px 12px rgba(134, 140, 144, 0.05);
  text-align: center;
  // overflow:hidden;
`;

export const UserSideBarInner = styled.section`
  height: 65vh;
  // width: 90%;
  // background:red;
  margin-left: 1.5em;
  margin-right: 0em;
  margin-top: 1em;
  overflow-y: scroll;
  @media screen and (max-width: 769px) {
    height: 60vh;
  }
`;

export const UserSideBarItem = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.44px;
  display: flex;
  margin-top: 0.5em;
  line-height: 2em;
`;

export const UserSideBarItemIcon = styled.div`
  color: rgba(5, 5, 5, 1);
`;

export const UserSideBarItemText = styled.p`
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-size: 0.9em;
  margin-left: 0em;
  color: rgba(255, 255, 255, 0.8);
  font-weight: normal;
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

export const UserSideBarItemSmallText = styled.p`
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-size: 0.8em;
  margin-left: 0em;
  color: rgba(42, 159, 238, 1);
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const UserHeaderInner = styled.div`
  // background:red;
  width: 90%;
  margin-left: 5%;
  @media screen and (max-width: 769px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const UserHeaderMiddle = styled.section`
  width: 34%;
  @media screen and (max-width: 769px) {
    display: none;
  }
`;

export const UserDarkSwitch = styled.div`
  height: fit-content;
  text-align: right;
  margin-right: 1em;
  display: flex;
  @media screen and (max-width: 769px) {
    display: none;
  }
`;

export const UserLogo = styled(Link)`
  display: none;
  text-align: center;
  margin-top: 0.5em;
  @media screen and (max-width: 769px) {
    display: block;
  }
`;

export const UserHeaderRight = styled.section`
  // width: 90%;
  height: 3em;
  margin-top: 1em;
  border-left: 1px solid #cacaca;
  display: flex;
  // justify-content: space-between;
  align-items: flex-center;
  margin-right: 7.5%;
  float: right;
  justify-content: flex-end;
  @media screen and (max-width: 769px) {
    // display: none;
    float: right;
    margin-right: 1em;
    border-left: none;
  }
`;

export const UserHeaderName = styled.h3`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 19px;
  color: #0f2b3d;
  mix-blend-mode: normal;
  margin-top: 0.3em;
  @media screen and (max-width: 769px) {
    font-size: 0.9em;
    margin-top: 0.5em;
    display: none;
  }
`;

export const UserHeaderNote = styled.div`
  margin-left: 1em;
  margin-top: 0.5em;
  color: rgba(174, 174, 174, 0.6);
  box-sizing: border-box;
`;

export const UserHeaderAvatar = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  width: 2.5em;
  height: 3.5em;
  cursor: pointer;
`;
// --- Header profile pop over ---
export const UserPopUpCard = styled.section`
  width: 197px;
  height: fit-content;
  margin-top: 1em;
  background: white;
  border: none;
  outline: none;
  // z-index: 10000000000000000000000;
`;

export const UserPopUpEach = styled.div`
  background: white;
  border-radius: 4px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 4em;
  display: flex;
  justify-content: space-between;
  color: rgba(5, 5, 5, 1);
  cursor: pointer;
  border: 1px solid rgba(42, 159, 238, 0.2);
  &:hover {
    color: #393939;
    background: #fafafa;
  }
`;

export const UserPopUpText = styled.span`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 14px;
  margin-top: 1em;
  letter-spacing: -0.44px;
`;

export const UserPopUpIcon = styled.span`
  margin-top: 0.5em;
`;

export const UserNoteCard = styled.div`
  background: #fdfefd;
  border-radius: 7px;
  // padding: 1em 1em;
  width: 21em;
  height: 20em;
  border: none;
  outline: none;
  overflow-y: scroll;
`;

export const UserNoteCardHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 1em;
  margin-right: 1em;
  height: 4em;
  position: fixed;
  width: 18em;
  top: 5.5em;
  z-index: 10000;
  background: white;
`;

export const UserNoteLeftTitle = styled.h3`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1em;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #393939;
`;

export const UserNoteRightTitle = styled.h3`
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #20bbfc;
`;

export const UserNoteBody = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0em;
`;

export const LayoutEnvironment = styled.main`
  background: rgb(247, 248, 252);
  position: absolute;
  width: 100%;
`;

export const LayoutContainer = styled.div`
  margin-top: 7em;
  height: fit-content;
  // width: 85%;
  margin-left: 20%;
  width: 77%;
  z-index: -100000;
  min-height: 100vh;
  @media screen and (max-width: 992px) {
    //   margin-left:5em;
    // margin-right:1em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
  }
  @media screen and (max-width: 769px) {
    //   margin-left:5em;
    // margin-right:1em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8em;
  }
`;
