import { Grid } from "@material-ui/core";
import SkeletonCard from "../../../../../adapters/utility/SkeletonCard";

const ViewASharePlanLoader = () => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <SkeletonCard loading={true} />
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <SkeletonCard loading={true} />
          <SkeletonCard loading={true} />
        </Grid>
      </Grid>
    </>
  );
};

export default ViewASharePlanLoader;
