import { useContext, useEffect, useState } from "react";

import { DashboardCard, UsersCard } from "../../components/admin";

import UserLayout from "../../components/userLayout/UserLayout";
import { Button, MainSection, TitleText } from "../../styles/util/UtilStyles";

import { Grid } from "@material-ui/core";
import { getMyUsers, getMyUsersStat } from "../../adapters/manager";
import { AppContext } from "../../App";
import { NotFoundError } from "../error";
import SkeletonCard from "../../adapters/utility/SkeletonCard";
import {
  AirplanemodeActiveOutlined,
  AirplanemodeInactiveOutlined,
  GroupOutlined,
  MonetizationOnOutlined,
} from "@material-ui/icons";
import { useHistory } from "react-router";

const usersPlaceholderList = [{}, {}, {}, {}];

const ManagerDashPage = () => {
  const [userStat, setUserStat] = useState({});
  const [loading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [adminStat, setAdminStat] = useState([]);

  const appContext = useContext(AppContext);

  useEffect(() => {
    getMyUsersStat(setLoading, setUserStat, appContext, setError);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAdminStat([
      {
        icon: <GroupOutlined />,
        title: "My Users",
        count: userStat?.total,
        color: "white",
        bgColor: "#670150",
      },
      {
        icon: <AirplanemodeActiveOutlined />,
        title: "Active Users",
        count: userStat?.active,
        color: "white",
        bgColor: "#FFB648",
      },
      {
        icon: <AirplanemodeInactiveOutlined />,
        title: "Inactive Users",
        count: userStat?.suspended,
        color: "white",
        bgColor: "#5F2EEA",
      },
      {
        icon: <MonetizationOnOutlined />,
        title: "Total Transactions.",
        count: "0",
        color: "white",
        bgColor: "#4BDE97",
      },
    ]);
    // eslint-disable-next-line
  }, [userStat]);

  const [usersList, setUsersList] = useState([]);
  useEffect(() => {
    getMyUsers(setLoading, setUsersList, appContext, setError, 1);
    // eslint-disable-next-line
  }, [appContext]);

  const history = useHistory();

  return (
    <>
      <UserLayout pageTitle={"Manager Dashboard"}>
        {!loading ? (
          <>
            {isError ? (
              <NotFoundError errorTitle={"An error occurred."} />
            ) : (
              <MainSection width={"100%"} top={"2em"}>
                <Grid container spacing={4}>
                  {adminStat.map((item, pos) => (
                    <Grid key={pos} item lg={3} md={3} sm={6} xs={6}>
                      <DashboardCard {...item} />
                    </Grid>
                  ))}

                  <Grid key={"stat"} item lg={12} md={12} sm={12} xs={12}>
                    {/* --- Bring In My Users Here */}
                    <TitleText
                      color={"#171717"}
                      top={"16px"}
                      bottom={"16px"}
                      size={"16px"}
                      heavy
                    >
                      My Managed Users
                    </TitleText>
                    <Grid container spacing={4}>
                      {usersList.map((item, pos) => (
                        <Grid key={pos} item lg={4} md={6} sm={6} xs={12}>
                          <UsersCard
                            {...item}
                            // setRefresh={setRefresh}
                            appContext={appContext}
                            managedByMe={true}
                          />
                        </Grid>
                      ))}
                    </Grid>

                    <Button
                      width={"20em"}
                      height={"4em"}
                      size={"10px"}
                      background={"white"}
                      border={"1px solid #670150"}
                      color={"#670150"}
                      radius={"12px"}
                      bottom={"32px"}
                      left={"auto"}
                      right={"1em"}
                      top={"32px"}
                      onClick={() => history.push("/manager/view-my-users/")}
                    >
                      View All
                    </Button>
                  </Grid>
                </Grid>
              </MainSection>
            )}
          </>
        ) : (
          <MainSection width={"100%"} top={"2em"}>
            <Grid container spacing={4}>
              {usersPlaceholderList.map((item, pos) => (
                <Grid key={pos} item lg={4} md={6} sm={6} xs={12}>
                  <SkeletonCard loading={loading} />
                </Grid>
              ))}
            </Grid>
          </MainSection>
        )}
      </UserLayout>
    </>
  );
};

export default ManagerDashPage;
