import {
  Button,
  Card,
  MainSection,
  TextField,
  TitleText,
} from "../../styles/util/UtilStyles";

import bgImage from "../../assets/img/home/BG Mask.png";
import envelope from "../../assets/img/home/Illustration.png";

import { Grid } from "@material-ui/core";
import { Box } from "../../styles/util/icon";

const Subscribers = () => {
  return (
    <>
      <MainSection bottom={"64px"} top={"32px"}>
        <Card
          height={"100%"}
          style={{ backgroundSize: "cover" }}
          radius={"8px"}
          width={"100%"}
          background={`url(${bgImage})`}
        >
          <Card top={"32px"} bottom={"32px"} background={"transparent"}>
            <Grid container spacing={4}>
              <Grid item lg={6} md={4} sm={4} xs={12}>
                <Box
                  width={"fit-content"}
                  background={"transparent"}
                  top={"4em"}
                  center
                >
                  <img
                    src={envelope}
                    alt="Email"
                    style={{
                      width: "7em",
                      height: "7em",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={8} sm={8} xs={12}>
                <Card width={"80%"} background={"transparent"} WidthResponsive>
                  <TitleText
                    color={"white"}
                    size={"32px"}
                    fontFamily={"Mulish"}
                    top={"16px"}
                    // lineheight={"54px"}
                  >
                    Join 569 more people in the waitlist
                  </TitleText>
                  <Card top={"16px"} background={"transparent"} flex>
                    <TextField placeholder="Enter your email here..." />
                    <Button
                      height={"3.5em"}
                      width={"10em"}
                      background={"#F2911B"}
                    >
                      Join the waitlist
                    </Button>
                  </Card>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Card>
      </MainSection>
    </>
  );
};

export default Subscribers;
