import { useContext, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { getAllInvestments } from "../../adapters/investments";
import { InvestPlanCard } from "../../components/investments";
import UserLayout from "../../components/userLayout/UserLayout";
import {
  Button,
  Card,
  MainSection,
  Option,
  SelectField,
  Text,
} from "../../styles/util/UtilStyles";
import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { AppContext } from "../../App";
import { useHistory } from "react-router";
import { NotFoundError } from "../error";

const CreateInvestPlan = () => {
  const history = useHistory();
  return (
    <>
      <Button
        background={"#670150"}
        width={"10em"}
        radius={"5px"}
        left={"auto"}
        onClick={() => history.push("/admin/investments/create/")}
      >
        <Card background={"transparent"} left={"auto"} right={"auto"} flex>
          <Add />
          <Text left={"4px"} top={"5px"} color={"white"} size={"12px"}>
            Add New
          </Text>
        </Card>
      </Button>
    </>
  );
};

const FilterOptions = ({ setFilter, filter }) => {
  return (
    <>
      <Card width={"8em"} left={"auto"} background={"transparent"}>
        <Text bottom={"16px"} heavy>
          Filter
        </Text>
        <SelectField
          defaultValue={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Option>Completed</Option>
          <Option>Pending</Option>
          <Option>In Progress</Option>
          <Option>Cancelled</Option>
          <Option>All</Option>
        </SelectField>
      </Card>
    </>
  );
};

const placeholderList = [{}, {}, {}, {}];

const AdminInvestPlansPage = () => {
  const [plansList, setPlansList] = useState([]);
  const [loading, setLoading] = useState(true);
  const appContext = useContext(AppContext);
  const [pageNo, setPageNo] = useState(1);
  const [filter, setFilter] = useState("All");

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    let newFilter = "&";
    if (filter === "All") {
      newFilter = "&";
    } else {
      newFilter = `&type=${filter}`;
    }

    getAllInvestments(setLoading, setPlansList, appContext, pageNo, newFilter);
  }, [appContext, refresh, pageNo, filter]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"Investment Plans"}
        pageTitleComponent={<CreateInvestPlan />}
      >
        <FilterOptions setFilter={setFilter} filter={filter} />
        {!loading ? (
          <MainSection width={"100%"} top={"2em"}>
            {plansList.length ? (
              <Grid container spacing={4}>
                {plansList.map((item, pos) => (
                  <Grid key={pos} item lg={4} md={6} sm={6} xs={12}>
                    <InvestPlanCard
                      {...item}
                      appContext={appContext}
                      setRefresh={setRefresh}
                      isAdmin
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div>
                <NotFoundError errorTitle={"No results found."} />
              </div>
            )}

            <Card top={"64px"} bottom={"32px"} background={"transparent"}>
              <Grid container spacing={4}>
                <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
                <Grid item lg={4} md={6} sm={6} xs={6}>
                  <Button
                    width={"100%"}
                    height={"3.5em"}
                    size={"12px"}
                    background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                    border={pageNo > 1 ? "1px solid #670150" : "none"}
                    color={"white"}
                    radius={"12px"}
                    disabled={!pageNo}
                    onClick={previous}
                  >
                    Previous
                  </Button>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={6}>
                  <Button
                    width={"100%"}
                    height={"3.5em"}
                    size={"12px"}
                    background={plansList.length ? "white" : "rgba(5,5,5,0.3)"}
                    border={plansList.length ? "1px solid #670150" : "none"}
                    color={"#670150"}
                    radius={"12px"}
                    disabled={!plansList.length}
                    onClick={next}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </MainSection>
        ) : (
          <MainSection width={"100%"} top={"2em"}>
            <Grid container spacing={4}>
              {placeholderList.map((item, pos) => (
                <Grid key={pos} item lg={4} md={6} sm={6} xs={12}>
                  <SkeletonCard loading={loading} />
                </Grid>
              ))}
            </Grid>
          </MainSection>
        )}
      </UserLayout>
    </>
  );
};

export default AdminInvestPlansPage;
