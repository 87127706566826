import styled from "styled-components";

import logo from "../../assets/img/util/VimeVest.png";

// --- Icons here ---
export const MenuIcon = styled.div`
  width: 2em;
  height: 2em;
  background: #751da5;
  border-radius: 0.5em;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  color: white;
`;

export const Circle = styled("div")`
  height: ${(props) => (props.height ? `${props.height}` : "40px")};
  width: ${(props) => (props.height ? `${props.height}` : "40px")};
  margin-right: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  background: ${(props) =>
    props.background ? `${props.background}` : "rgba(31, 40, 71, 0.8)"};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  // transition: 0.5s zoom;
  &:hover {
    // zoom: 1.2;
  }
`;

export const Box = styled("div")`
  height: ${(props) => (props.height ? `${props.height}` : "3em")};
  width: ${(props) => (props.width ? `${props.width}` : "3em")};
  background: ${(props) =>
    props.background ? `${props.background}` : "rgba(42, 159, 238, 1)"};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  margin-left: ${(props) => props.center && "auto"};
  margin-right: ${(props) => props.center && "auto"};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0em")};
  cursor: ${(props) => props.cursor && "pointer"};

  border-radius: ${(props) => (props.radius ? `${props.radius}` : "0px")};

  display: flex;
  align-items: center;
  align-self: center;
`;

export const VimeVestIcon = styled.img.attrs({
  src: logo,
})`
  width: ${(props) => (props.width ? `${props.width}` : "40px")};
  height: ${(props) => (props.height ? `${props.height}` : "40px")};
`;
