import { Grid } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { getMyInvestments } from "../../../adapters/investments";

import SkeletonCard from "../../../adapters/utility/SkeletonCard";
import { AppContext } from "../../../App";
import { InvestmentCard } from "../../../components/investments";
import UserLayout from "../../../components/userLayout/UserLayout";

import {
  Button,
  Card,
  GoBack,
  MainSection,
  Option,
  SelectField,
  Text,
} from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

// const placeHolders = [{}, {}, {}, {}, {}, {}];

const FilterOptions = ({ setFilter, filter }) => {
  return (
    <>
      <Card width={"8em"} left={"auto"} background={"transparent"}>
        <Text bottom={"16px"} heavy>
          Filter
        </Text>
        <SelectField
          defaultValue={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Option>Completed</Option>
          <Option>Pending</Option>
          <Option>In Progress</Option>
          <Option>Cancelled</Option>
          <Option>All</Option>
        </SelectField>
      </Card>
    </>
  );
};

const ViewMyPortfolioPage = () => {
  const appContext = useContext(AppContext);
  const history = useHistory();

  const [plansList, setPlansList] = useState([{}, {}, {}, {}, {}, {}]);
  const [filter, setFilter] = useState("All");
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [allPlans, setAllPlans] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [title, setTitle] = useState("My Portfolio");
  const [enableFilter, setEnableFilter] = useState(false);

  useEffect(() => {
    let statusParsed = history.location.pathname.substring(35);

    let status = null;

    if (statusParsed === "completed") {
      status = "Completed";
      setTitle("My Completed Investments");
    } else if (statusParsed === "cancelled") {
      status = "Cancelled";
      setTitle("My Cancelled Investments");
    } else {
      status = "In Progress, Pending";
      setTitle("My Portfolio");
      setEnableFilter(false);
    }

    getMyInvestments(
      setLoadingPlans,
      setPlansList,
      appContext,
      pageNo,
      setAllPlans,
      status
    );
    // eslint-disable-next-line
  }, [pageNo]);

  const next = () => {
    setPageNo(pageNo + 1);
  };

  const previous = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  useEffect(() => {
    if (allPlans !== null) {
      setPlansList(allPlans[filter]);
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <UserLayout
        pageTitle={title}
        pageTitleComponent={<GoBack url={"/user/market/investments/"} />}
      >
        {enableFilter && (
          <FilterOptions setFilter={setFilter} filter={filter} />
        )}

        <MainSection width={"100%"} top={"2em"}>
          {plansList.length ? (
            <Grid container spacing={4}>
              {plansList.map((item, pos) => (
                <Grid item lg={4} md={4} sm={6} xs={12} key={pos}>
                  {loadingPlans ? (
                    <SkeletonCard loading={loadingPlans} />
                  ) : (
                    <InvestmentCard
                      appContext={appContext}
                      {...item}
                      userState={filter}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <div>
              <NotFoundError errorTitle={"No results found."} />
            </div>
          )}

          <Card top={"64px"} bottom={"32px"} background={"transparent"}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={pageNo > 1 ? "#670150" : "rgba(5,5,5,0.3)"}
                  border={pageNo > 1 ? "1px solid #670150" : "none"}
                  color={"white"}
                  radius={"12px"}
                  disabled={!pageNo}
                  onClick={previous}
                >
                  Previous
                </Button>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  width={"100%"}
                  height={"3.5em"}
                  size={"12px"}
                  background={plansList.length ? "white" : "rgba(5,5,5,0.3)"}
                  border={plansList.length ? "1px solid #670150" : "none"}
                  color={"#670150"}
                  radius={"12px"}
                  disabled={!plansList.length}
                  onClick={next}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Card>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewMyPortfolioPage;
