import { useState } from "react";

import { Grid } from "@material-ui/core";

import { MainSection } from "../../../../../styles/util/UtilStyles";

import SharesBidModal from "../../common/modal/SharesBidModal";
import { SharesCard } from "../../common/SharesCard";
import ViewSharesBodyNavButtons from "./ViewSharesBodyNavButtons";

export const ViewSharesBody = ({ sharesList = [], previous, next, pageNo }) => {
  const [openBidModal, setOpenBidModal] = useState(false);
  const [selectedSharePlan, setSelectedSharePlan] = useState({});

  const handleOpenBidModal = (sharePlan) => {
    setSelectedSharePlan(sharePlan);
    setOpenBidModal(true);
  };

  const handleCloseBidModal = () => {
    setSelectedSharePlan({});
    setOpenBidModal(false);
  };

  return (
    <>
      <MainSection width={"100%"} top={"2em"}>
        <Grid container spacing={4}>
          {sharesList.map((share, key) => (
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <SharesCard
                {...share}
                key={key}
                handleOpen={handleOpenBidModal}
                hasBidButton
              />
            </Grid>
          ))}
        </Grid>

        <ViewSharesBodyNavButtons {...{ previous, next, pageNo, sharesList }} />
      </MainSection>

      {openBidModal && (
        <SharesBidModal
          shareData={selectedSharePlan}
          handleClose={handleCloseBidModal}
          open={openBidModal}
        />
      )}
    </>
  );
};
