import { Grid } from "@material-ui/core";
import { Card, Main } from "../../styles/util/UtilStyles";

import authImage from "../../assets/img/auth/Image.png";

const AuthLayout = (props) => {
  return (
    <>
      <Main>
        <Grid container spacing={0}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {props.children}
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card hideOnMobile>
              <img
                src={authImage}
                alt=""
                style={{ width: "100%", height: "100vh" }}
              />
            </Card>
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default AuthLayout;
