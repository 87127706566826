import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

// --- Button ---
export const Button = styled("button")`
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "40px")};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  background: ${(props) =>
    props.background ? `${props.background}` : "#751DA5"};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => (props.center ? "auto" : "0px")};
  margin-right: ${(props) => (props.center ? "auto" : "0px")};
  border-radius: ${(props) => (props.radius ? `${props.radius}` : "0.5px")};
  box-sizing: border-box;
  border: none;
  display: block;
  font-size: ${(props) => props.size && `${props.size}`};
  border: ${(props) => props.border && `${props.border}`};
  font-weight: bold;
  margin-left: ${(props) => props.left && props.left};
  margin-right: ${(props) => props.right && props.right};
  cursor: pointer;
  outline: none;
  transition: 0.5s opacity;
  &:hover {
    opacity: 0.5;
  }
`;

// --- Button ---
export const Label = styled("label")`
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "40px")};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  background: ${(props) =>
    props.background ? `${props.background}` : "#751DA5"};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => (props.center ? "auto" : "0px")};
  margin-right: ${(props) => (props.center ? "auto" : "0px")};
  border-radius: ${(props) => (props.radius ? `${props.radius}` : "0.5px")};
  box-sizing: border-box;
  border: none;
  display: flex;
  align-items: center;
  align-self: center;
  font-size: ${(props) => props.size && `${props.size}`};
  border: ${(props) => props.border && `${props.border}`};
  font-weight: bold;
  margin-left: ${(props) => props.left && props.left};
  margin-right: ${(props) => props.right && props.right};
  text-align: ${(props) => props.position && props.position};
  cursor: pointer;
  outline: none;
  padding: 0.1px 0.1px;
  transition: 0.5s opacity;
  &:hover {
    opacity: 0.5;
  }
`;

export const Card = styled.div`
  width: ${(props) => (props.width ? `${props.width}` : "initial")};
  height: ${(props) => (props.height ? `${props.height}` : "fit-content")};
  background: ${(props) =>
    props.background ? `${props.background}` : "white"};
  box-shadow: ${(props) =>
    props.shadow ? `0px 8px 15px rgba(0, 0, 0, 0.1)` : "none"};
  box-shadow: ${(props) => props.shadowAlt && `${props.shadowAlt}`};
  display: ${(props) => (props.flex ? "flex" : "block")};
  justify-content: ${(props) => (props.center ? "center" : "none")};
  text-align: ${(props) => (props.center ? "center" : "none")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  border-radius: ${(props) => (props.radius ? `${props.radius}` : "0px")};
  background-image: ${(props) => props.bgImg && `${props.bgImg}`};
  background-size: ${(props) => props.bgSize && `${props.bgSize}`};
  padding: ${(props) => (props.padding ? `${props.padding}` : "0.1px")};
  text-align: ${(props) => props.textAlign && "center"};
  align-items: ${(props) => (props.verticalCenter ? "center" : "none")};
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between" : "none"};

  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};

  border-radius: ${(props) => (props.radius ? `${props.radius}` : "none")};

  // --- For right align on display flex ----
  justify-content: ${(props) => props.flexEnd && "flex-end"};
  display: ${(props) => props.flexEnd && "flex"};

  border: ${(props) => (props.borderColor ? `${props.borderColor}` : "none")};

  overflowx: ${(props) => props.overflowX && props.overflowX};
  overflow-x: ${(props) => props.overflowY && props.overflowY};

  transition: 0.5s zoom 0.5s background;
  &:hover {
    zoom: ${(props) => props.animateZoom && 1.1};
  }

  // --- Responsive Parts ----
  margin-left: ${(props) => props.WidthResponsive && "auto"};
  margin-right: ${(props) => props.WidthResponsive && "auto"};
  width: ${(props) => props.lgWidth && `${props.lgWidth}`};
  @media screen and (max-width: 992px) {
    width: ${(props) => props.mdWidth && `${props.mdWidth}`};
  }
  @media screen and (max-width: 768px) {
    width: ${(props) => props.smWidth && `${props.smWidth}`};
  }
  @media screen and (max-width: 481px) {
    width: ${(props) => props.xsWidth && `${props.xsWidth}`};
  }

  //   ----- To control screen size ----
  @media screen and (max-width: 992px) {
    display: ${(props) => props?.hideOnMobile && "none"};
  }
`;

export const TitleText = styled("h3")`
  color: ${(props) => (props.color ? `${props.color}` : "#000000")};
  font-size: ${(props) => (props.size ? `${props.size}` : "20px")};
  line-height: 45px;
  text-align: ${(props) => (props.position ? `${props.position}` : "left")};
  font-weight: ${(props) => (props.heavy ? `bold` : "normal")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  line-height: ${(props) => props.lineheight && `${props.lineheight}`};
  // --- For centralization ---
  text-align: ${(props) => props.center && "center"};
  font-family: ${(props) => props?.fontFamily && props?.fontFamily};
  font-style: normal;
`;

export const Text = styled("p")`
  color: ${(props) => (props.color ? `${props.color}` : "rgba(5,5,5,0.8)")};
  font-size: ${(props) => (props.size ? `${props.size}` : "16px")};
  text-align: ${(props) => (props.position ? `${props.position}` : "left")};
  font-weight: ${(props) => (props.heavy ? `bold` : "normal")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  line-height: ${(props) => props.lineheight && `${props.lineheight}`};

  // --- For centralization ---
  text-align: ${(props) => props.center && "center"};
  font-family: ${(props) => props?.fontFamily && props?.fontFamily};
  font-style: normal;
`;

export const LinkText = styled(Link)`
  color: ${(props) =>
    props.color ? `${props.color}` : "rgba(42, 159, 238, 1)"};
  font-size: ${(props) => (props.size ? `${props.size}` : "14px")};
  text-align: ${(props) => (props.position ? `${props.position}` : "left")};
  font-weight: ${(props) => (props.heavy ? `bold` : "normal")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  line-height: ${(props) => props.lineheight && `${props.lineheight}`};
  text-decoration: ${(props) => props.nounderline && "none"};

  // --- For centralization ---
  text-align: ${(props) => props.center && "center"};
  margin-left: ${(props) => props.center && "auto"};
  margin-right: ${(props) => props.center && "auto"};

  font-style: normal;
  display: ${(props) => !props.inline && "block"};
  font-family: ${(props) => props?.fontFamily && props?.fontFamily};
  width: fit-content;
`;

export const Avatar = styled.div`
  background: #751da5;
  border-radius: 50%;
  width: 2.5px;
  height: 2.5px;
`;

export const Divider = styled("hr")`
  border: ${(props) => (props.size ? `${props.size}` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(211, 213, 216, 0.5)")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
`;

export const TextField = styled("input")`
  box-sizing: border-box;
  border-radius: ${(props) => (props.radius ? props?.radius : "5px")};
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  border: ${(props) => (props.size ? `${props.size}` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(126, 126, 126, 0.4)")};
  border: ${(props) => props.noBorder && "none"};
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "48px")};
  outline: none;
  font-style: normal;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.9);
  &::placeholder {
    padding: 16px 15px;
  }
`;

export const TextAreaField = styled("textarea")`
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  border: ${(props) => (props.size ? `${props.size}` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(126, 126, 126, 0.4)")};
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "10em")};
  outline: none;
  font-style: normal;
  // font-weight: 600;
  line-height: 32px;
  color: rgba(21, 20, 57, 0.9);
  &::placeholder {
    padding: 16px 15px;
  }
`;

export const SelectField = styled("select")`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  border: ${(props) => (props.size ? `${props.size}` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(211, 213, 216, 0.5)")};
  background: ${(props) =>
    props.background ? `${props.background}` : "white"};
  padding: 0.5px 0.5px;
  font-weight: ${(props) => (props.heavy ? "bold" : "normal")};
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "3.5em")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  outline: none;
`;

export const Option = styled("option")`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}` : "0px")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0px")};
  border: ${(props) => (props.size ? `${props.size}` : "16px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(211, 213, 216, 0.5)")};
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
  height: ${(props) => (props.height ? `${props.height}` : "3px")};
  outline: none;
`;

// --- Screen width display control ---
export const ScreenControl = styled("div")`
  display: none;
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  display: ${(props) => props.all && "initial"};

  margin-left: ${(props) => props.left && `${props.left}`};
  margin-right: ${(props) => props.right && `${props.right}`};

  display: ${(props) => props.lg && "initial"};
  @media screen and (max-width: 1200px) {
    display: none;
    display: ${(props) => props.md && "initial"};
  }
  @media screen and (max-width: 960px) {
    display: none;
    display: ${(props) => props.sm && "initial"};
  }
  @media screen and (max-width: 600px) {
    display: none;
    display: ${(props) => props.xs && "initial"};
  }
`;

export const Main = styled("main")`
  width: 100%;
  height: fit-content;
  margin-top: ${(props) => (props?.top ? props?.top : "0px")};
  background: ${(props) => (props?.background ? props?.background : "white")};
  // position: absolute;
  font-family: ${(props) =>
    props.fontFamily ? `${props.fontFamily}` : "Metropolis"};
`;

export const SearchField = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  background: white;
  padding: 0.5px 1px;
  border-radius: 0.5px;
  @media screen and (max-width: 992px) {
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    width: 80%;
  }
`;

export const MainSection = styled("section")`
  width: ${(props) => (props.width ? `${props.width}` : "90%")};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.top ? `${props.top}` : "0px")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0px")};
  margin-left: ${(props) => props.left && `${props.left}`};
  margin-right: ${(props) => props.right && `${props.right}`};
  display: ${(props) => props.verticalCenter && "flex"};
  align-items: ${(props) => props.verticalCenter && "center"};
  background: ${(props) => props.background && props.background};
  // height: fit-content;
  height: 100%;

  #plans{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    #media screen and (max-width: 767px){
      grid-template-columns: 1fr;
    }
  }
`;

export const ModalCard = styled.div`
  height: ${(props) => (props.height ? `${props.height}` : "fit-content")};
  background: ${(props) =>
    props.background ? `${props.background}` : "white"};
  box-shadow: ${(props) =>
    props.shadow ? `0px 8px 15px rgba(0, 0, 0, 0.1)` : "none"};
  box-shadow: ${(props) => props.shadowAlt && `${props.shadowAlt}`};
  display: ${(props) => (props.flex ? "flex" : "block")};
  justify-content: ${(props) => (props.center ? "center" : "none")};
  text-align: ${(props) => (props.center ? "center" : "none")};
  border-radius: ${(props) => (props.radius ? `${props.radius}` : "0px")};
  background-image: ${(props) => props.bgImg && `${props.bgImg}`};
  background-size: ${(props) => props.bgSize && `${props.bgSize}`};
  padding: ${(props) => (props.padding ? `${props.padding}` : "0.1px")};
  text-align: ${(props) => props.textAlign && "center"};
  align-items: ${(props) => (props.verticalCenter ? "center" : "none")};
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between" : "none"};

  border-radius: ${(props) => (props.radius ? `${props.radius}` : "none")};

  // --- For right align on display flex ----
  justify-content: ${(props) => props.flexEnd && "flex-end"};
  display: ${(props) => props.flexEnd && "flex"};

  border: ${(props) => (props.borderColor ? `${props.borderColor}` : "none")};

  overflowx: ${(props) => props.overflowX && props.overflowX};
  overflow: ${(props) => props.overflowY && props.overflowY};
  // --- Responsive Parts ----
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  position: fixed;
  top: 50%;
  left: 50%;
  // max-height:100%;
  overflow: scroll;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 992px) {
    width: 80%;
    // margin-top:10vh;
    // margin-bottom:10vh !important;
    // height:80vh;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 481px) {
    width: 90%;
  }
`;

export const GoBack = ({ url }) => {
  const history = useHistory();
  return (
    // <>
    //   <LinkText
    //     to={url ? url : "/user/"}
    //     nounderline={"true"}
    //     color={"#670150"}
    //     left={"auto"}
    //   >
    <Button
      border={"1px solid #670150"}
      width={"10em"}
      background={"white"}
      color={"#670150"}
      left={"auto"}
      onClick={() => history.goBack()}
    >
      Go Back
    </Button>
    // </LinkText>
    // </>
  );
};

export const carouselDots = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "1.2em" : "1.2em",
      width: isActive ? "1.2em" : "1.2em",
      borderRadius: "50%",
      color: isActive ? "white" : "#670150",
      background: isActive ? "#670150" : "white",
      marginTop: "2em",
      border: isActive ? "1px solid #670150" : "1px solid #670150",
      boxShadow: isActive
        ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
        : "0px 4px 4px rgba(0, 0, 0, 0.25)",
    }}
  ></span>
);
