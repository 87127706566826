import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  AuthLayout,
  RecoverPassword,
  SendFogotPassEmail,
} from "../../components/auth";

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);
  const [recoverData, setRecoverData] = useState({ code: null, emai: null });

  const handleStep = () => {
    let currentView = null;

    if (step === 1) {
      currentView = <SendFogotPassEmail />;
    }

    if (step === 2) {
      currentView = <RecoverPassword {...recoverData} />;
    }
    return currentView;
  };

  const history = useHistory();

  useEffect(() => {
    let email = null;
    let code = null;
    if (history.location?.search) {
      email = history.location?.search.substring(7);
    }

    if (history.location?.hash) {
      code = history.location.hash.substring(1);
    }

    if (code && email) {
      setStep(2);
      setRecoverData({ code, email });
    }
  }, [history]);

  return (
    <>
      <AuthLayout>{handleStep()}</AuthLayout>
    </>
  );
};

export default ForgotPasswordPage;
