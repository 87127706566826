import {
  Card,
  Option,
  SelectField,
  Text,
} from "../../../../../styles/util/UtilStyles";

export const MySharesFilter = ({ filter, setFilter }) => {
  return (
    <>
      <Card
        width={"8em"}
        bottom="32px"
        left={"auto"}
        background={"transparent"}
      >
        <Text bottom={"16px"} heavy>
          Filter
        </Text>
        <SelectField
          defaultValue={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <Option value={"Approved"}>Approved</Option>
          <Option value={"Pending"}>Pending</Option>
        </SelectField>
      </Card>
    </>
  );
};
