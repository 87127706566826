import { toast } from "react-toastify";

import { fetchApi } from "../utility/fetchApi";

import { baseUrl } from "../utility/Util";

export const loginApi = async (params, setLoading, history, appContext) => {
  if (!params?.email && !params?.password) {
    return toast.warning("Ensure email and password are passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/auth/login/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const { handleSetUser, handleSetToken, handleSetIsLoggedIn } = appContext;

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Successfully logged in...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      data.data.user.userType = "User";

      handleSetUser(data?.data?.user);
      handleSetToken(data?.data?.accessToken);
      handleSetIsLoggedIn(true);
      return setTimeout(() => {
        history.push("/user/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const signUpApi = async (params, setLoading, history) => {
  if (params?.password?.length < 8) {
    return toast.error("Password length too short!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);

  const APIUrl = `${baseUrl}/auth/register`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    params.userType = "User";
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(
        "Account successfully created. Wait while we redirect you.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
      return setTimeout(() => {
        history.push("/auth/login/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const sendRecoverAcctEmailApi = async (
  params,
  setLoading,
  history,
  appContext
) => {
  if (!params?.email) {
    return toast.warning("Ensure the email to your lost account is passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/auth/forgotPassword/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success(
        "An email has just been sent to you now. Kindly open it and proceed. Thanks",
        {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const response = await getResponse();
  return response;
};

export const setNewPassAcctApi = async (params, setLoading, history) => {
  if (!params?.email) {
    return toast.warning("Ensure the email to your lost account is passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/auth/forgotPassword/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success(
        "Your account has been successfully recovered. You will be redirected to login now.",
        {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setTimeout(() => {
        return history.push("/auth/login/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const adminSignUpApi = async (params, setLoading, history) => {
  if (params?.password?.length < 8) {
    return toast.error("Password length too short!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);

  const APIUrl = `${baseUrl}/admin/auth/register/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    params.userType = "User";
    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(
        "Account successfully created. Wait while we redirect you.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
      return setTimeout(() => {
        history.push("/auth/login/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const adminLoginApi = async (
  params,
  setLoading,
  history,
  appContext
) => {
  if (!params?.email && !params?.password) {
    return toast.warning("Ensure email and password are passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/admin/auth/login/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const { handleSetUser, handleSetToken, handleSetIsLoggedIn } = appContext;

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Successfully logged in...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      data.data.admin.userType = "Admin";
      handleSetUser(data?.data?.admin);
      handleSetToken(data?.data?.accessToken);
      handleSetIsLoggedIn(true);
      return setTimeout(() => {
        history.push("/admin/dashboard/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const managerSignUpApi = async (params, setLoading, history) => {
  if (params?.password?.length < 8) {
    return toast.error("Password length too short!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);

  const APIUrl = `${baseUrl}/auth/register/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    params.userType = "Manager";

    const { isError, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(
        "Account successfully created. Wait while we redirect you.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setLoading(false);
      return setTimeout(() => {
        history.push("/manager/login/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const managerLoginApi = async (
  params,
  setLoading,
  history,
  appContext
) => {
  if (!params?.email && !params?.password) {
    return toast.warning("Ensure email and password are passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/auth/login/`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const { handleSetUser, handleSetToken, handleSetIsLoggedIn } = appContext;

  const getResponse = async () => {
    params.userType = "Manager";
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.success("Successfully logged in...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (data.data?.user?.userType === "Manager") {
        handleSetUser(data?.data?.user);
        handleSetToken(data?.data?.accessToken);
        handleSetIsLoggedIn(true);
      }

      return setTimeout(() => {
        history.push("/manager/dashboard/");
      }, 3000);
    }
  };

  const response = await getResponse();
  return response;
};

export const verifyAccountApi = async (
  setLoading,
  verifyCode,
  setConfirmMessage,
  setIsConfirmed,
  history
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/auth/verify-account/${verifyCode}`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      setConfirmMessage(errorMessage);
      return { isError: true, message: errorMessage };
    } else {
      setConfirmMessage(data?.message);
      setLoading(false);
      setIsConfirmed(true);
      toast.success(
        "Account successfully verified. Please, wait while we redirect you to login.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTimeout(() => {
        history.push("/auth/login/");
      }, 3000);
      return { isError: false, message: data?.message };
    }
  };

  const response = await getResponse();
  return response;
};

export const resetEarlyBirdPasswordApi = async (
  params,
  setLoading,
  history,
  userId
) => {
  setLoading(true);
  const APIUrl = `${baseUrl}/auth/early-bird/new-password/${userId}`;

  let requestHeaders = {
    "Content-Type": "application/json",
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
      setTimeout(() => {
        history.push("/auth/login/");
      }, 1500);

      return { data: data.data };
    }
  };

  const response = await getResponse();
  return response;
};

export const logOut = async (appContext, history) => {
  const { token, clearData } = appContext;
  const APIUrl = `${baseUrl}/users/logout/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "POST",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      clearData();
      toast.success("Successfully logged out...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return { errorMessage, isError };
    } else {
      toast.success("Successfully logged out...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      clearData();
      setTimeout(() => {
        history.push("/auth/login/");
      }, 3000);
      return { data: data.data };
    }
  };

  const response = await getResponse();
  return response;
};

export const changePasswordApi = async (params, setLoading, appContext) => {
  if (
    !params?.currentPassword &&
    !params?.newPassword &&
    !params?.confirmPassword
  ) {
    return toast.warning("Ensure all fields are passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/profile/changePassword`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      return toast.success("Password updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const updateProfileApi = async (params, setLoading, appContext) => {
  if (
    !params?.firstName &&
    !params?.lastName
    // &&!params?.confirmPassword
  ) {
    return toast.warning("Ensure all fields are passed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/profile/update/`;

  const { token, handleSetUser } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      handleSetUser(data?.data?.user);
      return toast.success("Profile updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const updateProfilePicApi = async (params, setLoading, appContext) => {
  if (
    !params?.picture
    // &&!params?.confirmPassword
  ) {
    return toast.warning("Ensure an image is selected.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  setLoading(true);
  const APIUrl = `${baseUrl}/profile/upload_picture`;

  const { token } = appContext;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, errorMessage } = await fetchApi(
      params,
      "PATCH",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      setLoading(false);
      return toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      return toast.success("Profile Picture updated!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const response = await getResponse();
  return response;
};

export const getUserDetailsApi = async (history, appContext) => {
  const { token, user, handleSetUser } = appContext;
  let APIUrl = ``;

  if (user?.userType === "Admin") {
    APIUrl = `${baseUrl}/admin/auth/admin/`;
  }

  if (user?.userType === "User") {
    APIUrl = `${baseUrl}/auth/user/`;
  }

  if (user?.userType === "Manager") {
    APIUrl = `${baseUrl}/auth/user/`;
  }

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi({}, "GET", APIUrl, requestHeaders);

    if (isError) {
      // toast.error(errorMessage, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      return setTimeout(() => {
        history.push("/auth/login/");
      }, 3000);
    } else {
      if (user?.userType === "Admin") {
        data.data.admin.userType = "Admin";
        handleSetUser(data?.data?.admin);
      } else {
        data.data.user.userType = user?.userType;
        handleSetUser(data?.data?.user);
      }
    }
  };

  const response = await getResponse();
  return response;
};

export const getAUserInfoApi = async (
  userId,
  setResult,
  setFetching,
  appContext
) => {
  const { token } = appContext;
  setFetching(true);

  let APIUrl = `${baseUrl}/auth/user/${userId}`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setResult(data?.data?.user);
      setFetching(false);
    }
  };

  const response = await getResponse();
  return response;
};

export const getAllManagers = async (appContext) => {
  const { token, user, handleSetManagers } = appContext;

  let APIUrl = ``;

  if (user?.userType === "Admin") {
    APIUrl = `${baseUrl}/admin/users/managers`;
  }

  if (user?.userType === "User") {
    APIUrl = `${baseUrl}/admin/users/managers`;
  }

  if (user?.userType === "Manager") {
    APIUrl = `${baseUrl}/admin/users/managers`;
  }

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getResponse = async () => {
    const { isError, data } = await fetchApi({}, "GET", APIUrl, requestHeaders);

    if (isError) {
      handleSetManagers([]);
    } else {
      let allManagers = {};
      let resp = data.data.managers;

      resp.map((manager) => {
        let id = manager._id;
        allManagers[id] = manager;

        return allManagers;
      });

      let managersList = {
        dict: allManagers,
        array: resp,
      };
      handleSetManagers(managersList);
    }
  };

  const response = await getResponse();
  return response;
};

export const getNotifications = async (appContext, setFetching, setData) => {
  const { token } = appContext;
  let APIUrl = `${baseUrl}/notification/fetch/`;

  let requestHeaders = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  setFetching(true);

  const getResponse = async () => {
    const { isError, data, errorMessage } = await fetchApi(
      {},
      "GET",
      APIUrl,
      requestHeaders
    );

    if (isError) {
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFetching(false);
    } else {
      setFetching(false);
      setData(data.data.notifications);
    }
  };

  const response = await getResponse();
  return response;
};
