import {
  AboutUsMiddle,
  ContactUsTopView,
  OurClientSpeaks,
  Subscribers,
} from "../../components/home";

import Layout from "../../components/homeLayout/Layout";
import { Card } from "../../styles/util/UtilStyles";

const AboutUsPage = () => {
  return (
    <>
      <Layout notWhiteBg>
        <Card background={"#EAEAEE"}>
          <ContactUsTopView />
          <AboutUsMiddle />
          <OurClientSpeaks />
          <Subscribers />
        </Card>
      </Layout>
    </>
  );
};

export default AboutUsPage;
