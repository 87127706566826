import { useContext, useEffect, useState } from "react";
import { getAdminShareInfo } from "../../../adapters/admin";
import { STATUSES } from "../../../adapters/utility/enums";
import { AppContext } from "../../../App";
import AdminEditSharesBody from "../../../components/admin/shares/AdminEditShares/AdminEditSharesBody";
import AdminViewShareInfoLoading from "../../../components/admin/shares/AdminViewShareInfo/loading/AdminViewShareInfoLoading";
import UserLayout from "../../../components/userLayout/UserLayout";
import { GoBack } from "../../../styles/util/UtilStyles";
import { NotFoundError } from "../../error";

const AdminEditSharePage = ({ match }) => {
  const appContext = useContext(AppContext);

  const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;
  const [status, setStatus] = useState(DATA);

  const [shareData, setShareData] = useState({});

  useEffect(() => {
    const {
      params: { shareId },
    } = match || {};
    let params = { _id: shareId };
    getAdminShareInfo(params, setStatus, appContext, setShareData);
    // eslint-disable-next-line
  }, [appContext, match]);

  const renderBaseOnStatus = () => {
    switch (status) {
      case LOADING:
        return <AdminViewShareInfoLoading />;
      case DATA:
        return <AdminEditSharesBody {...shareData} appContext={appContext} />;
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"No Shares Added Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout pageTitle={"Edit Share Plan"} pageTitleComponent={<GoBack />}>
        {renderBaseOnStatus()}
      </UserLayout>
    </>
  );
};

export default AdminEditSharePage;
