import { useHistory } from "react-router";
import {
  Button,
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

// import homeImage from "../../assets/img/home/Image Placeholder.png";

const ContactUsTopView = () => {
  const history = useHistory();

  return (
    <>
      <Card top={"0em"} height={"fit-content"} background={"#EAEAEE"}>
        <MainSection top={"10em"} bottom={"5em"}>
          <TitleText
            fontFamily={"Mulish"}
            color={"#120216"}
            size={"32px"}
            heavy
            center
          >
            Vimevest Investment
          </TitleText>
          <Text
            fontFamily={"Mulish"}
            color={"rgba(21, 20, 57, 0.4)"}
            top={"20px"}
            lineheight={"40px"}
            width={"80%"}
            center
          >
            Welcome to Vimevest, we are happy and grateful you are here.
            <br />
            Vimevest was created to provide solutions to people who find it
            difficult to invest. We are one of the 6 unique brands of{" "}
            <b>vikkymore multi-business limited</b> with the aim of helping
            people’s financial growth.
            <br /> We are a forward thinking investment platform that enables
            our audience to have the type of life they desire through
            investment.
            <br /> We provide distinguished investment plans to our clients
            which includes, wealth management services that procures a limitless
            foundation for keeping and growing their assets, discretionary
            investment plans which allows our clients the freewill on duration
            of what they intend to invest and at large peace of mind as our
            platform is secure and free from fraudulent stories that touch.
          </Text>
          <Button
            width={"10em"}
            top={"20px"}
            background={"#F98809"}
            color={"white"}
            radius={"16px"}
            onClick={() => history.push("/all-investments/")}
            center
          >
            Invest Now
          </Button>
        </MainSection>
      </Card>
    </>
  );
};

export default ContactUsTopView;
