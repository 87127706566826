import { useState } from "react";
import { suspendOrActivateAUser } from "../../adapters/admin";
import { Button } from "../../styles/util/UtilStyles";

const SuspendUser = ({ isActive, setResult, appContext, userId }) => {
  const [loading, setLoading] = useState(false);
  const handleUserStatus = (e) => {
    e.preventDefault();
    let params = {
      userID: userId,
    };
    suspendOrActivateAUser(isActive, params, setResult, setLoading, appContext);
  };
  return (
    <>
      <Button
        type="submit"
        background={isActive ? "rgba(103, 1, 80, 1)" : "white"}
        border={"1px solid rgba(103, 1, 80, 0.5)"}
        color={isActive ? "white" : "#670150"}
        disabled={loading}
        onClick={handleUserStatus}
      >
        {loading ? "Please wait..." : <>{isActive ? "Suspend User" : "Activate User"}</>}
      </Button>
    </>
  );
};

export default SuspendUser;
