import Layout from "../../components/homeLayout/Layout";
import {
  Card,
  MainSection,
  ScreenControl,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import image from "../../assets/img/home/Web-BannerArtboard-1.png";

const terms = [
  {
    title: `GENERAL`,
    text: `The Platform may include or make available certain content (the “Content”), which includes, without limitation: (1) account positions, balances, transactions, confirmations, and order history; (2) general news and information, commentary, educational material and information and data concerning the financial markets; (3) market data such as prices and returns for securities transactions and/or (4) wallet balance, amount invested, simulation calculators; (5) account management tools; (6) company names, logos, product and service names, trade names, trademarks and services marks (collectively, “Marks”) owned by Company, and Marks owned by Third-Party Providers (defined below); and (7) any other information, content, services, or software. Certain Content is supplied, distributed, provided or delivered by a Third-Party Provider (the “Third-Party Content”) such as information, services or software, made available by, through or on the Platform.`,
  },
  {
    title: "LEGAL AGE",
    text: `You must be at least 18 years of age to become a user of the Platform. You represent and warrant that you are 18 years of age or older and are fully able and competent to enter into, and abide by, the contract created by these Terms of Use.`,
  },
  {
    title: `ACCOUNTS`,
    text: `
  To gain access to the services we offer on vimevest, each client is required to sign-up on the Platform through the vimevest app or website vimevest.com, which would require the provision of certain personally identifiable information, including but not limited to your name, your phone number, your address, your email address... You will select your own password at the time of registration, and you agree that:
<br/>You are eighteen years of age and otherwise comply with applicable law;
<br/>You will not use a username (or e-mail address) that is already being used by someone else, or that infringes the intellectual property or other right of any person or entity, or is offensive;
<br/>You will provide true, accurate, current, and complete registration information about yourself in connection with the registration process;
<br/>You are solely responsible for all activities that occur under your Account, password, and username;
<br/>You are solely responsible for maintaining the confidentiality of your password and for restricting access to your mobile device so that others may not access any password protected portion of Platform using your name, username, or password;
<br/>You will immediately notify us of any unauthorized use of your Account, password, or username, or any other breach of security; and
<br/>You will not sell, transfer, or assign your account or any Account rights.
<br/><br/>OPTING TO CREATE AN ACCOUNT ON THE PLATFORM, SATISFIES THAT YOU HAVE ACCEPTED ALL OUR TERMS AND CONDITIONS.
<br/>If any information that you provide, or if we have reasonable grounds to suspect that any information that you provide, is false, inaccurate, outdated, incomplete, or violates these Terms or any applicable law, then we may suspend or terminate your account without recourse to you. You agree that you will make available upon request, up to date identification and any other additional information, in order to keep it true, accurate and current. We also reserve the more general and broad right to terminate your account or suspend or otherwise deny you access to it or its benefits – all in our sole discretion, for any reason, with or without notice to you and without liability`,
  },
  {
    title: `NO RECOMMENDATIONS OR INVESTMENT ADVICE`,
    text: `You are solely responsible for evaluating the merits and risks associated with the use of any Content provided through the Service before making any decisions based on such Content. You agree not to hold the Company or any Third-Party Provider liable for any possible claim for damages arising from any decision you make based on the Content or other information made available to you through the Service or any Third-Party Provider. Past performance data should not be construed as indicative of future results.`,
  },
  {
    title: `EXECUTION OF ORDERS`,
    text: `All plans on vimevest are executed through Vimevest, or its financial partners. The decision to accept or reject a customer’s investment plan is at the sole discretion of Vimevest and any of its financial partners. Transactions on the Platform are guided by laws and regulations of the Nigerian financial market and its regulators.`,
  },
  {
    title: `ORDER CANCELLATION/ MODIFICATION`,
    text: `Customer acknowledges that it may not be possible to cancel/ modify an order and that the Customer is responsible for executions notwithstanding a cancel/modify request.`,
  },
  {
    title: `CONFLICT OF INTEREST AND DISCLOSURE`,
    text: `Executives and former executives of Companies which they wish to purchase shares of, shall comply with the Company’s Insider Trading Policy and Securities and Exchange Commission (SEC) Nigeria Rules and Guidelines , including but not limited to those that relate to non-disclosure of information, insider trading and individual reporting requirements and shall specifically abstain from discussing the non-public aspects of the Corporation’s business affairs with any individual or group of individuals . Such executive hereby agrees to immediately notify the Corporation’s Compliance Officer or Chief Financial Officer in accordance with the Corporation’s Insider Trading Policy prior to acquisition or disposition of such Corporation’s securities.`,
  },
  {
    title: `CONFIRMATION OF INVESTMENTS`,
    text: `
  All client investment orders accepted on vimevest are backed-up by a confirmation message, while investment orders rejected receive a rejection message. However, confirmation or rejection messages may be delayed due to computer system issues. Therefore, the client is to monitor each investment order until vimevest confirms the status of each plan. Customer agrees to notify vimevest immediately by telephone or electronically through the vimevest website if:
  <br/>Customer fails to receive an accurate information on investment orders executed;
  <br/>Customer receives a confirmation for an investment order that the Customer did not place; or
  <br/>Customer receives an account statement, confirmation or other information reflecting inaccurate orders, balances, positions, or transaction history. Customer acknowledges that Vimevest may adjust Customer’s account to correct any error. Customer agrees to promptly return to Vimevest any assets erroneously distributed to Customer.`,
  },
  {
    title: `MATURITY OF INVESTMENT`,
    text: `
  All financial investment options offered on the platform/app have a maturity date, which each client agrees to before purchasing a product. Sales proceeds from equity investments will be credited to the customer’s wallet.  All bank charges applied during the transfer of funds to accounts are fully borne by the customer.`,
  },
  {
    title: `PRE-LIQUIDATION`,
    text: `Certain products listed on Vimevest provides the customer the opportunity for early termination with a penalty. The extent of the penalties depend on the nature of the investment product and can only be done thirty (30) days after investment has been made.`,
  },
  {
    title: `CLIENT PROTECTION CLAUSE`,
    text: `Vimevest undertakes, to the extent required under law,to protect the privacy of all information provided by users of vimevest except in instances of disclosure to law enforcement agencies and or statutory agencies to assist in the prevention or detection of fraud or other criminal activities.`,
  },
  {
    title: `CONTACT`,
    text: `If you require help or have questions or complaint about i-invest, please call our customer care centre on  <b>08122450145</b> or email complaints@ <b>vimevest@gmail.com</b>`,
  },
];

const TermsPage = () => {
  return (
    <>
      <Layout title={"VikkyMore - Terms & Policy"}>
        <MainSection top={"10em"} bottom={"5em"}>
          <TitleText
            fontFamily={"Mulish"}
            color={"#120216"}
            size={"32px"}
            heavy
            // center
          >
            Our Terms & Privacy Policy.
          </TitleText>
          <ScreenControl lg md>
            <Card top={"32px"} bottom={"16px"}>
              <img src={image} alt="about us" style={{ width: "50%" }} />
            </Card>
          </ScreenControl>

          <ScreenControl sm xs>
            <Card top={"16px"} bottom={"16px"}>
              <img src={image} alt="about us" style={{ width: "100%" }} />
            </Card>
          </ScreenControl>
          <Text
            fontFamily={"Mulish"}
            color={"rgba(21, 20, 57, 0.4)"}
            top={"20px"}
            lineheight={"40px"}
            width={"80%"}
            position={"justify"}
            // center
          >
            These terms and conditions are or will form a legally binding
            agreement between you and Vimevest establishing how this website or
            platform may be used. Use of any Vimevest's online services implies
            that you have accepted the terms conditions. If you do not accept
            these terms and conditions please do not use the platform.
            {/* Welcome to Vimevest, we are happy and grateful you are here.
            <br />
            Vimevest was created to provide solutions to people who find it
            difficult to invest. We are one of the 6 unique brands of{" "}
            <b>vikkymore multi-business limited</b> with the aim of helping
            people’s financial growth.
            <br /> We are a forward thinking investment platform that enables
            our audience to have the type of life they desire through
            investment.
            <br />
            <br /> We provide distinguished investment plans to our clients
            which includes, wealth management services that procures a limitless
            foundation for keeping and growing their assets, discretionary
            investment plans which allows our clients the freewill on duration
            of what they intend to invest and at large peace of mind as our
            platform is secure and free from fraudulent stories that touch. */}
          </Text>
          {terms.map((item, pos) => (
            <Card key={pos} top="1em">
              <TitleText
                fontFamily={"Mulish"}
                color={"#120216"}
                lineheight={"40px"}
                heavy
              >
                {item?.title}
              </TitleText>
              <Text
                fontFamily={"Mulish"}
                color={"rgba(21, 20, 57, 0.4)"}
                top={"20px"}
                lineheight={"40px"}
                width={"80%"}
                position={"justify"}
                dangerouslySetInnerHTML={{ __html: item?.text }}
              />
            </Card>
          ))}
        </MainSection>
      </Layout>
    </>
  );
};

export default TermsPage;
