import { Grid } from "@material-ui/core";
import ArrowDropDownRounded from "@material-ui/icons/ArrowDropDownRounded";
import { useState } from "react";
import Layout from "../../components/homeLayout/Layout";
import { Circle } from "../../styles/util/icon";
import {
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const faqQuestions = [
  {
    quest: "What is an investment?",
    ans: "Investing is the act of allocating resources, usually money, with the expectation of generating an income or profit.",
  },
  {
    quest: "What do I stand to gain from investing with Vimevest?",
    ans: "It is a real chance at financial freedom. Once you invest with Vimevest, you have put yourself in a place of advantage. Your asset or item will appreciate leaving smiles on your face.",
  },
  {
    quest: "How can I invest on Vimevest?",
    ans: "All you have to do is open an account on vimevest.com or contact customer support on 08122450145. You can also get the help you need by emailing vimevest@gmail.com.",
  },
  {
    quest: "How much is the least amount I can invest?",
    ans: "You can invest as low as N10,000 (ten thousand naira) on Vimevest.",
  },
  {
    quest: "When can I withdraw my ROI?",
    ans: "Your return on investment can be withdrawn on the end date of your investment plan.",
  },
  {
    quest: "Are my funds secure?",
    ans: "Vimevest is a legal company with licenses to practice in Nigeria. If there is any case of suspected fraud, you can visit our office at Number 18, University Crescent, Obaolagbegi Estate, Osuntokun Avenue Bodija Ibadan to lodge your complaint.",
  },
  {
    quest: "Is there a referral bonus?",
    ans: "If you invite a friend to invest with Vimevest, you will earn 0.1% of every deposit made from every account created under your link.",
  },
  {
    quest: "What are the requirements to invest on Vimevest?",
    ans: `Two things! 
    <br/>
    i. You must be 18 years old before you invest on Vimevest. It requires going to www.vimevest.com 
   <br/>
    ii. Depositing the funds you would like to invest.
    `,
  },
  {
    quest: "Does investing on Vimevest involve having a pyramid?",
    ans: "Vimevest is not a Ponzi scheme and it does not involve having a pyramid.",
  },
  {
    quest: "How do I invest funds?",
    ans: "You can invest on Vimevest by choosing from any of our plans and funding the project.",
  },
  {
    quest: "What is the minimum maturity period of my asset?",
    ans: "It depends on the investment plan.",
  },
  {
    quest: "Where is Vimevest located?",
    ans: "Vimevest is a sub-brand of Vikkymore Multi-business Limited located at Number 18, University Crescent, Obaolagbegi Estate. Osuntokun avenue, Bodija Ibadan.",
  },
  {
    quest: "Are you having trouble logging in?",
    ans: "Try clicking on “forgot password” and if the problem persists, kindly contact our customer service on 08122450145 or send an email to vimevest@gmail.com.",
  },
];

const FaqsPage = () => {
  const [dropDown, setDropDown] = useState({});

  const handleDropDown = (pos) => {
    setDropDown({
      [pos]: true,
    });
  };
  return (
    <>
      <Layout title={"VikkyMore - FAQS"}>
        <MainSection bottom={"64px"}>
          <Card top={"100px"} bottom={"32px"}>
            <TitleText
              color={"#11142D"}
              fontFamily={"Mulish"}
              size={"24px"}
              top={"24px"}
              heavy
              center
            >
              Frequently Asked Questions
            </TitleText>
            <Text
              color={"#92929D"}
              fontFamily={"Mulish"}
              size={"14px"}
              lineheight={"24px"}
              center
            >
              We will also facilitate the business marketing of these products
              <br />
              with our SEO experts so that they become a ready-to-use website
              and
              <br />
              help sell a product from the company
            </Text>
            <Card top={"32px"} width={"90%"} left={"auto"} right={"auto"}>
              <Grid container spacing={4}>
                {faqQuestions.map((item, pos) => (
                  <Grid item lg={6} md={6} sm={6} xs={12} key={pos}>
                    <Card radius={"8px"} borderColor={"1px solid #F1F1F6"}>
                      <Card
                        left={"8px"}
                        right={"8px"}
                        top={"8px"}
                        bottom={"8px"}
                        flex
                        spaceBetween
                      >
                        <Text
                          color={"#11142D"}
                          fontFamily={"Mulish"}
                          lineheight={"24px"}
                        >
                          {item?.quest}
                        </Text>

                        <Circle
                          onClick={() => handleDropDown(pos)}
                          color={"#9A9AB0"}
                          background={"white"}
                        >
                          <ArrowDropDownRounded />
                        </Circle>
                      </Card>
                      {dropDown[pos] && (
                        <Card left={"8px"} bottom={"8px"} right={"8px"}>
                          <Text
                            color={"#11142D"}
                            fontFamily={"Mulish"}
                            lineheight={"20px"}
                            size={"14px"}
                            dangerouslySetInnerHTML={{ __html: item?.ans }}
                          />
                        </Card>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Card>
        </MainSection>
      </Layout>
    </>
  );
};

export default FaqsPage;
