import moment from "moment";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const convertDate = (timestamp) =>
  moment(timestamp).format("MMMM DD, YYYY");

export const getFormattedDate = (timestamp) =>
  moment(timestamp).format("MMMM DD, YYYY");

export const todayDate = () => moment().format("MMMM DD, YYYY");

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const initiatePayConfig = (
  amount,
  email,
  phoneNumber,
  firstName,
  title,
  description
) => {
  return {
    public_key: "FLWPUBK_TEST-16b4cfde4551eed88a788b06e3919224-X",
    tx_ref: Date.now(),
    amount: amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phonenumber: phoneNumber,
      name: firstName,
    },
    customizations: {
      title: title,
      description: description,
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
  };
};

export const goNextPage = (pageNo, setPageNo) => {};

export const goPreviousPage = (pageNo, setPageNo) => {};
