import { useContext, useState } from "react";

import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  LinkText,
  MainSection,
  Text,
  // TextField,
  TitleText,
} from "../../styles/util/UtilStyles";
import { increment } from "../../redux/config/counterSlice";
import { loginApi } from "../../adapters/user";
import { AppContext } from "../../App";
import { useHistory } from "react-router-dom";

const Login = () => {
  const count = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const appContext = useContext(AppContext);

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(increment());
    console.log(count);

    loginApi(data, setLoading, history, appContext);
  };

  return (
    <>
      <MainSection>
        <Card flexEnd>
          <LinkText
            to="/auth/register/"
            color={"rgba(21, 20, 57, 0.4)"}
            position={"right"}
            lineheight={"64px"}
            nounderline={"true"}
          >
            New here? <b style={{ color: "#F2994A" }}>Sign Up</b>
          </LinkText>
        </Card>
        <Card bottom={"16px"}>
          <TitleText
            top={"3em"}
            color={"#670150"}
            lineheight={"52px"}
            size={"32px"}
            heavy
          >
            Sign In to Discover Startup Features
          </TitleText>
        </Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              EMAIL
            </Text>
            <TextField
              placeholder="Enter Your Email"
              variant="outlined"
              type="email"
              {...register("email")}
              required
              fullWidth
            />
            {/* <TextField type="email" placeholder={"Your email"} radius={"100px"} /> */}
          </Card>

          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              PASSWORD
            </Text>
            <TextField
              // label="Enter Your Password"
              variant="outlined"
              type="password"
              placeholder="Enter Your Password"
              {...register("password")}
              required
              fullWidth
            />
            {/* <TextField
            type="password"
            placeholder={"Your password"}
            radius={"100px"}
          /> */}
          </Card>

          <Card flexEnd>
            <LinkText
              to="/auth/recover-account/"
              color={"#F2994A"}
              position={"right"}
              lineheight={"64px"}
            >
              Forgot Password
            </LinkText>
          </Card>

          <Card>
            <Button
              type="submit"
              background={"#670150"}
              radius={"100px"}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Login"}
            </Button>
          </Card>
        </form>
      </MainSection>
    </>
  );
};

export default Login;
