import { Card, LinkText, Text, TitleText } from "../../styles/util/UtilStyles";

// import NotFoundImg from "../../assets/img/util/NotFound.png";

import { useHistory } from "react-router";
import { ErrorOutlined } from "@material-ui/icons";

const NotFoundError = ({ errorTitle, errorText }) => {
  const history = useHistory();
  
  return (
    <>
      <Card top={"32px"} background={"none"} center>
        {/* <img
          src={NotFoundImg}
          style={{ width: "12em", height: "12em", marginTop: "5em" }}
          alt="Not Found"
        /> */}
        <ErrorOutlined
          style={{
            width: "8em",
            height: "8em",
            marginTop: "2em",
            color: "#751DA5",
            opacity: "0.1",
          }}
        />
        <TitleText center>{errorTitle}</TitleText>
        <Text bottom={"32px"} size={"0.9em"} center>
          You may{" "}
          <LinkText onClick={() => history.go()} to={"#"} inline>
            Refresh
          </LinkText>{" "}
          the page if your network connectivity was not strong.
        </Text>
      </Card>
    </>
  );
};

export default NotFoundError;
