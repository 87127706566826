import { useState } from "react";

import { Box } from "../../styles/util/icon";

import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Label,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import InvestAskAmountModal from "./InvestAskAmountModal";
import { getFormattedAmount, truncateText } from "../../Utils";

const InvestmentCard = ({
  investmentID,
  title,
  status,
  interest,
  duration,
  amount,
  minimum,
  description,
  picture,
  appContext,
  userState,
  amountRemaining,
  _id,
}) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  let investDetails = {
    title,
    investmentID: investmentID ? investmentID : _id,
    amount,
    minimum,
    amountRemaining,
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Card>
        <Card left={"16px"} top={"16px"} right={"16px"} bottom={"16px"}>
          <Box radius={"10px"} width={"5em"} height={"5em"}>
            <img
              src={picture}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <TitleText
            top={"16px"}
            color={"#000000"}
            size={"20px"}
            lineheight={"30px"}
            fontFamily={"Poppins"}
            heavy
          >
            {title}
          </TitleText>
          <Text
            top={"8px"}
            fontFamily={"Poppins"}
            size={"14px"}
            lineheight={"21px"}
            color={"rgba(0, 0, 0, 0.8)"}
          >
            {truncateText(description, 5)}
          </Text>
          <Card top={"16px"} flex spaceBetween>
            <Label
              background={"rgba(103, 1, 80, 0.1)"}
              width={"5em"}
              position={"center"}
            >
              <Text
                size={"12px"}
                color={"#670150"}
                fontFamily={"Poppins"}
                left={"auto"}
                right={"auto"}
              >
                {interest} %
              </Text>
            </Label>
            <Label
              background={"rgba(103, 1, 80, 0.1)"}
              width={"5em"}
              position={"center"}
            >
              <Text
                size={"12px"}
                color={"#670150"}
                fontFamily={"Poppins"}
                left={"auto"}
                right={"auto"}
              >
                {duration} Days
              </Text>
            </Label>
            <Label
              background={"rgba(103, 1, 80, 0.1)"}
              width={"5em"}
              position={"center"}
            >
              <Text
                size={"12px"}
                color={"#670150"}
                fontFamily={"Poppins"}
                left={"auto"}
                right={"auto"}
              >
                N {getFormattedAmount(amount)}
              </Text>
            </Label>
          </Card>

          <Card top={"32px"} flex spaceBetween>
            {amountRemaining ? (
              <>
                {userState ? (
                  <Button
                    radius={"5px"}
                    background={"#670150"}
                    color={"white"}
                    width={"8em"}
                    // onClick={handleInvestNow}
                    // onClick={() => setOpenModal(true)}
                  >
                    View Details
                  </Button>
                ) : (
                  <Button
                    radius={"5px"}
                    background={"#670150"}
                    color={"white"}
                    width={"8em"}
                    // onClick={handleInvestNow}
                    onClick={() => setOpenModal(true)}
                  >
                    {status === "Pending" ? "Invest More" : "Invest Now"}
                  </Button>
                )}
              </>
            ) : (
              <Button
                radius={"5px"}
                background={"white"}
                color={"#670150"}
                width={"8em"}
                disabled={true}
                border={"1px solid #670150"}
                // onClick={handleInvestNow}
                // onClick={() => setOpenModal(true)}
              >
                {status}
              </Button>
            )}
            <Button
              radius={"5px"}
              background={"#670150"}
              color={"white"}
              width={"8em"}
              onClick={() =>
                history.push(`/investment/${investmentID ? investmentID : _id}`)
              }
            >
              Check out.
            </Button>
          </Card>
        </Card>
      </Card>

      <InvestAskAmountModal
        open={openModal}
        handleClose={handleClose}
        investDetails={investDetails}
        appContext={appContext}
      />
    </>
  );
};

export default InvestmentCard;
