import { Card, Text, TitleText } from "../../styles/util/UtilStyles";

import walletBalanceImg from "../../assets/img/dashboard/WalletCardBackground.png";
import { Divider, Grid } from "@material-ui/core";
import { getFormattedAmount } from "../../Utils";

const AccountBalanceCard = ({ appContext }) => {
  const { user } = appContext;
  const { wallet } = user;
  return (
    <>
      <Card
        height={"13em"}
        radius={"15px"}
        bgSize={"100% 100%"}
        bgImg={`url('${walletBalanceImg}')`}
        background={
          "linear-gradient(112.32deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 101.12%)"
        }
      >
        <Card
          background={"transparent"}
          radius={"10px"}
          top={"32px"}
          bottom={"16px"}
          right={"16px"}
          left={"16px"}
        >
          <Grid container spacing={4}>
            <Grid item lg={8} md={6} sm={6} xs={8}>
              <TitleText color={"white"} lineheight={"20px"} heavy>
                Wallet Balance
              </TitleText>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={4}></Grid>
          </Grid>
        </Card>
        <Card
          background={"transparent"}
          radius={"10px"}
          top={"24px"}
          bottom={"16px"}
          right={"16px"}
          left={"16px"}
        >
          <Grid container spacing={4}>
            <Grid item lg={5} md={5} sm={5} xs={5}>
              <Text color="white" lineheight={"24px"} size={"14px"}>
                Your balance (NGN)
              </Text>
              <Text color={"white"} lineheight={"30px"} size={"18px"}>
                {getFormattedAmount(wallet)}
              </Text>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <Divider style={{ transform: "260deg" }} />
            </Grid>
            {/* <Grid item lg={5} md={5} sm={5} xs={5}>
              <Text color="white" lineheight={"24px"} size={"12px"}>
                Naira
              </Text>
              <Text color={"white"} lineheight={"30px"} size={"18px"}>
                {wallet}
              </Text>
            </Grid> */}
          </Grid>
        </Card>
      </Card>
    </>
  );
};

export default AccountBalanceCard;
