import { AuthLayout, Signup } from "../../components/auth";

const RegisterPage = () => {
  return (
    <>
      <AuthLayout>
        <Signup />
      </AuthLayout>
    </>
  );
};

export default RegisterPage;
