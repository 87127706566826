import { Card } from "../../styles/util/UtilStyles";
import { Avatar } from "@material-ui/core";

const UserProfilePic = ({ picture }) => {
  return (
    <>
      <Card background={"#320528"} radius={"10px"}>
        <Card
          background={"transparent"}
          top={"16px"}
          bottom={"16px"}
          right={"16px"}
          left={"16px"}
        >
          <Avatar
            style={{
              width: "12em",
              height: "12em",
              border: "1px solid rgba(255,255,255,0.5)",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={picture}
          />
        </Card>
      </Card>
    </>
  );
};

export default UserProfilePic;
