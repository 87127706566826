import { Grid, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { signUpApi } from "../../adapters/user";
import {
  Button,
  Card,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const Signup = () => {
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [refCode, setRefCode] = useState(null);

  useEffect(() => {
    const ref = history.location.pathname.substring(15);
    setRefCode(ref);
  }, [history]);

  const onSubmit = (data) => {
    data.referralCode = refCode;
    signUpApi(data, setLoading, history);
  };

  return (
    <>
      <MainSection>
        <Card flexEnd>
          <LinkText
            to="/auth/login/"
            color={"rgba(21, 20, 57, 0.4)"}
            position={"right"}
            lineheight={"64px"}
            nounderline={"true"}
          >
            Already have an Account? <b style={{ color: "#F2994A" }}>Sign In</b>
          </LinkText>
        </Card>
        <Card bottom={"32px"}>
          <TitleText
            top={"0em"}
            color={"#670150"}
            lineheight={"32px"}
            size={"32px"}
            heavy
          >
            Sign Up to Discover Startup Features
          </TitleText>
        </Card>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              FIRST NAME
            </Text>
            <TextField
              placeholder="Enter Your Firstname"
              variant="outlined"
              type="text"
              {...register("firstName")}
              required
              fullWidth
            />
          </Card>

          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              LAST NAME
            </Text>
            <TextField
              placeholder="Enter Your Lastname"
              variant="outlined"
              type="text"
              {...register("lastName")}
              required
              fullWidth
            />
          </Card>

          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              PHOME NUMBER
            </Text>
            <TextField
              placeholder="Your phone"
              variant="outlined"
              type="text"
              {...register("phoneNumber")}
              required
              fullWidth
            />
          </Card>

          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              EMAIL
            </Text>
            <TextField
              placeholder="Enter Your Email"
              variant="outlined"
              type="email"
              {...register("email")}
              required
              fullWidth
            />
          </Card>

          <Card top={"8px"} bottom={"8px"}>
            <Text lineheight={"32px"} color={"#670150"} size={"14px"}>
              PASSWORD
            </Text>
            <TextField
              placeholder="Your Password"
              variant="outlined"
              type="password"
              {...register("password")}
              minLength={8}
              required
              fullWidth
            />
          </Card>

          <Card top={"32px"}>
            <Grid container spacing={2}>
              {refCode && (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text color={"rgba(21, 20, 57, 1)"}>
                    Note: You are registering using another user's referral
                    code.
                  </Text>
                </Grid>
              )}

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Button
                  type="submit"
                  right={"16px"}
                  background={"#670150"}
                  radius={"100px"}
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Sign Up"}
                </Button>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Text color={"rgba(21, 20, 57, 0.4)"}>
                  By signing up you agree to Our Terms and Privacy Policy
                </Text>
              </Grid>
            </Grid>
          </Card>
        </form>
      </MainSection>
    </>
  );
};

export default Signup;
