import { Card } from "../../../../styles/util/UtilStyles";

export const ViewAShareImage = ({ picture }) => {
  return (
    <>
      <Card height="385px" background="rgba(5,5,5,0.3)">
        <img
          src={picture}
          alt="Shares"
          style={{ width: "100%", height: "100%" }}
        />
      </Card>
    </>
  );
};
