import { Box } from "../../styles/util/icon";
import { Card, Text } from "../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../Utils";

const WithdrawalBalance = ({ appContext }) => {
  const { user } = appContext;
  return (
    <>
      <Card height={"13em"} width={"100%"}>
        <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
          <Box background={"#670150"} radius={"12px"} center />
          <Text
            color={"#2D3748"}
            top={"16px"}
            lineheight={"40px"}
            size={"18px"}
            center
            heavy
          >
            Invested
          </Text>
          <Text size={"14px"} color={"#A0AEC0"} center>
            Balance
          </Text>
          <Text size={"16px"} lineheight={"40px"} center heavy>
            N {getFormattedAmount(user?.amountInvested)}
          </Text>
        </Card>
      </Card>
    </>
  );
};

export default WithdrawalBalance;
