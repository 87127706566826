import { Grid, Modal, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addNewBank, updateBank } from "../../adapters/account";
import {
  Button,
  Card,
  ModalCard,
  Option,
  SelectField,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const AddNewBank = ({ open, handleClose, appContext }) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    addNewBank(data, setLoading, appContext, handleClose);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard radius={"10px"} top={"20vh"} width={"50%"} WidthResponsive>
          <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
            <TitleText bottom={"16px"} fontFamil={"Poppins"}>
              Add New Withdrawal Details
            </TitleText>

            <Text
              color={"#2D3748"}
              top={"16px"}
              fontFamily={"Helvetica"}
              lineheight={"20px"}
              size={"14px"}
              bottom={"16px"}
            >
              <strong>Note:</strong> You must add account that belongs to you.
              Adding any account that does not bear your name may end your
              account being suspended or not able to Withdral.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Bank Name"
                    variant="outlined"
                    type="text"
                    {...register("bankName")}
                    defaultValue={""}
                    required
                    fullWidth
                  />
                  {errors.bankName && <span>This field is required</span>}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Account Name"
                    variant="outlined"
                    type="text"
                    {...register("accountName")}
                    defaultValue={""}
                    required
                    fullWidth
                  />
                  {errors.accountName && <span>This field is required</span>}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Account Number"
                    variant="outlined"
                    type="number"
                    {...register("accountNumber")}
                    defaultValue={""}
                    required
                    fullWidth
                  />
                  {errors.accountNumber && <span>This field is required</span>}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Button
                    type="submit"
                    background={"#670150"}
                    // radius={"15px"}
                    disabled={loading}
                  >
                    {loading ? "Please wait...." : "Proceed"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

const AccountBank = ({ appContext }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [banks, setBanks] = useState([]);

  const [selectedBank, setSelectedBank] = useState({
    accountName: "",
    accountNumber: "",
    bankName: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    updateBank(data, setLoading, appContext);
  };

  const { user } = appContext;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  useEffect(() => {
    if (user) {
      setBanks(user?.banks);
      if (user?.banks.length) {
        setSelectedBank(user?.banks[0]);
      }
    }
  }, [user]);

  const { accountName, accountNumber, bankName } = selectedBank;

  const handleSelectBank = (e) => {
    if (banks.length) {
      setSelectedBank(banks[e.target.value]);
    }
  };

  return (
    <>
      <Card>
        <Card left={"16px"} right={"16px"} top={"16px"} bottom={"16px"}>
          <TitleText bottom={"16px"} fontFamily={"Poppins"}>
            Update Withdrawal Details
          </TitleText>

          <Text
            color={"#2D3748"}
            top={"16px"}
            fontFamily={"Helvetica"}
            lineheight={"20px"}
            size={"14px"}
            bottom={"16px"}
          >
            <strong>Note:</strong> You must add account that belongs to you.
            Adding any account that does not bear your name may end your account
            being suspended or not able to Withdral.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Text
                  color={"#2D3748"}
                  top={"16px"}
                  fontFamily={"Helvetica"}
                  lineheight={"20px"}
                  size={"14px"}
                  bottom={"16px"}
                >
                  Select an Account
                </Text>
                <SelectField
                  label="Bank Name"
                  variant="outlined"
                  type="text"
                  {...register("accountId")}
                  onChange={handleSelectBank}
                  defaultValue={bankName}
                  required
                  fullWidth
                >
                  {banks?.length === 0 && (
                    <Option value={1} key={1}>
                      Account 1
                    </Option>
                  )}
                  {banks.map((bank, pos) => (
                    <Option key={pos} value={pos}>
                      {bank?.accountNumber}
                    </Option>
                  ))}
                </SelectField>
                {errors.bankName && <span>This field is required</span>}
                {banks.length === 0 && (
                  <span style={{ color: "red" }}>
                    You have no bank account added yet.
                  </span>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Bank Name"
                  variant="outlined"
                  type="text"
                  {...register("bankName")}
                  value={bankName}
                  required
                  fullWidth
                />
                {errors.bankName && <span>This field is required</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Account Name"
                  variant="outlined"
                  type="text"
                  {...register("accountName")}
                  value={accountName}
                  required
                  fullWidth
                />
                {errors.accountName && <span>This field is required</span>}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Account Number"
                  variant="outlined"
                  type="number"
                  {...register("accountNumber")}
                  value={accountNumber}
                  required
                  fullWidth
                />
                {errors.accountNumber && <span>This field is required</span>}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 0.1)"}
                  color={"#670150"}
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Update Bank"}
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Button
                  type="submit"
                  background={"rgba(103, 1, 80, 1)"}
                  color={"white"}
                  // disabled={loading}
                  onClick={handleOpen}
                >
                  {loading ? "Please wait..." : "Add New Bank"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Card>

      <AddNewBank
        open={open}
        handleClose={handleClose}
        appContext={appContext}
      />
    </>
  );
};

export default AccountBank;
