import { useContext, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";

import { AdminStatCard, DashboardCard } from "../../components/admin";
import UserLayout from "../../components/userLayout/UserLayout";
import { MainSection } from "../../styles/util/UtilStyles";

import { AppContext } from "../../App";
import { getStatData } from "../../adapters/admin";
import {
  MonetizationOnOutlined,
  SupervisedUserCircleOutlined,
} from "@material-ui/icons";
import { getFormattedAmount } from "../../Utils";

// const adminStats = [
//   {
//     icon: <PersonOutlineOutlinedIcon />,
//     title: "Total User",
//     count: "0",
//     color: "white",
//     bgColor: "#670150",
//   },
//   {
//     icon: <NoteOutlinedIcon />,
//     title: "Total User",
//     count: "0",
//     color: "white",
//     bgColor: "#FFB648",
//   },
//   {
//     icon: <MailOutlinedIcon />,
//     title: "Total User",
//     count: "0",
//     color: "white",
//     bgColor: "#5F2EEA",
//   },
//   {
//     icon: <SupervisorAccountIcon />,
//     title: "Total User",
//     count: "0",
//     color: "white",
//     bgColor: "#4BDE97",
//   },
// ];

const AdminDashboardPage = () => {
  const [statData, setStatData] = useState({});
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);

  const [adminStat, setAdminStat] = useState([]);

  useEffect(() => {
    getStatData(setLoading, setStatData, appContext);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if(!loading){
    setAdminStat([
      {
        icon: <PersonOutlineOutlinedIcon />,
        title: "Total Customers",
        count: statData?.usersCount,
        color: "white",
        bgColor: "#670150",
      },
      
      {
        icon: <SupervisedUserCircleOutlined />,
        title: "Total Managers",
        count: statData?.managersCount,
        color: "white",
        bgColor: "#FFB648",
      },
      {
        icon: <MonetizationOnOutlined />,
        title: "Total Plans",
        count: statData?.investmentsCount,
        color: "white",
        bgColor: "#5F2EEA",
      },
      {
        icon: <MonetizationOnOutlined />,
        title: "Total Amount Invested",
        count: `N${getFormattedAmount(statData?.totalInvested)}`,
        color: "white",
        bgColor: "#670150",
      },

      {
        icon: <MonetizationOnOutlined />,
        title: "Total Wallet Balance",
        count: `N${getFormattedAmount(statData?.totalWalletBalance)}`,
        color: "white",
        bgColor: "#FFB648",
      },
      {
        icon: <MonetizationOnOutlined />,
        title: "Total Withdrawals",
        count: `N${getFormattedAmount(statData?.totalWithdrawals)}`,
        color: "white",
        bgColor: "#4BDE97",
      },
      {
        icon: <MonetizationOnOutlined />,
        title: "Total Deposits",
        count: `N${getFormattedAmount(statData?.totalDeposits)}`,
        color: "white",
        bgColor: "#5F2EEA",
      },
    ]);
    // }
    console.log(loading);
    // eslint-disable-next-line
  }, [statData]);

  return (
    <>
      <UserLayout pageTitle={"Admin Dashboard"}>
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            {adminStat.map((item, pos) => (
              <Grid key={pos} item lg={3} md={4} sm={12} xs={6}>
                <DashboardCard {...item} />
              </Grid>
            ))}

            <Grid key={"stat"} item lg={12} md={12} sm={12} xs={12}>
              <AdminStatCard />
            </Grid>
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default AdminDashboardPage;
