import { useContext, useEffect, useState } from "react";
import { getAdminSharesPlan } from "../../../adapters/admin";
import { STATUSES } from "../../../adapters/utility/enums";
import { AppContext } from "../../../App";
import {
  AdminViewAllSharesBody,
  LoadingAdminViewAllShares,
} from "../../../components/admin/shares/AdminViewAllShares";
import CreateShareButton from "../../../components/admin/shares/common/AdminShareButtons/CreateShareButton";

import UserLayout from "../../../components/userLayout/UserLayout";
import { NotFoundError } from "../../error";

const AdminViewAllSharesPage = () => {
  const appContext = useContext(AppContext);

  const { LOADING, DATA, NULL_DATA, ERROR } = STATUSES;
  const [status, setStatus] = useState(DATA);
  const [sharesList, setSharesList] = useState([]);
  // const[pageNo,setPageNo]=useState(1);

  useEffect(() => {
    getAdminSharesPlan({}, setStatus, appContext, setSharesList);
    // eslint-disable-next-line
  }, [appContext]);

  const renderBaseOnStatus = () => {
    switch (status) {
      case LOADING:
        return <LoadingAdminViewAllShares />;
      case DATA:
        return <AdminViewAllSharesBody sharesList={sharesList} />;
      case NULL_DATA:
        return (
          <NotFoundError
            errorTitle={"No Shares Added Yet!"}
            errorText="Check back later"
          />
        );
      case ERROR:
        return <NotFoundError errorTitle={"Page Not Found"} />;
      default:
        return "";
    }
  };

  return (
    <>
      <UserLayout
        pageTitle={"Shares Plan"}
        pageTitleComponent={<CreateShareButton />}
      >
        {renderBaseOnStatus()}
      </UserLayout>
    </>
  );
};

export default AdminViewAllSharesPage;
