import Carousel from "react-grid-carousel";
import { carouselDots } from "../../../../../styles/util/UtilStyles";
import { SharesCard } from "../../common/SharesCard";

export const ApprovedShares = ({ sharesList = [] }) => {
  return (
    <>
      <Carousel
        cols={3}
        rows={1}
        gap={30}
        dot={carouselDots}
        loop
        autoplay={2000}
        style={{ width: "100%" }}
        showDots
      >
        {sharesList.map((share, key) => (
          <Carousel.Item key={key}>
            <SharesCard {...share} />
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};
