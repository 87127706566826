// import { useState } from "react";

import { Box } from "../../../../styles/util/icon";

import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Label,
  Text,
  TitleText,
} from "../../../../styles/util/UtilStyles";
import { getFormattedAmount, truncateText } from "../../../../Utils";

export const SharesCard = ({
  title,
  amount,
  description,
  picture,
  _id,
  status,
  minimum,
  amountRemaining,
  handleOpen,
  hasBidButton,
  hasUnbidButton,
  bidAmount,
  isBiddedPlan,
}) => {
  const history = useHistory();

  return (
    <>
      <Card>
        <Card left={"16px"} top={"16px"} right={"16px"} bottom={"16px"}>
          <Card flex spaceBetween>
            <Box radius={"10px"} width={"5em"} height={"5em"}>
              <img
                src={picture}
                alt={title}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            {isBiddedPlan && (
              <Card width="fit-content">
                <Text
                  size={"12px"}
                  fontFamily={"Poppins"}
                  left={"auto"}
                  bottom="8px"
                  heavy
                >
                  My Bid Price
                </Text>
                <Label
                  background={"white"}
                  width={"8em"}
                  position={"center"}
                  border={"1px solid rgba(103, 1, 80, 0.1)"}
                >
                  <Text
                    size={"12px"}
                    color={"#670150"}
                    fontFamily={"Poppins"}
                    left={"auto"}
                    right={"auto"}
                    heavy
                  >
                    N {getFormattedAmount(bidAmount)}
                  </Text>
                </Label>
              </Card>
            )}
          </Card>
          <TitleText
            top={"16px"}
            color={"#000000"}
            size={"20px"}
            lineheight={"30px"}
            fontFamily={"Poppins"}
            heavy
          >
            {title}
          </TitleText>
          <Text
            top={"8px"}
            fontFamily={"Poppins"}
            size={"14px"}
            lineheight={"21px"}
            color={"rgba(0, 0, 0, 0.8)"}
          >
            {truncateText(description, 5)}
          </Text>
          <Card top={"16px"} flex spaceBetween>
            <Label
              background={"rgba(103, 1, 80, 0.1)"}
              width={"8em"}
              position={"center"}
            >
              <Text
                size={"12px"}
                color={"#670150"}
                fontFamily={"Poppins"}
                left={"auto"}
                right={"auto"}
              >
                {status}
              </Text>
            </Label>
            <Label
              background={"rgba(103, 1, 80, 0.1)"}
              width={"8em"}
              position={"center"}
            >
              <Text
                size={"12px"}
                color={"#670150"}
                fontFamily={"Poppins"}
                left={"auto"}
                right={"auto"}
              >
                N {getFormattedAmount(amount)}
              </Text>
            </Label>
          </Card>

          <Card top={"32px"} flex spaceBetween>
            <>
              {status === "Pending" && (
                <>
                  {hasBidButton && (
                    <Button
                      radius={"5px"}
                      background={"white"}
                      width={"8em"}
                      border="1px solid #670150"
                      color="#670150"
                      onClick={() =>
                        handleOpen({
                          title,
                          amount,
                          description,
                          picture,
                          _id,
                          status,
                          minimum,
                          amountRemaining,
                        })
                      }
                    >
                      BID NOW
                    </Button>
                  )}
                </>
              )}
            </>

            {hasUnbidButton && (
              <Button
                radius={"5px"}
                background={"white"}
                width={"8em"}
                border="1px solid #670150"
                color="#670150"
                onClick={() =>
                  handleOpen({
                    title,
                    amount,
                    description,
                    picture,
                    _id,
                    status,
                    minimum,
                    amountRemaining,
                    bidAmount,
                  })
                }
              >
                UNBID NOW
              </Button>
            )}

            <Button
              radius={"5px"}
              background={"#670150"}
              color={"white"}
              width={"8em"}
              onClick={() => history.push(`/user/market/view-shares/${_id}`)}
            >
              VIEW PLAN
            </Button>
          </Card>
        </Card>
      </Card>
    </>
  );
};
