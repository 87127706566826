import { Grid, Modal, TextField } from "@material-ui/core";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { depositWallet } from "../../adapters/account";
// import { fundWallet } from "../../adapters/account";
// import { initiatePayConfig } from "../../adapters/utility/Util";

import {
  Button,
  Card,
  ModalCard,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import { getFormattedAmount } from "../../Utils";

const DepositModal = ({ open, handleClose, appContext }) => {
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const [step, setStep] = useState(1);

  const [payInfo, setPayInfo] = useState({
    amount: "0",
    description: "",
  });

  const updatePayInfo = (key, val) => {
    if (payInfo.amount.length && payInfo?.description?.length) {
      // console.log("got that",payInfo.amount.length,payInfo?.description?.length);
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }

    setPayInfo({
      ...payInfo,
      [key]: val,
    });
  };

  // const handleFlutterPayment = useFlutterwave(
  //   initiatePayConfig(
  //     payInfo?.amount,
  //     email,
  //     phoneNumber,
  //     firstName,
  //     "Fund My Wallet.",
  //     payInfo?.description
  //   )
  // );

  // const handleFlutterPayment2 = () => {
  //   if (payInfo?.amount && payInfo?.description) {
  //     handleFlutterPayment({
  //       callback: (response) => {
  //         let params = {
  //           transactionID: response?.transaction_id.toString(),
  //         };
  //         // if (response?.status !== "successful") {
  //         //   return toast.error("Payment failed to process.", {
  //         //     position: "top-right",
  //         //     autoClose: 5000,
  //         //     hideProgressBar: false,
  //         //     closeOnClick: true,
  //         //     pauseOnHover: true,
  //         //     draggable: true,
  //         //     progress: undefined,
  //         //   });
  //         // }
  //         closePaymentModal(); // this will close the modal programmatically
  //         fundWallet(params, setLoading, appContext, handleClose);
  //       },
  //       onClose: () => {
  //         console.log("you closed me");
  //       },
  //     });
  //   } else {
  //     return toast.warning(
  //       "Please, ensure you enter an amount and description.",
  //       {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       }
  //     );
  //   }
  // };

  const nextStep = (stepNo) => {
    setStep(stepNo);
  };

  // const handleSuccessPayment = () => {
  //   setLoading(true);

  //   handleClose();
  //   return toast.error(
  //     "Thanks for making the payment. An admin will check and credit your account when received.",
  //     {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     }
  //   );
  // };

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.amount = Number.parseInt(payInfo?.amount);
    data.description = payInfo.description;
    depositWallet(data, setLoading, appContext, handleClose);
  };

  const handleStep = () => {
    let currentView = null;
    if (step === 1) {
      currentView = (
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TitleText color={"#0F2B3D"} size={"18px"} heavy>
              Deposit Money
            </TitleText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                  Amount
                </Text>
                <TextField
                  onChange={(e) => updatePayInfo("amount", e.target.value)}
                  id="outlined-required"
                  label="Enter Amount"
                  variant="outlined"
                  type="number"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                  Description
                </Text>
                <TextField
                  onChange={(e) => updatePayInfo("description", e.target.value)}
                  id="outlined-required"
                  label="Deposit description."
                  variant="outlined"
                  type="text"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TitleText color={"#0F2B3D"} size={"18px"} heavy>
              Please Note
            </TitleText>
            <Text bottom={"20px"} color={"rgba(57, 57, 57, 0.5)"} size={"14px"}>
              {/* You will be required to choose a payment method upon clicking
      the button below. Kindly ensure you do not close the payment
      modal until the payment is successful. */}
              You will be required to make the payment as transfer upon clicking
              the button below. Kindly ensure the amount entered is correct.
            </Text>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  // onClick={handleFlutterPayment2}
                  onClick={() => nextStep(2)}
                  width={"12em"}
                  background={!disableBtn ? "rgba(5,5,5,0.3)" : "#670150"}
                  radius={"15px"}
                  disabled={!disableBtn}
                >
                  {loading ? "Please wait...." : "Proceed"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (step === 2) {
      currentView = (
        <Grid container spacing={2}>
          <Grid item lg={12} md={6} sm={6} xs={12}>
            <TitleText color={"#0F2B3D"} size={"18px"} bottom={"16px"} heavy>
              Deposit Money
            </TitleText>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={12} xs={6}>
                <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                  ACCOUNT NUMBER
                </Text>
                <Text
                  bottom={"12px"}
                  color={"rgba(57, 57, 57, 0.5)"}
                  size={"14px"}
                >
                  2001045239
                </Text>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={6}>
                <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                  ACCOUNT NAME
                </Text>
                <Text
                  bottom={"12px"}
                  color={"rgba(57, 57, 57, 0.5)"}
                  size={"14px"}
                >
                  VIKKYMORE ENTERPRISES
                </Text>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={6}>
                <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                  BANK NAME
                </Text>
                <Text
                  bottom={"12px"}
                  color={"rgba(57, 57, 57, 0.5)"}
                  size={"14px"}
                >
                  Globus bank
                </Text>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={6}>
                <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                  AMOUNT TO PAY
                </Text>
                <Text
                  bottom={"12px"}
                  color={"rgba(57, 57, 57, 0.5)"}
                  size={"14px"}
                  heavy
                >
                  N{getFormattedAmount(payInfo.amount)}
                </Text>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} md={6} sm={6} xs={12}>
            <TitleText color={"#0F2B3D"} size={"18px"} heavy>
              Please Note
            </TitleText>
            <Text bottom={"20px"} color={"rgba(57, 57, 57, 0.5)"} size={"14px"}>
              {/* You will be required to choose a payment method upon clicking
          the button below. Kindly ensure you do not close the payment
          modal until the payment is successful. */}
              Please, kindly make the payment of the sum of money into the bank
              account details above and click submit when done.
              <br />
              An admin will receive the notification and credit your account
              once the payment is received.
            </Text>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  onClick={() => nextStep(3)}
                  width={"12em"}
                  background={"#670150"}
                  radius={"15px"}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (step === 3) {
      currentView = (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TitleText color={"#0F2B3D"} size={"18px"} heavy>
                Payment Bank Details
              </TitleText>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                    Account Number
                  </Text>
                  <TextField
                    {...register("accountNumber")}
                    id="outlined-required"
                    label="Enter Your Account Number"
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                    Account Name
                  </Text>
                  <TextField
                    {...register("accountName")}
                    id="outlined-required"
                    label="Enter Your Account Name"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Text color={"#393939"} size={"14px"} bottom={"16px"}>
                    Bank Name
                  </Text>
                  <TextField
                    {...register("bankName")}
                    id="outlined-required"
                    label="Enter Your Bank Name"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TitleText color={"#0F2B3D"} size={"18px"} heavy>
                Please Note
              </TitleText>
              <Text
                bottom={"20px"}
                color={"rgba(57, 57, 57, 0.5)"}
                size={"14px"}
              >
                These infor will be used to confirm the actual payment came from
                you.
              </Text>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    type="submit"
                    width={"12em"}
                    background={loading ? "rgba(5,5,5,0.3)" : "#670150"}
                    radius={"15px"}
                    disabled={loading}
                  >
                    {loading ? "Please wait...." : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      );
    }

    return currentView;
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalCard radius={"10px"} top={"20vh"} width={"50%"} WidthResponsive>
          <Card top={"16px"} bottom={"32px"} right={"16px"} left={"16px"}>
            {handleStep()}
          </Card>
        </ModalCard>
      </Modal>
    </>
  );
};

export default DepositModal;
