import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../App";
import UserLayout from "../../components/userLayout/UserLayout";
import { AccountBalanceCard, AccountBank } from "../../components/wallet";
import { GoBack, MainSection } from "../../styles/util/UtilStyles";

const ViewBankPage = () => {
  const appContext = useContext(AppContext);

  return (
    <>
      <UserLayout
        pageTitle={"View Withdrawal Account"}
        pageTitleComponent={<GoBack />}
      >
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <AccountBalanceCard appContext={appContext} />
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <AccountBank appContext={appContext} />
            </Grid>
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewBankPage;
