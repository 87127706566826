import { Grid } from "@material-ui/core";
import {
  Button,
  Card,
  MainSection,
  Text,
  TextAreaField,
  TextField,
  TitleText,
} from "../../styles/util/UtilStyles";


// import bgImage from "../../assets/img/home/Rectangle 340.png";


import Map from "../../assets/img/home/Map.png";

const HomeContactUs = () => {
  return (
    <>
      <Card height={"fit-content"} background={"#EAEAEE"}>
        <MainSection top={"64px"} bottom={"64px"}>
          <Card height={"100%"}>
            {/* <Card
            width={"100%"}
            background={"red"}
            height={"10em"}
            className={'curved'}
          ></Card> */}

            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Card height={"100%"} background={`linear-gradient(120deg,rgba(5,5,5,0.8),rgba(5,5,5,0.7)), url(${Map})`}>
                  <Card background={"transparent"} left="24px" right={"24px"} top={"16px"} bottom={"32px"}>
                    <TitleText
                      color={"white"}
                      size={"40px"}
                      lineheight={"60px"}
                      fontFamily={"Mulish"}
                      top={"1em"}
                      heavy
                    >
                      Hi, you can get in touch with us here!
                    </TitleText>
                    <Text
                      size={"16px"}
                      top={"16px"}
                      color={"rgba(255,255,255,0.7)"}
                      lineheight={"24px"}
                    >
                      If you have any questions or any bothering you, don’t
                      hesitate to SEND US A MESSAGE!
                    </Text>
                  </Card>
                </Card>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Card
                  height={"100%"}
                  shadow
                  radius={0.2}
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="500"
                >
                  {/* <Grid grid spacing={1} cardWidth={"90%"} top={"5em"} left={"1em"} right={"1em"}>
          <Grid lg={12} md={6} sm={6} xs={12}>
            <TextField placeholder="Your Full Name*" />
          </Grid>
          <Grid lg={6} md={6} sm={6} xs={12}>
            <TextField placeholder="Your Email Address*" />
          </Grid>
          <Grid lg={6} md={6} sm={6} xs={12}>
            <TextFieldi placeholder="Your Email Address*" />
          </Grid>
        </Grid> */}

                  <div
                    style={{
                      marginTop: "2em",
                      marginLeft: "1em",
                      marginRight: "1em",
                      marginBottom: "2em",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField placeholder="Your Full Name*" />
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField placeholder="Your Email Address*" />
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField placeholder="Phone No*" />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextAreaField placeholder="Your Message*" />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Button background={"#F2911B"}>Submit</Button>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </MainSection>
      </Card>
    </>
  );
};

export default HomeContactUs;
