import { Grid } from "@material-ui/core";
import { NotFoundError } from "../../../../../../pages/error";
import { TitleText } from "../../../../../../styles/util/UtilStyles";
import AdminViewBidCard from "./AdminViewBidCard";

const AdminViewBidders = ({
  bids = [],
  appContext,
  hasTitle,
  title = "Share Bids",
  errorText = "No User Bids Yet!",
}) => {
  return (
    <>
      {hasTitle && <TitleText bottom="32px">{title}</TitleText>}
      {bids?.length > 0 ? (
        <Grid container spacing={4}>
          {bids.map((bid, key) => (
            <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
              <AdminViewBidCard {...bid} appContext={appContext} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NotFoundError errorTitle={errorText} />
      )}
    </>
  );
};

export default AdminViewBidders;
