import { Divider } from "@material-ui/core";
import { Card, Text, TitleText } from "../../styles/util/UtilStyles";

const YourReferralEarningCard = ({ user }) => {
  return (
    <>
      <Card radius={"10px"}>
        <Card top={"16px"} bottom={"0px"} right={"16px"} left={"16px"}>
          <TitleText bottom={"16px"} fontFamil={"Poppins"}>
            Your Referral Earning
          </TitleText>
        </Card>
        <Divider />
        <Card top={"16px"} bottom={"16px"} right={"16px"} left={"16px"}>
          <TitleText
            bottom={"0px"}
            size={"24px"}
            fontFamil={"Poppins"}
            heavy
            center
          >
            NGN {user?.referralEarning}
          </TitleText>
          <Text
            bottom={"16px"}
            color={"rgba(0, 0, 0, 0.4)"}
            size={"12px"}
            center
          >
            Total earning on your 0 referrals
          </Text>
          {/* <Button width={"15em"} bottom={"16px"} center radius={"10px"} background={"#670150"} color={"white"} top={"16px"}>Transfer</Button> */}
        </Card>
      </Card>
    </>
  );
};

export default YourReferralEarningCard;
