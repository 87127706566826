import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getAUserInfoApi } from "../../adapters/user";
import { AppContext } from "../../App";
import { UserBasicDetails, UserProfilePic } from "../../components/general";
import UserLayout from "../../components/userLayout/UserLayout";
import { GoBack, MainSection } from "../../styles/util/UtilStyles";

const ViewManagerUserProfilePage = () => {
  const appContext = useContext(AppContext);

  const [userDetails, setUserDetails] = useState({});
  const history = useHistory();
  const [userId, setUserId] = useState(history.location.pathname.substr(22));

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setUserId(history.location.pathname.substr(22));
    getAUserInfoApi(userId, setUserDetails, setFetching, appContext);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <UserLayout
        pageTitle={"User Profile"}
        pageTitleComponent={<GoBack url={`/admin/users/`} />}
      >
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <UserProfilePic picture={userDetails?.picture} />
            </Grid>
            
            <Grid item lg={8} md={4} sm={12} xs={12}>
              <UserBasicDetails
                fetching={fetching}
                appContext={appContext}
                setUserDetails={setUserDetails}
                userDetails={userDetails}
                userId={userId}
                managedByMe
              />
            </Grid>
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewManagerUserProfilePage;
