import { useContext, useEffect, useRef, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import UserLayout from "../../components/userLayout/UserLayout";
import {
  Button,
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import { getBase64 } from "../../adapters/utility/Util";
import { createInvestPlan, getInvestInfoAsAdmin } from "../../adapters/admin";
import { AppContext } from "../../App";
import { useHistory } from "react-router";
import { VisibilityOutlined } from "@material-ui/icons";

const ViewInvestPlans = () => {
  const history = useHistory();
  return (
    <>
      <Button
        background={"#670150"}
        width={"10em"}
        radius={"5px"}
        left={"auto"}
        onClick={() => history.push("/investment/")}
      >
        <Card background={"transparent"} left={"auto"} right={"auto"} flex>
          <VisibilityOutlined />
          <Text left={"4px"} top={"5px"} color={"white"} size={"12px"}>
            View Plan
          </Text>
        </Card>
      </Button>
    </>
  );
};

const AdminEditInvestPlanPage = () => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const appContext = useContext(AppContext);

  const inputFile = useRef(null);

  const openFileUpload = () => {
    inputFile.current.click();
  };

  const handleUpdatePic = (e) => {
    if (e?.target?.value?.length) {
      getBase64(e.target.files[0]).then((data) => setImage(data));
    }
  };

  const history = useHistory();

  const onSubmit = (data) => {
    data.picture = image;
    console.log(data);
    createInvestPlan(data, setLoading, appContext, history);
  };

  const [planInfo, setPlanInfo] = useState({});

  useEffect(() => {
    let params = {
      investmentID: "6193ea94b0fff31102b9328f",
    };
    console.log("url", planInfo);
    getInvestInfoAsAdmin(params, setLoading, setPlanInfo, appContext);
        // eslint-disable-next-line
  }, [appContext]);

  return (
    <>
      <UserLayout
        pageTitle={"Update Investment Plan"}
        pageTitleComponent={<ViewInvestPlans />}
      >
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Card>
                <TitleText top={"16px"} left={"16px"}>
                  Investment Plan Image
                </TitleText>
                <Card
                  height={"15em"}
                  top={"16px"}
                  bottom={"16px"}
                  left={"16px"}
                  right={"16px"}
                  background={"rgba(5,5,5,0.1)"}
                >
                  <img
                    src={image}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </Card>
                <Card top={"16px"} bottom={"16px"} left={"16px"} right={"16px"}>
                  <Button
                    top={"16px"}
                    bottom={"16px"}
                    background={"#670150"}
                    color={"white"}
                    radius={"5px"}
                    onClick={openFileUpload}
                  >
                    Select Image
                  </Button>
                  <input
                    onChange={handleUpdatePic}
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    accept=".jpg,.png,.bmp"
                  />
                </Card>
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Card>
                <TitleText top={"16px"} left={"16px"}>
                  Investment Plan Details
                </TitleText>
                <Card top={"16px"} bottom={"16px"} left={"16px"} right={"16px"}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <TextField
                          label="Title of Investment Plan"
                          variant="outlined"
                          type="text"
                          {...register("title")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <TextField
                          label="Amount"
                          variant="outlined"
                          type="number"
                          {...register("amount")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <TextField
                          label="Interest"
                          variant="outlined"
                          type="number"
                          {...register("interest")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <TextField
                          label="Duration of Investment"
                          variant="outlined"
                          type="number"
                          {...register("duration")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <TextField
                          label="Minium Amount."
                          variant="outlined"
                          type="number"
                          {...register("minimum")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} sm={12} md={12} xs={12}>
                        <TextField
                          label="Description of Investment Plan"
                          variant="outlined"
                          type="text"
                          {...register("description")}
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          type="submit"
                          background={"rgba(103, 1, 80, 0.1)"}
                          color={"#670150"}
                          disabled={loading}
                        >
                          {loading ? "Please wait..." : "Create Plan"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Card>
              </Card>
            </Grid>
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default AdminEditInvestPlanPage;
