import { Grid } from "@material-ui/core";
import { MoneyOutlined } from "@material-ui/icons";
import { useContext } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../App";
import UserLayout from "../../components/userLayout/UserLayout";
import {
  AccountBalanceCard,
  AccountCTA,
  ActualBalance,
  // InvoiceCard,
  TransactionsCard,
  WithdrawalBalance,
} from "../../components/wallet";
import {
  Button,
  Card,
  MainSection,
  Text,
  Label,
  LinkText,
} from "../../styles/util/UtilStyles";

const WithdrawalDetails = () => {
  const history = useHistory();
  return (
    <>
      <Button
        background={"#670150"}
        width={"10em"}
        radius={"5px"}
        left={"auto"}
        onClick={() => history.push("/user/account/view/bank/")}
      >
        <Card background={"transparent"} left={"auto"} right={"auto"} flex>
          <MoneyOutlined />
          <Text left={"4px"} top={"5px"} color={"white"} size={"12px"}>
            Account Details
          </Text>
        </Card>
      </Button>
    </>
  );
};

const ViewAccountPage = () => {
  const appContext = useContext(AppContext);
  return (
    <>
      <UserLayout
        pageTitle={"View Account"}
        pageTitleComponent={<WithdrawalDetails />}
      >
        {/* --- Referral Cards section here ---- */}
        <MainSection width={"100%"} top={"2em"}>
          <Grid container spacing={4}>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={12} sm={6} xs={12}>
                  <AccountBalanceCard appContext={appContext} />
                </Grid>

                <Grid item lg={3} md={6} sm={3} xs={6}>
                  <ActualBalance appContext={appContext} />
                </Grid>
                <Grid item lg={3} md={6} sm={3} xs={6}>
                  <WithdrawalBalance appContext={appContext} />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <AccountCTA appContext={appContext} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xs={12}>
              {/* <InvoiceCard appContext={appContext} /> */}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card bottom={"16px"} background={"transparent"}>
                <Label
                  border={"1px solid #670150"}
                  width={"10em"}
                  background={"white"}
                  left={"auto"}
                >
                  <LinkText
                    to={"/user/transactions/"}
                    nounderline={"true"}
                    color={"#670150"}
                    center
                  >
                    View All
                  </LinkText>
                </Label>
              </Card>
              <TransactionsCard appContext={appContext} />
            </Grid>
          </Grid>
        </MainSection>
      </UserLayout>
    </>
  );
};

export default ViewAccountPage;
