import { Grid } from "@material-ui/core";
import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { useHistory } from "react-router";
import { cancelInvestment } from "../../adapters/investments";
import SkeletonCard from "../../adapters/utility/SkeletonCard";

import {
  Button,
  Card,
  Label,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import {
  getFormattedAmount,
  getPercentageRate,
  getPercentageTime,
  getTimeAgo,
} from "../../Utils";
import InvestAskAmountModal from "./InvestAskAmountModal";

const PlanBoxes = ({ title, text }) => {
  return (
    <>
      <Card
        left={"0.5px"}
        right={"0.5px"}
        height={"4.5em"}
        width={"100%"}
        borderColor={"1px double #D2D2D2"}
      >
        <Text
          size={"16px"}
          color={"rgba(0, 0, 0, 0.4)"}
          fontFamily={"Poppins"}
          top={"6px"}
          center
        >
          {title}
        </Text>
        <Text
          lineheight={"27px"}
          size={"18px"}
          color={"#000000"}
          fontFamily={"Poppins"}
          heavy
          center
        >
          {text}
        </Text>
      </Card>
    </>
  );
};

const InvestmentOverview = ({ appContext, planInfo }) => {
  const { user } = appContext;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    title,
    amount,
    amountRemaining,
    duration,
    interest,
    minimum,
    description,
    status,
    addedDate,
    investors,
    _id,
    invested,
    startDate,
    // transactions,
  } = planInfo;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    const params = { investmentID: _id };
    cancelInvestment(params, setLoading, appContext, history);
  };

  return (
    <>
      <Card bottom={"32px"}>
        <Card top={"16px"} bottom={"16px"} left={"16px"} right={"16px"}>
          {!title ? (
            <>
              <SkeletonCard loading={true} />
              <SkeletonCard loading={true} />
            </>
          ) : (
            <Grid container spacing={4}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TitleText
                  color={"#000000"}
                  size={"20px"}
                  lineheight={"30px"}
                  fontFamily={"Poppins"}
                  style={{ textTransform: "uppercase" }}
                  heavy
                >
                  {title}
                </TitleText>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Label
                  style={{ display: "block" }}
                  position={"center"}
                  background={"white"}
                  border={"1px solid #670150"}
                  radius={"8px"}
                >
                  <Text
                    color={"#670150"}
                    size={"16px"}
                    top={"8px"}
                    fontFamily={"Poppins"}
                    center
                    heavy
                  >
                    {status}
                  </Text>
                </Label>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Card left={"auto"} width={"fit-content"} flex>
                  <Text
                    fontFamily={"Poppins"}
                    lineheight={"21px"}
                    size={"14px"}
                    color={"rgba(0, 0, 0, 0.4)"}
                  >
                    {/* Added 8 Days ago */}

                    {getTimeAgo(addedDate)}
                  </Text>
                  <div>
                    <Text
                      left={"16px"}
                      color={"#670150"}
                      size={"14px"}
                      lineheight={"21px"}
                    >
                      {investors} Investor(s)
                    </Text>
                  </div>
                </Card>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* <Card flex> */}
                <Grid container spacing={1}>
                  <Grid item lg={3} md={4} sm={6} xs={6}>
                    <PlanBoxes title={"Percentage"} text={`${interest} %`} />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={6}>
                    <PlanBoxes title={"Minimum"} text={`N ${minimum}`} />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={6}>
                    <PlanBoxes title={"Duration"} text={`${duration} day(s)`} />
                  </Grid>
                  <Grid item lg={3} md={4} sm={6} xs={6}>
                    <PlanBoxes
                      title={"Total"}
                      text={`N ${getFormattedAmount(amount)}`}
                    />
                  </Grid>
                </Grid>
                {/* </Card> */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TitleText color={"#000000"} size={"18px"} lineheight={"27px"}>
                  Overview
                </TitleText>
                <Text
                  color={"rgba(0, 0, 0, 0.4)"}
                  size={"14px"}
                  fontFamily={"Poppins"}
                  lineheight={"21px"}
                  top={"16px"}
                >
                  {description}
                </Text>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TitleText
                  bottom={"16px"}
                  color={"#000000"}
                  size={"16px"}
                  lineheight={"27px"}
                >
                  Investment Completion
                </TitleText>
                <ProgressBar
                  height={"1em"}
                  completed={100 - getPercentageRate(amountRemaining, amount)}
                  maxCompleted={100}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TitleText
                  bottom={"16px"}
                  color={"#000000"}
                  size={"16px"}
                  lineheight={"27px"}
                >
                  Investment Progress
                </TitleText>
                <ProgressBar
                  height={"1em"}
                  completed={getPercentageTime(startDate, duration)}
                  maxCompleted={100}
                />
              </Grid>

              {/* --- Only set this functionality permissions on Admins only ---- */}
              {user?.userType === "Admin" ? (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    {status === "Pending" && (
                      <Button
                        background={"rgba(200,20,20,0.8)"}
                        color={"white"}
                        top={"1em"}
                        left={"auto"}
                        radius={"5px"}
                      >
                        Cancel Plan
                      </Button>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Button
                      top={"1em"}
                      radius={"5px"}
                      background={"#670150"}
                      color={"white"}
                      onClick={() => history.push(`/admin/investments/edit/`)}
                    >
                      Edit Plan
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  {user?.userType !== "Manager" && (
                    <>
                      {amountRemaining ? (
                        <>
                          {!invested && (
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                              <Button
                                radius={"5px"}
                                background={"rgba(5,5,5,0.1)"}
                                color={"#670150"}
                                onClick={handleCancel}
                                disabled={loading}
                              >
                                {loading
                                  ? "Please wait..."
                                  : "Cancel Investment"}
                              </Button>
                            </Grid>
                          )}
                          <Grid
                            item
                            lg={!invested ? 6 : 12}
                            md={!invested ? 6 : 12}
                            sm={!invested ? 6 : 12}
                            xs={!invested ? 6 : 12}
                          >
                            <Button
                              radius={"5px"}
                              background={"#670150"}
                              color={"white"}
                              onClick={handleOpen}
                            >
                              Invest Now
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Button
                            radius={"5px"}
                            background={"rgba(5,5,5,0.1)"}
                            color={"#670150"}
                            disabled
                          >
                            {status}
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          )}
        </Card>
      </Card>

      <InvestAskAmountModal
        open={open}
        handleClose={handleClose}
        investDetails={planInfo}
        appContext={appContext}
      />
    </>
  );
};

export default InvestmentOverview;
