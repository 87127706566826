import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { updateSharePlan } from "../../../../adapters/admin";
import { getBase64 } from "../../../../adapters/utility/Util";
import AdminCreateSharesBody from "../AdminCreateShares/AdminCreateSharesBody/AdminCreateSharesBody";

const AdminEditSharesBody = ({
  appContext,
  title,
  description,
  status,
  addedDate,
  amount,
  bids,
  interest,
  minimum,
  maxAmount,
  duration,
  amountRemaining,
  picture,
  _id,
}) => {
  const [editShareData, setEditShareData] = useState({});

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(picture);
  const inputFile = useRef(null);

  const openFileUpload = () => {
    inputFile.current.click();
  };

  const handleUpdatePic = (e) => {
    if (e?.target?.value?.length) {
      getBase64(e.target.files[0]).then((data) => setImage(data));
    }
  };

  const onSubmit = (data) => {
    data.picture = image;
    data._id = _id;
    updateSharePlan(data, setLoading, appContext);
  };

  useEffect(() => {
    setEditShareData({
      appContext,
      title,
      description,
      status,
      addedDate,
      amount,
      bids,
      interest,
      minimum,
      maxAmount,
      duration,
      amountRemaining,
      picture,
      _id,
    });
    setImage(picture);
    // eslint-disable-next-line
  }, [picture]);

  return (
    <>
      {editShareData?._id && (
        <AdminCreateSharesBody
          {...{
            onSubmit,
            handleUpdatePic,
            openFileUpload,
            inputFile,
            loading,
            register,
            image,
            handleSubmit,
            isEdit: true,
            editShareData,
          }}
        />
      )}
    </>
  );
};

export default AdminEditSharesBody;
