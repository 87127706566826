import { Avatar, Grid } from "@material-ui/core";
import { Box } from "../../styles/util/icon";
import {
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const testimonialList = [
  {
    title: "NO REGRETS WITH VIMEVEST",
    text: `Vimevest is a very good and reliable platform. I've invested on the platform for more than 3 years and there have been no regrets.`,
    name: "MISS CLARA ILOH",
  },
  {
    title: "A PLATFORM WORTHY OF REFERRAL",
    text: `It takes trust to refer! Vimevest has never failed me and as a result, I have been referring my family and friends to the platform. More than 2 years on a spin and the platform has proven to be trustworthy.`,
    name: "RITA ADESINA",
  },
  {
    title: "CONSISTENT QUALITY SERVICE",
    text: `I am only two years on this platform and the level of service rendered has been top notch. Vimevest has made investing super easy. The quality of service catches the eye and mind all-together. Long long shall this brand identity reign.  `,
    name: "CHARITY ODUCHE",
  },
];

const TestimonialsCard = ({ text, name }) => {
  return (
    <>
      <Card
        borderColor={"1px solid #F2994A"}
        background={"rgba(255,255,255,1)"}
      >
        <Grid container spacing={2}>
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <Box
              top={"32px"}
              radius={"8px"}
              left="auto"
              background="white"
              center
            >
              <Avatar />
            </Box>
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Card left={"auto"} right={"auto"} width={"80%"}>
              <Text
                lineheight={"24px"}
                color={"#120216"}
                fontFamily={"Mulish"}
                size={"14px"}
                top={"32px"}
                right={"16px"}
              >
                {text}
              </Text>
              <Text bottom={"32px"} color={"#92929D"} top={"16px"}>
                {name}
              </Text>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const Testimonials = () => {
  return (
    <>
      <MainSection top={"64px"}>
        <TitleText
          color={"#11142D"}
          fontFamily={"Mulish"}
          size={"24px"}
          top={"24px"}
          heavy
          center
        >
          Hear From Our Users
        </TitleText>
        <Text
          top={"16px"}
          color={"#92929D"}
          fontFamily={"Mulish"}
          size={"16px"}
          lineheight={"24px"}
          center
        >
          We have revolutionized investment for every investor regardless of
          their chosen plan and so we have the happiest customers.
        </Text>

        <Card
          top={"32px"}
          bottom={"64px"}
          width={"90%"}
          left={"auto"}
          right={"auto"}
        >
          <Grid container spacing={4} justifyContent={"center"}>
            {testimonialList.map((item, pos) => (
              <Grid key={pos} item lg={6} md={6} sm={6} xs={12}>
                <TestimonialsCard {...item} />
              </Grid>
            ))}
          </Grid>
        </Card>
        {/* <Card width={"48.8%"} left={"auto"} right={"auto"} top={"16px"}>
        <TestimonialsCard/>
        </Card> */}
      </MainSection>
    </>
  );
};

export default Testimonials;
