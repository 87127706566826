import { Grid } from "@material-ui/core";
import Layout from "../../components/homeLayout/Layout";
import {
  Button,
  Card,
  MainSection,
  Text,
  TextAreaField,
  TextField,
  TitleText,
} from "../../styles/util/UtilStyles";

import Map from "../../assets/img/home/Map.png";

const ContactPage = () => {
  return (
    <>
      <Layout>
        <MainSection top={"10em"}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Text
                color={"#670150"}
                size={"16px"}
                lineheight={"20px"}
                fontFamily={"Mulish"}
              >
                Contact Us
              </Text>
              <TitleText
                color={"#120216"}
                size={"42px"}
                lineheight={"52px"}
                fontFamily={"Mulish"}
                top={"16px"}
              >
                Hi, you can get in touch with us here!😊
              </TitleText>
              <Text
                color={"#120216"}
                size={"16px"}
                lineheight={"20px"}
                fontFamily={"Mulish"}
                top={"16px"}
              >
                If you have any questions or any bothering you, don’t hesitate
                to SEND US A MESSAGE!
              </Text>
              <Card top={"16px"}>
                <Text
                  color={"#120216"}
                  size={"16px"}
                  lineheight={"40px"}
                  fontFamily={"Mulish"}
                >
                  234-812-2450-145
                </Text>
                <Text
                  color={"#120216"}
                  size={"16px"}
                  lineheight={"40px"}
                  fontFamily={"Mulish"}
                >
                  hello@vimevest.com
                </Text>
                <Text
                  color={"#120216"}
                  size={"16px"}
                  lineheight={"40px"}
                  fontFamily={"Mulish"}
                >
                  Address: 18, University Crescent, Ibadan, Nigeria.
                </Text>
              </Card>
              <Card top={"16px"}>
                <img src={Map} alt="Map" style={{ width: "100%" }} />
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Card background={"#EAEAEE"}>
                <Card
                  background={"transparent"}
                  top={"32px"}
                  left={"16px"}
                  right={"16px"}
                  bottom={"32px"}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField height={"5em"} placeholder={"Full name"} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField height={"5em"} placeholder={"Email address"} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField height={"5em"} placeholder={"Subject"} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextAreaField placeholder={"Message"} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button background={"#670150"}>Submit</Button>
                    </Grid>
                  </Grid>
                </Card>
              </Card>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Card background={"#670150"} top={"64px"}>
                <Card background={"transparent"} top={"32px"} bottom={"32px"}>
                  <TitleText
                    size={"24px"}
                    lineheight={"48px"}
                    color={"white"}
                    fontFamily={"Mulish"}
                    center
                  >
                    Join our mailing list today!
                  </TitleText>
                  <Card
                    xsWidth={"90%"}
                    smWidth={"80%"}
                    mdWidth={"60%"}
                    lgWidth={"50%"}
                    top={"16px"}
                    background={"transparent"}
                    WidthResponsive
                    flex
                  >
                    <TextField placeholder="Enter your email here..." />
                    <Button
                      height={"3.5em"}
                      width={"12em"}
                      background={"#F2911B"}
                    >
                      Subscribe now
                    </Button>
                  </Card>
                </Card>
              </Card>
            </Grid>
          </Grid>
        </MainSection>
      </Layout>
    </>
  );
};

export default ContactPage;
