import { Grid } from "@material-ui/core";
import {
  InvestmentImage,
  InvestmentInvestors,
  InvestmentOverview,
} from "../../components/investments";
import UserLayout from "../../components/userLayout/UserLayout";

import { GoBack, MainSection } from "../../styles/util/UtilStyles";
import { useContext, useEffect, useState } from "react";
import { getInvestInfo } from "../../adapters/investments";

import { useHistory } from "react-router";
import { AppContext } from "../../App";
import { NotFoundError } from "../error";

const AdminViewInvestmentPage = () => {
  const appContext = useContext(AppContext);

  const [planInfo, setPlanInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // "619c482b105e1f8dc138d9a0"
    let params = {
      investmentID: history.location.pathname.substr(18),
    };

    getInvestInfo(params, setLoading, setPlanInfo, appContext, setError, true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <UserLayout pageTitle={"Investment Plan"} pageTitleComponent={<GoBack />}>
        {!isError ? (
          <MainSection width={"100%"} top={"2em"}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <InvestmentImage loading={loading} image={planInfo?.picture} />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <InvestmentOverview
                  loading={loading}
                  appContext={appContext}
                  planInfo={planInfo}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InvestmentInvestors
                  loading={loading}
                  appContext={appContext}
                  investors={planInfo.transactions}
                />
              </Grid>
            </Grid>
          </MainSection>
        ) : (
          <NotFoundError errorTitle={"Page Not Found"} />
        )}
      </UserLayout>
    </>
  );
};

export default AdminViewInvestmentPage;
