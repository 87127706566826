import { Grid } from "@material-ui/core";
import {
  Button,
  Card,
  MainSection,
  ScreenControl,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import image from "../../assets/img/home/Web-BannerArtboard-1.png";
import { useHistory } from "react-router";

const whatMakesUsDifferent = [
  {
    title: "Security",
    text: "Securing the future of our clients is our watchword and to enable this, a secure platform is what we diligently provide.",
  },
  {
    title: "Comfortability",
    text: "We carefully structure our plans to be inclusive so as to enable everybody incl",
  },
  {
    title: "Ingenuity",
    text: "We have a strong team of professionals who promotes open communication with our clients with the aim of helping them grow financially.",
  },
];

const CounterCard = ({ no, text, title }) => {
  return (
    <>
      <Card background={"transparent"}>
        <TitleText
          color={"#EAEAEE"}
          size={"48px"}
          lineheight={"32px"}
          fontFamily={"Inter"}
          center
        >
          {no}
        </TitleText>
        <Text
          color={"rgba(255,255,255,0.9)"}
          fontFamily={"Inter"}
          size={"18px"}
          top={"20px"}
          lineheight={"28px"}
          center
        >
          {title}
        </Text>
        <Text
          color={"rgba(255,255,255,0.7)"}
          fontFamily={"Inter"}
          size={"14px"}
          lineheight={"32px"}
          center
        >
          {text}
        </Text>
      </Card>
    </>
  );
};
const AboutUsMiddle = () => {
  const history = useHistory();

  return (
    <>
      <Card height={"10em"} width={"85%"} bottom={"0em"} shadow WidthResponsive>
        <Card left={"16px"} top={"16px"} right={"16px"} bottom={"16px"}>
          <Grid container spacing={4}>
            <Grid
              style={{ background: "white" }}
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <Text
                top={"16px"}
                color={"#670150"}
                size={"16px"}
                fontFamily={"Mulish"}
                heavy
              >
                WHO WE ARE
              </Text>
              <TitleText
                color={"#000000"}
                size={"32px"}
                top={"16px"}
                lineheight={"52px"}
                fontFamily={"Mulish"}
                heavy
              >
                Our Vision
              </TitleText>
              <Text
                color={"#1819F"}
                fontFamily={"Inter"}
                size={"14px"}
                lineheight={"32px"}
                bottom={"16px"}
              >
                <li>To lead Nigerians to financial freedom.</li>
              </Text>
              <TitleText
                color={"#000000"}
                size={"32px"}
                top={"16px"}
                lineheight={"52px"}
                fontFamily={"Mulish"}
                heavy
              >
                Our Mission
              </TitleText>
              <Text
                color={"#1819F"}
                fontFamily={"Inter"}
                size={"14px"}
                lineheight={"32px"}
                bottom={"16px"}
              >
                <li>To promote convenience and better life for our clients </li>
                <li>
                  To provide efficient services in financial management and
                  investment.
                </li>
              </Text>
              <Button
                onClick={() => history.push("/all-investments/")}
                width={"12em"}
                background={"#F98809"}
                radius={"8px"}
              >
                Invest Now
              </Button>
            </Grid>
            <Grid
              style={{ background: "white" }}
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <ScreenControl lg md>
                <Card>
                  <img src={image} alt="about us" style={{ width: "100%" }} />
                </Card>
              </ScreenControl>
            </Grid>
          </Grid>
        </Card>
      </Card>

      <Card background={"#670150"}>
        <MainSection top={"20em"} bottom={"32px"}>
          <TitleText
            color={"white"}
            size={"32px"}
            fontFamily={"Mulish"}
            center
            heavy
          >
            What makes us different?
          </TitleText>
          <Text
            top={"8px"}
            bottom={"32px"}
            color={"rgba(255,255,255,0.7)"}
            size={"16px"}
            lineheight={"32px"}
            fontFamily={"Inter"}
            center
          >
            {/* We had an incredible experience working with Landify. */}
          </Text>

          <Grid container spacing={3}>
            {whatMakesUsDifferent.map((item, pos) => (
              <Grid item lg={4} md={4} sm={4} xs={12} key={pos}>
                <CounterCard {...item} />
              </Grid>
            ))}
          </Grid>
        </MainSection>
      </Card>
    </>
  );
};

export default AboutUsMiddle;
