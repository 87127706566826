import { Card, Text, TitleText } from "../../styles/util/UtilStyles";
// import { getFormattedAmount } from "../../Utils";

const BalanceCard = ({ title, amount, color }) => {
  return (
    <>
      <Card
        height={"150px"}
        radius={"11px"}
        shadowAlt={"0px 2.87095px 44.4997px rgba(39, 52, 125, 0.05)"}
        background={color}
      >
        <Card background={"transparent"} left={"32px"} top={"24px"}>
          <TitleText
            fontFamily={"Poppins"}
            color={"#464255"}
            lineheight={"35px"}
            size={"16px"}
          >
            {title}
          </TitleText>
          <Text
            fontFamily={"Mitr"}
            color={"#464255"}
            lineheight={"68px"}
            size={"20px"}
            heavy
          >
            ₦ {amount}
          </Text>
        </Card>
      </Card>
    </>
  );
};
export default BalanceCard;
