import { Grid } from "@material-ui/core";
import { ChangePasswordCard, NotificationCard, ProfileActionCard, ProfileInfoCard, TwoFaCard } from "../../components/settings";
import UserLayout from "../../components/userLayout/UserLayout";
import { MainSection } from "../../styles/util/UtilStyles";

const SettingsPage = () => {
    return (
        <>
            <UserLayout pageTitle={"Settings"}>
                {/* --- Overview cards section here ---- */}
                <MainSection width={"100%"} top={"2em"}>
                    <Grid container spacing={4}>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Grid container spacing={4}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <ProfileActionCard />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <NotificationCard />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <ProfileInfoCard />
                        </Grid>

                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Grid container spacing={4}>
                                <Grid item lg={12} md={6} sm={6} xs={12}>
                                    <ChangePasswordCard />
                                </Grid>
                                <Grid item lg={12} md={6} sm={6} xs={12}>
                                    <TwoFaCard />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </MainSection>
            </UserLayout>
        </>
    );
};

export default SettingsPage;