import {
  Card,
  Label,
  Text,
  TitleText,
} from "../../../../styles/util/UtilStyles";

export const ViewAShareContent = ({ title = "Overview", text }) => {
  return (
    <>
      <TitleText color="#393939" weight="500" size="28px" lineheight="42px">
        {title}
      </TitleText>

      <Card background="transparent" top="14px" flex>
        <Label height="6px" width="20px" background="#670150" />
        <Label background="rgba(0, 0, 0, 0.2)" height="4px" width="100%" />
      </Card>
      <Text
        size="16px"
        lineheight="173.5%"
        top="29px"
        fontFamily={"Poppins"}
        weight="800"
      >
        {text}
      </Text>
    </>
  );
};
