import SkeletonCard from "../../adapters/utility/SkeletonCard";
import { Card } from "../../styles/util/UtilStyles";

const InvestmentImage = ({ image }) => {
  return (
    <>
      {!image ? (
        <SkeletonCard loading={true} />
      ) : (
        <Card background={"transparent"}>
          <Card height={"25em"}>
            <img
              src={image}
              alt="Investment"
              style={{ width: "100%", height: "100%" }}
            />
          </Card>
          <Card
            top={"-2.5em"}
            left={"2.5em"}
            width={"5em"}
            height={"5em"}
            // borderColor={"1px solid rgba(5,5,5,0.7)"}
            radius={"8px"}
          >
            <img
              src={image}
              alt="Plan"
              style={{ width: "100%", height: "100%" }}
            />
          </Card>
        </Card>
      )}
    </>
  );
};

export default InvestmentImage;
